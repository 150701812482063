import { useAppDispatch, useAppSelector } from "app/hook";
import { fetchOrderRegister } from "app/slices/documentSlices/orderRegisterSlice/orderRegisterSlice";
import {
  clearSubProduct,
  fetchSubstandardProduct,
  fetchSubstandardProductById,
  fetchTerritorySubstandards,
  fetchTovarSubstandard,
  movementSubstandardProduct,
} from "app/slices/documentSlices/substandardProductSlices/substandardProductSlices";
import { fetchIncomes } from "app/slices/enumerationsSlices/incomeSlices/incomeSlices";
import { fetchTypeWriteOff } from "app/slices/enumerationsSlices/typeWriteOffSlices/typeWriteOffSlices";
import { fetchTypeWriteSubstandard } from "app/slices/enumerationsSlices/typeWriteSubstandard/typeWriteSubstandard";
import { fetchProduct } from "app/slices/handbookSlices/productSlices/productSlices";
import { fetchRawMaterial } from "app/slices/handbookSlices/rawMaterialSlices/rawMaterialSlices";
import { fetchSemiProductMaterial } from "app/slices/handbookSlices/semiProductSlice/semiProductSlice";
import { fetchUnitsMeasurement } from "app/slices/handbookSlices/unitsMeasurementSlices/unitsMeasurementSlices";
import { fetchOwnSkladCM } from "app/slices/handbookSlices/wareHouseSlices/wareHouseSlices";
import { substandardProductForm } from "app/states/documents";
import {
  loadingOrderData,
  loadingProeductSub,
  orderRegisterOptions,
  subProductDetails,
  subTerritoryDetails,
  unitsMeasurementOptions,
} from "app/states/documents/selectStates";
import { getTypeSubstandard } from "app/states/enumerations/selectStates";
import {
  loadingSemiDetails,
  SemiProductDataOptions,
} from "app/states/handbooks";
import {
  getMaterialData,
  getProductData,
  getSkladCM,
  loadingProductionData,
  loadingRawMaterial,
} from "app/states/handbooks/selectStates";
import add_green from "assets/images/icons/add_green.svg";
import {
  ActionBase,
  BaseInput,
  BaseMaskInput,
  BaseSelect,
  BaseTable,
  Button,
  Content,
  DatePicker,
  FlexBox,
  Form,
  FormButton,
  FormSelect,
  Input,
  LoadingAddPages,
  ReactModal,
  Text,
  TextArea,
  Title,
} from "components";
import BaseMarkInput from "components/BaseMarkInput/BaseMarkInput";
import { useFormik } from "formik";
import { forEach, get, isEmpty, isEqual } from "lodash";
import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import { Col, Row } from "react-grid-system";
import { NumericFormat } from "react-number-format";
import { useLocation, useNavigate } from "react-router-dom";
import { ReactSVG } from "react-svg";
import { toast } from "react-toastify";
import HasAccess from "routers/HasAccess";
import { DOCUMENT_KEY, MAIN_DEPARTMENT } from "routers/constants";
import DocumentServices from "services/apiServices/documents";
import { ACTION, MESSAGES, PAGE, PROTECTED_TYPE } from "utilities/constants";
import { checkDateYear, textFormatter } from "utilities/helpers";
import { substandardProductDetailsSchema } from "utilities/schema/document/documentSchemas";
import { NumberDocType } from "types/documentsType";

// Добавить договор на покупку

enum REKVIZET_NAMES {
  TOVAR = "TOVAR",
  ZAKAN_NARYAD = "ZAKAN_NARYAD",
  MATERIAL = "MATERIAL",
  POLFABRIKAT = "POLFABRIKAT",
  SKLAD_GP = "SKLAD_GP",
  SKLAD = "SKLAD",
  KOL = "KOL",
  SKLAD_SM = "SKLAD_SM",
}
const INFO_TEXT = "Подобная информация уже добавлена";

const AddNewDocToTable = ({
  handleOpenModal,
  handleCloseModal,
  tableData,
  id,
  maxSumma,
  dataDoc,
}: {
  handleOpenModal?: () => void;
  handleCloseModal: () => void;
  tableData: Array<any>;
  id: number | null;
  maxSumma: number | string;
  dataDoc: any;
}) => {
  const dispatch = useAppDispatch();
  const typeOptions = useAppSelector(getTypeSubstandard);
  const productOptions = useAppSelector(getProductData);
  const materialOptions = useAppSelector(getMaterialData);
  const skladOptions = useAppSelector(getSkladCM);
  const getSemiMaterial = useAppSelector((store) =>
    SemiProductDataOptions(store, 1)
  );
  const orderOptions = useAppSelector(orderRegisterOptions);
  let loadingOrder = useAppSelector(loadingOrderData);
  let loadingProduct = useAppSelector(loadingProductionData);
  let loadingMaterial = useAppSelector(loadingRawMaterial);
  let loadingSemi = useAppSelector(loadingSemiDetails);
  const [isAgreeSubmit, setIsAgree] = useState(false);
  const [maxAmount, setMaxAmount] = useState<any>(0);
  const unitsMeasurementDropdowns = useAppSelector(unitsMeasurementOptions);
  const details = tableData?.find((_, index: number) => index === id);
  const deep = [
    "tipSpisaniyaId",
    "zakazNaryadId",
    "tovarId",
    "materialId",
    "skladId",
    "polufabrikatId",
  ];

  const onSubmit = (values: any) => {
    if (values && tableData) {
      const found = tableData.some((elem) => {
        if (
          isEqual(get(elem, `${deep[0]}`), get(values, `${deep[0]}`)) &&
          isEqual(get(elem, `${deep[1]}`), get(values, `${deep[1]}`)) &&
          isEqual(get(elem, `${deep[2]}`), get(values, `${deep[2]}`)) &&
          isEqual(get(elem, `${deep[3]}`), get(values, `${deep[3]}`)) &&
          isEqual(get(elem, `${deep[4]}`), get(values, `${deep[4]}`)) &&
          isEqual(get(elem, `${deep[5]}`), get(values, `${deep[5]}`))
        ) {
          return true;
        } else {
          return false;
        }
      });
      if (found && isEmpty(details)) {
        toast.info(INFO_TEXT);
      } else {
        if (isEmpty(details)) {
          dispatch(
            movementSubstandardProduct({
              type: ACTION.ADD,
              data: values,
            })
          );
          resetForm();
          handleCloseModal();
        } else {
          dispatch(
            movementSubstandardProduct({
              type: ACTION.UPDATE,
              data: values,
              id,
            })
          );
          resetForm();
          handleCloseModal();
        }
      }
    } else {
      dispatch(
        movementSubstandardProduct({
          type: ACTION.ADD,
          data: values,
        })
      );
      resetForm();
      handleCloseModal();
    }
  };

  const { values, handleSubmit, setFieldValue, resetForm } = useFormik({
    initialValues: {
      tipSpisaniyaId: get(details, "tipSpisaniyaId", null),
      zakazNaryadId: get(details, "zakazNaryadId", null),
      edIzmId: get(details, "edIzmId", null),
      skladGPId: get(details, "skladGPId", null),
      tovarId: get(details, "tovarId", null),
      imyaTipSpisaniya: get(details, "imyaTipSpisaniya", null),
      imyaTovara: get(details, "imyaTovara", null),
      imyaMateriala: get(details, "imyaMateriala", ""),
      materialId: get(details, "materialId", null),
      imyaPolufabrikata: get(details, "imyaPolufabrikata", ""),
      polufabrikatId: get(details, "polufabrikatId", ""),
      nomerZakazNaryad: get(details, "nomerZakazNaryad", ""),
      imyaSkladGP: get(details, "imyaSkladGP", ""),
      skladId: get(details, "skladId", ""),
      imyaSklada: get(details, "imyaSklada", ""),
      imyaEdIzm: get(details, "imyaEdIzm", ""),
      kol: get(details, "kol", ""),
      summa: get(details, "summa", ""),
      kommentariya: get(details, "kommentariya", ""),
    },
    enableReinitialize: true,
    validationSchema: substandardProductDetailsSchema,
    onSubmit,
  });

  const fetchDogDetails = useCallback(() => {
    if (get(values, "tipSpisaniyaId", "")) {
      switch (get(values, "tipSpisaniyaId", "")) {
        case 1:
          dispatch(fetchProduct({ all: true }));
          // if (get(values, "tovarId", false)) {
          //   dispatch(
          //     fetchOrderRegister({
          //       filterPage: "VIPUSK_GP",
          //       otmVipolneniya: 0,
          //       tovarId: get(values, "tovarId", null),
          //     })
          //   );
          // }
          break;
        case 2:
          dispatch(fetchRawMaterial({ all: true, tip: "SM" }));
          dispatch(fetchOwnSkladCM({}));
          break;
        case 3:
          dispatch(fetchSemiProductMaterial({ all: true, tip: "PF" }));
          // if (get(values, "polufabrikatId", null)) {
          //   dispatch(
          //     fetchOrderRegister({
          //       filterPage: "VIPUSK_PF",
          //       otmVipolneniya: 0,
          //       polufabrikatId: get(values, "polufabrikatId", null),
          //     })
          //   );
          // }
          // dispatch(fetchOwnSkladCM({}));
          break;
        default:
          break;
      }
    }
  }, [values.tipSpisaniyaId]);
  const fetchLimitAmount = useCallback(() => {
    if (values.zakazNaryadId && values.tovarId) {
      DocumentServices.FetchLimitForTovar({
        tovarId: get(values, "tovarId", null),
        zakazNaryadId: get(values, "zakazNaryadId", null),
      })
        .then(({ data }) => {
          if (data) {
            setMaxAmount(data.data);
          }
        })
        .catch((err) => console.log(err));
    } else if (values.polufabrikatId && values.zakazNaryadId) {
      DocumentServices.FetchLimitForSemiMaterial({
        polufabrikatId: get(values, "polufabrikatId", null),
        zakazNaryadId: get(values, "zakazNaryadId", null),
      })
        .then(({ data }) => {
          if (data) {
            setMaxAmount(data.data);
          }
        })
        .catch((err) => console.log(err));
    }
  }, [values.zakazNaryadId, values.tovarId, values.polufabrikatId]);
  const checkIsAvailable = ({
    tipSpisaniyaId,
    rekvizetName,
  }: {
    tipSpisaniyaId: string | number;
    rekvizetName: string;
  }) => {
    switch (rekvizetName) {
      case REKVIZET_NAMES.TOVAR:
        if (tipSpisaniyaId == 1) {
          return true;
        }
        return false;
      case REKVIZET_NAMES.MATERIAL:
        if (tipSpisaniyaId == 2) {
          return true;
        }
        return false;
      case REKVIZET_NAMES.POLFABRIKAT:
        if (tipSpisaniyaId == 3) {
          return true;
        }
        return false;
      case REKVIZET_NAMES.SKLAD:
        if (tipSpisaniyaId == 2) {
          return true;
        }
        return false;
      case REKVIZET_NAMES.SKLAD_GP:
        if (tipSpisaniyaId == 1) {
          return true;
        }
        return false;
      case REKVIZET_NAMES.ZAKAN_NARYAD:
        if (tipSpisaniyaId == 1 || tipSpisaniyaId == 3) {
          return true;
        }
        return false;
      case REKVIZET_NAMES.KOL:
        if (tipSpisaniyaId == 1 || tipSpisaniyaId == 2 || tipSpisaniyaId == 3) {
          return true;
        }
        return false;
      case REKVIZET_NAMES.SKLAD_SM:
        if (tipSpisaniyaId == 3) {
          return true;
        }
        return false;
    }
  };

  const fetchSelectors = useCallback(() => {
    dispatch(fetchTypeWriteSubstandard({ all: true }));
  }, [dispatch]);
  const isAgreeToSubmit = useCallback(() => {
    if (
      Number(get(values, "summa", 0)) >
      Number(maxSumma) + get(details, "summa", 0)
    ) {
      setIsAgree(true);
    } else if (
      (maxAmount < get(values, "kol", 0) &&
        get(values, "tipSpisaniyaId", 0) === 1) ||
      get(values, "tipSpisaniyaId", 0) === 3
    ) {
      setIsAgree(true);
    } else {
      setIsAgree(false);
    }
  }, [values.summa, values.kol, maxAmount, get(values, "tipSpisaniyaId", 0)]);

  //  function get all material Prices
  const handleClose = () => {
    handleCloseModal();
    resetForm();
  };
  useEffect(() => {
    isAgreeToSubmit();
  }, [isAgreeToSubmit]);
  useEffect(() => {
    fetchSelectors();
  }, [fetchSelectors]);
  useEffect(() => {
    fetchDogDetails();
  }, [fetchDogDetails]);
  useEffect(() => {
    fetchLimitAmount();
  }, [fetchLimitAmount]);
  return (
    <>
      <Col xs={12} className="mt-16 text-left">
        <Title sm bold>
          Ввод данных
        </Title>
      </Col>
      <Col xs={12} style={{ padding: 0 }} className={"my-16"}>
        <hr />
      </Col>
      <Form onSubmit={handleSubmit} align={"center"}>
        <Col xs={12} className={"px-24"}>
          <FormSelect
            id={"tipSpisaniyaId"}
            label={"Тип списание"}
            className="mt-24"
            isSearchable
            required={true}
            value={get(values, "tipSpisaniyaId", "")}
            defaultValue={{
              value: get(values, "tipSpisaniyaId", ""),
              label: get(values, "imyaTipSpisaniya", ""),
            }}
            isLoading={get(typeOptions, "loading", false)}
            options={get(typeOptions, "list", [])}
            handleValueLabel={(e: any) => {
              setFieldValue("tipSpisaniyaId", e.value);
              setFieldValue("imyaTipSpisaniya", e.label);

              if (!isEqual(e.value, get(values, "tipSpisaniyaId", false))) {
                setFieldValue("imyaMateriala", null);
                setFieldValue("materialId", null);
                setFieldValue("zakazNaryadId", null);
                setFieldValue("nomerZakazNaryad", null);
                setFieldValue("polufabrikatId", null);
                setFieldValue("imyaPolufabrikata", null);
                setFieldValue("imyaSkladGP", null);
                setFieldValue("skladGPId", null);
                setFieldValue("tovarId", null);
                setFieldValue("imyaTovara", null);
                setFieldValue("skladId", null);
                setFieldValue("imyaSklada", null);
                setFieldValue("kol", "");
                setFieldValue("summa", "");

                setMaxAmount(null);
              }
            }}
          />
          {checkIsAvailable({
            tipSpisaniyaId: get(values, "tipSpisaniyaId", ""),
            rekvizetName: REKVIZET_NAMES.TOVAR,
          }) && (
              <FormSelect
                id={"tovarId"}
                label={"Товар"}
                className="mt-24"
                isSearchable
                required={true}
                isLoading={loadingProduct}
                value={get(values, "tovarId", "")}
                options={productOptions}
                handleValueLabel={(e: any) => {
                  setFieldValue("tovarId", e.value);
                  setFieldValue("imyaTovara", e.label);
                  setFieldValue("imyaEdIzm", e.imyaEdIzm);
                  setFieldValue("edIzmId", e.edIzmId);
                  if (get(values, "tipSpisaniyaId", false) === 1) {
                    dispatch(
                      fetchOrderRegister({
                        filterPage: "VIPUSK_GP",
                        otmVipolneniya: 0,
                        tovarId: e.value,
                      })
                    );
                  }
                  if (!isEqual(e.value, get(values, "tovarid", false))) {
                    setFieldValue("zakazNaryadId", null);
                    setFieldValue("imyaSkladGP", "");
                    setFieldValue("nomerZakazNaryad", null);
                    // setFieldValue("imyaSkladGP", null);
                    setFieldValue("skladGPId", null);
                    setFieldValue("skladId", null);
                    setFieldValue("imyaSklada", null);
                    setFieldValue("kol", "");
                    setFieldValue("summa", "");
                    setMaxAmount(null);
                  }
                }}
              />
            )}
          {checkIsAvailable({
            tipSpisaniyaId: get(values, "tipSpisaniyaId", ""),
            rekvizetName: REKVIZET_NAMES.MATERIAL,
          }) && (
              <FormSelect
                id={"materialId"}
                label={"Материал"}
                className="mt-24"
                isSearchable
                isLoading={loadingMaterial}
                required={true}
                value={get(values, "materialId", "")}
                options={materialOptions}
                handleValueLabel={(e: any) => {
                  setFieldValue("materialId", e.value);
                  setFieldValue("imyaMateriala", e.label);
                  setFieldValue("imyaEdIzm", e.imyaEdIzm);
                  setFieldValue("edIzmId", e.edIzmId);
                  if (!isEqual(e.value, get(values, "materialId", false))) {
                    setFieldValue("zakazNaryadId", null);
                    setFieldValue("nomerZakazNaryad", null);
                    // setFieldValue("imyaSkladGP", null);
                    // setFieldValue("skladGPId", null);
                    // setFieldValue("skladId", null);
                    // setFieldValue("imyaSklada", null);
                    setFieldValue("kol", "");
                    setFieldValue("summa", "");
                    setMaxAmount(null);
                    setFieldValue("summa", "");
                  }
                }}
              />
            )}
          {checkIsAvailable({
            tipSpisaniyaId: get(values, "tipSpisaniyaId", ""),
            rekvizetName: REKVIZET_NAMES.POLFABRIKAT,
          }) && (
              <FormSelect
                id={"polufabrikatId"}
                label={"Полуфабрикат"}
                className="mt-24"
                required={true}
                isLoading={loadingSemi}
                isSearchable
                value={get(values, "polufabrikatId", "")}
                options={getSemiMaterial}
                handleValueLabel={(e: any) => {
                  setFieldValue("polufabrikatId", e.value);
                  setFieldValue("imyaPolufabrikata", e.label);
                  setFieldValue("imyaEdIzm", e.imyaEdIzm);
                  setFieldValue("edIzmId", e.edIzmId);
                  if (values.tipSpisaniyaId === 3) {
                    dispatch(
                      fetchOrderRegister({
                        filterPage: "VIPUSK_PF",
                        otmVipolneniya: 0,
                        polufabrikatId: get(e, "value", null),
                      })
                    );
                  }
                  if (!isEqual(e.value, get(values, "polufabrikatId", false))) {
                    setFieldValue("zakazNaryadId", null);
                    setFieldValue("nomerZakazNaryad", null);
                    setFieldValue("imyaSkladGP", null);
                    setFieldValue("skladGPId", null);
                    setFieldValue("skladId", null);
                    setFieldValue("imyaSklada", "");
                    setFieldValue("kol", "");
                    setFieldValue("summa", "");
                    setMaxAmount(null);
                  }
                }}
              />
            )}
          {checkIsAvailable({
            tipSpisaniyaId: get(values, "tipSpisaniyaId", ""),
            rekvizetName: REKVIZET_NAMES.ZAKAN_NARYAD,
          }) && (
              <FormSelect
                id={"zakazNaryadId"}
                label={"Заказ-наряд"}
                className="mt-24"
                isSearchable
                isLoading={loadingOrder}
                value={get(values, "zakazNaryadId", "")}
                options={orderOptions}
                required={true}
                handleValueLabel={(e: any) => {
                  setFieldValue("nomerZakazNaryad", e.label);
                  setFieldValue("zakazNaryadId", e.value);
                  setFieldValue("imyaSkladGP", e.imyaSkladaGP);
                  setFieldValue("skladGPId", e.skladGPId);
                  setFieldValue("skladId", e.skladId);
                  setFieldValue("imyaSklada", e.imyaSklada);
                  if (!isEqual(e.value, get(values, "zakazNaryadId", false))) {
                    setFieldValue("kol", "");
                    setFieldValue("summa", "");
                    setMaxAmount(null);
                  }
                }}
              />
            )}
          {checkIsAvailable({
            tipSpisaniyaId: get(values, "tipSpisaniyaId", ""),
            rekvizetName: REKVIZET_NAMES.SKLAD_GP,
          }) && (
              <Row align="center" className="mt-24">
                <Col xs={4} style={{ paddingRight: 0 }}>
                  <Title>
                    Склад ГП
                    <span className="required_point">*</span>
                  </Title>
                </Col>
                <Col xs={8}>
                  <BaseInput
                    id={"imyaSkladGP"}
                    value={get(values, "imyaSkladGP", "")}
                    disabled={true}
                  />
                </Col>
              </Row>
            )}
          {checkIsAvailable({
            tipSpisaniyaId: get(values, "tipSpisaniyaId", ""),
            rekvizetName: REKVIZET_NAMES.SKLAD,
          }) && (
              <FormSelect
                id={"skladId"}
                label={"Склад"}
                className="mt-24"
                required={true}
                defaultValue={{
                  label: get(values, "imyaSklada", ""),
                  value: get(values, "skladId", ""),
                }}
                isSearchable
                value={get(values, "skladId", "")}
                options={skladOptions}
                handleValueLabel={(e: any) => {
                  setFieldValue("imyaSklada", e.label);
                  setFieldValue("skladId", e.value);
                  if (!isEqual(e.value, get(values, "skladId", false))) {
                    setFieldValue("summa", "");
                  }
                }}
              />
            )}
          {checkIsAvailable({
            tipSpisaniyaId: get(values, "tipSpisaniyaId", ""),
            rekvizetName: REKVIZET_NAMES.SKLAD_SM,
          }) && (
              <Row align="center" className="mt-24">
                <Col xs={4} style={{ paddingRight: 0 }}>
                  <Title>
                    Склад
                    <span className="required_point">*</span>
                  </Title>
                </Col>
                <Col xs={8}>
                  <BaseInput
                    id={"imyaSklada"}
                    value={get(values, "imyaSklada", "")}
                    disabled={true}
                  />
                </Col>
              </Row>
            )}
          {checkIsAvailable({
            tipSpisaniyaId: get(values, "tipSpisaniyaId", ""),
            rekvizetName: REKVIZET_NAMES.KOL,
          }) && (
              <Row align="center" className="mt-24">
                <Col xs={4} style={{ paddingRight: 0 }}>
                  <Title>
                    Количество
                    <span className="required_point">*</span>
                  </Title>
                </Col>
                <Col xs={8} className="form-input-date_picker">
                  <Row>
                    <Col xs={7}>
                      <BaseMarkInput
                        min={1}
                        id={"kol"}
                        placeholder={
                          maxAmount && `до: ${textFormatter(maxAmount)}`
                        }
                        maxLimit={maxAmount}
                        value={get(values, "kol", "")}
                        handleInput={(e: number) => {
                          setFieldValue("kol", e);
                        }}
                      />
                      <p>
                        {Number(get(values, "kol", 0)) > maxAmount && (
                          <Text xs danger>
                            ограничение до:
                            {textFormatter(maxAmount)}
                          </Text>
                        )}
                      </p>
                    </Col>
                    <Col xs={5} style={{ paddingLeft: 0 }}>
                      <FlexBox>
                        <Text xs>Ед.изм.:</Text>
                        <BaseSelect
                          id={"edIzmId"}
                          isSearchable
                          defaultValue={get(values, "edIzmId", "")}
                          value={get(values, "edIzmId", "")}
                          options={unitsMeasurementDropdowns}
                          isDisabled={true}
                        />
                      </FlexBox>
                    </Col>
                  </Row>
                </Col>
              </Row>
            )}

          <Row align="center" className="mt-24">
            <Col xs={4} style={{ paddingRight: 0 }}>
              <Title>
                Сумма
                <span className="required_point">*</span>
              </Title>
            </Col>
            <Col xs={8}>
              <BaseMaskInput
                id={"summa"}
                placeholder={`до: ${textFormatter(
                  Number(maxSumma) + get(details, "summa", 0)
                )}`}
                value={get(values, "summa", "")}
                handleInput={(e: any) => setFieldValue("summa", e)}
              />
              <p>
                {get(values, "summa", 0) >
                  Number(maxSumma) + get(details, "summa", 0) &&
                  maxSumma !== null ? (
                  <Text xs danger>
                    ограничение до:
                    {textFormatter(Number(maxSumma) + get(details, "summa", 0))}
                  </Text>
                ) : (
                  ""
                )}
              </p>
            </Col>
          </Row>
          <Input
            id={"kommentariya"}
            label={"Коментарии"}
            value={get(values, "kommentariya", "")}
            handleInput={(e: string) => {
              setFieldValue("kommentariya", e);
            }}
          />
          <Col xs={12} className="text-right mt-24 mb-16">
            <Button
              className="mr-8"
              type={"submit"}
              green
              inability={isAgreeSubmit}
            >
              {get(details, "tipSpisaniyaId", null) ? PAGE.CHANGE : PAGE.ADD}
            </Button>
            <Button onClick={handleClose} danger>
              Закрыть
            </Button>
          </Col>
        </Col>
      </Form>
    </>
  );
};
const AddSubstandardProductContainer = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { state } = useLocation();
  const subStandard = useAppSelector(substandardProductForm);
  const territoryOptions = useAppSelector(subTerritoryDetails);
  const productOptions = useAppSelector(subProductDetails);
  let loadingSub = useAppSelector(loadingProeductSub);
  let LOADING = get(subStandard, "loading", false);
  const [openModal, setOpenModal] = useState(false);
  const [disable, setDisable] = useState(false);
  const [amount, setMaxAmount] = useState(0);
  const [sebest, setSebest] = useState(0);
  const handleCloseModal = useCallback(() => {
    setOpenModal(false);
  }, []);
  const [detailId, setDetailsId] = useState<number | null>(null);

  const handleOpenModal = useCallback((id: number | null) => {
    setOpenModal(true);
    setDetailsId(id);
  }, []);

  const onSubmit = (values: any) => {
    const subjects = get(subStandard, "data.spisanieBrakDetails", []).map(
      (item: any) => ({
        tipSpisaniyaId: get(item, "tipSpisaniyaId", null),
        zakazNaryadId: get(item, "zakazNaryadId", ""),
        edIzmId: get(item, "edIzmId", ""),
        skladGPId: get(item, "skladGPId", ""),
        summa: get(item, "summa", null),
        tovarId: get(item, "tovarId", ""),
        materialId: get(item, "materialId", ""),
        polufabrikatId: get(item, "polufabrikatId", ""),
        skladId: get(item, "skladId", null),
        kol: get(item, "kol", ""),
        kommentariya: get(item, "kommentariya", ""),
      })
    );
    let valuesToSbm: any = {};
    forEach(
      values,
      (value: any, key: any) =>
      (valuesToSbm[key] = [
        "nomerDoc",
        "dataDoc",
        "kolTovara",
        "totalAmount",
        "sebestTovar",
        "kommentariya",
      ].includes(key)
        ? value
        : get(value, "value", ""))
    );

    try {
      DocumentServices.AddSubstandardProduct({
        transactionTime: new Date(),
        resultCode: null,
        resultMsg: null,
        data: {
          ...valuesToSbm,
          dataDoc: !isEqual(get(values, "dataDoc", null), null)
            ? get(values, "dataDoc", "")
            : moment(new Date()).format("YYYY-MM-DD"),
          spisanieBrakDetails: subjects,
        },
      }).then((res) => {
        if (get(res, "status", "") == 200) {
          dispatch(fetchSubstandardProduct({ all: true }));
          navigate(-1);
          toast.success(MESSAGES.ADDED);
        }
        setSubmitting(false);
      });
    } catch (err) {
      console.log("err", err);
    }
  };
  const { values, setFieldValue, handleSubmit, setSubmitting, isSubmitting } =
    useFormik({
      enableReinitialize: true,
      initialValues: {
        ...get(subStandard, "data.formIntVls", {}),
        dataDoc: get(state, 'id', false) ? get(subStandard, "data.formIntVls.dataDoc", '') : ''
      },
      onSubmit,
    });

  const fetchSubstandardProductDetails = useCallback(() => {
    if (get(values, "territoriyaId.value", null) && !get(state, "id", false)) {
      dispatch(
        fetchTovarSubstandard({
          territoriyaId: get(values, "territoriyaId.value", null),
        })
      );
    }
  }, [get(values, "territoriyaId.value", null)]);
  //   fetch doc number
  const getRegisterParishCashNumber = useCallback((date: NumberDocType) => {
    DocumentServices.FetchSubstandardNumber(date)
      .then(({ data }) => {
        setFieldValue("nomerDoc", get(data, "data", ""));
        if (get(data, "transactionTime", "")) {
          const transactionTime = get(data, "transactionTime", "");
          const localMaxDate = moment.utc(transactionTime).add(5, 'hours').format("YYYY-MM-DD HH:mm");
          const localDate = moment.utc(transactionTime).add(5, 'hours').format("YYYY-MM-DD");
          setFieldValue("maxDate", localMaxDate);
          if (isEmpty(get(values, 'dataDoc', ''))) {
            setFieldValue("dataDoc", localDate);
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, [values]);

  const handleCheckTemp = useCallback(() => {
    if (get(state, "id", false)) {
      dispatch(fetchSubstandardProductById(get(state, "id", "")));
      setDisable(true);
    } else {
      getRegisterParishCashNumber({});
    }
  }, [dispatch, state]);

  const calculateTotalAmnt = useCallback(() => {
    if (isEmpty(get(state, "id", false))) {
      const total = get(values, "kolTovara", 0) * get(values, "totalAmount", 0);
      if (total) {
        setFieldValue("sebestTovar", total);
      } else if (total === 0) {
        setFieldValue("sebestTovar", 0);
      }
    }
  }, [setFieldValue, values.kolTovara, values.totalAmount]);
  //  calc summa
  const calcSummaDetails = useCallback(() => {
    const sumWithInitial = get(
      subStandard,
      "data.spisanieBrakDetails",
      []
    ).reduce(
      (accumulator: any, currentValue: any) =>
        accumulator + Number(currentValue.summa),
      0
    );
    setSebest(get(values, "sebestTovar", 0) - Number(sumWithInitial));
  }, [
    get(subStandard, "data.spisanieBrakDetails", []),
    get(values, "sebestTovar", 0),
  ]);

  //  fetchs
  useEffect(() => {
    handleCheckTemp();
  }, [dispatch, handleCheckTemp]);

  useEffect(() => {
    calculateTotalAmnt();
  }, [calculateTotalAmnt, values.kolTovara]);

  useEffect(() => {
    if (isEmpty(get(state, "id", null))) {
      dispatch(fetchTerritorySubstandards());
    }
    return () => {
      dispatch(clearSubProduct());
    };
  }, []);

  useEffect(() => {
    calcSummaDetails();
  }, [calcSummaDetails]);

  useEffect(() => {
    fetchSubstandardProductDetails();
  }, [fetchSubstandardProductDetails]);

  return (
    <Content className={"min_height"}>
      {/* modal */}
      <ReactModal isOpen={openModal}>
        <AddNewDocToTable
          handleCloseModal={handleCloseModal}
          tableData={get(subStandard, "data.spisanieBrakDetails", [])}
          maxSumma={sebest}
          id={detailId}
          //   sebset={get(values, "sebestTovar", 0)}
          dataDoc={get(values, "dataDoc", null)}
        />
      </ReactModal>
      {isEqual(LOADING, false) ? (
        <Form onSubmit={handleSubmit}>
          <Row>
            <Col xs={12}>
              <FlexBox justify={"space-between"} style={{ width: "100%" }}>
                <Title bold sm>
                  Реквизиты
                </Title>
              </FlexBox>
            </Col>
            <Col xs={12} style={{ padding: 0 }} className={"my-16"}>
              <hr />
            </Col>
          </Row>
          <Row>
            <Col xs={6}>
              <Row align="center">
                <Col xs={4}>
                  <Title>
                    Номер<span className="required_point">*</span>
                  </Title>
                </Col>
                <Col xs={8} className="form-input-date_picker">
                  <Row>
                    <Col xs={6}>
                      <BaseInput
                        id={"nomerDoc"}
                        value={get(values, "nomerDoc", "")}
                        handleInput={(e: string) => {
                          setFieldValue("nomerDoc", e);
                        }}
                      />
                    </Col>
                    <Col xs={6} style={{ paddingLeft: 0 }}>
                      <FlexBox>
                        <Text>от:</Text>
                        <DatePicker
                          id={"dataDoc"}
                          value={get(values, "dataDoc", null)}
                          handleDate={(e: any) => {
                            getRegisterParishCashNumber({
                              year: checkDateYear({
                                currentYear: get(values, "dataDoc", null),
                                checkYear: moment(e).format("YYYY-MM-DD"),
                              }),
                            });
                            setFieldValue(
                              "dataDoc",
                              moment(e).format("YYYY-MM-DD")
                            );
                          }}
                          dateFormat="dd.MM.yyyy"
                          disabled={disable}
                          // maxDate={new Date()}
                          maxDate={new Date(get(values, 'maxDate', new Date()))}
                        />
                      </FlexBox>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <FormSelect
                id={"territoriyaId"}
                label={"Территория"}
                required={true}
                defaultValue={get(values, "territoriyaId", null)}
                isDisabled={disable}
                value={get(values, "territoriyaId.value", null)}
                isLoading={get(territoryOptions, "loading", false)}
                options={get(territoryOptions, "list", [])}
                className="mt-24"
                handleValueLabel={(e: any) => {
                  setFieldValue("territoriyaId", e);
                  if (!isEqual(get(values, "territoriyaId", null), e)) {
                    setFieldValue("tovarId", null);
                    setFieldValue("sebestTovar", "");
                    setFieldValue("kolTovara", "");
                    setMaxAmount(0);
                  }
                }}
              />
              <FormSelect
                id={"tovarId"}
                label={"Товар"}
                isLoading={loadingSub}
                required={true}
                defaultValue={get(values, "tovarId", {})}
                isDisabled={disable}
                value={get(values, "tovarId.value", null)}
                options={
                  isEmpty(get(values, "territoriyaId.value", null))
                    ? []
                    : productOptions
                }
                className="mt-24"
                handleValueLabel={(e: any) => {
                  setFieldValue("tovarId", e);
                  setFieldValue("totalAmount", e.sebestTovar);
                  setMaxAmount(get(e, "maxKol", 0));
                  if (!isEqual(get(values, "tovarId", null), e)) {
                    setFieldValue("sebestTovar", "");
                    setFieldValue("kolTovara", "");
                  }
                }}
              />
              <Row align="center" className="mt-24">
                <Col xs={4}>
                  <Title>
                    Количество
                    <span className="required_point">*</span>
                  </Title>
                </Col>
                <Col xs={8}>
                  <BaseMarkInput
                    placeholder={
                      amount !== null ? `до: ${textFormatter(amount)}` : ""
                    }
                    disabled={disable}
                    id={"kolTovara"}
                    value={get(values, "kolTovara", "")}
                    handleInput={(e: number) => {
                      setFieldValue("kolTovara", e);
                    }}
                    min={1}
                  />
                  <p>
                    {Number(get(values, "kolTovara", 0)) > Number(amount) &&
                      amount !== null &&
                      isEmpty(get(state, "id", null)) ? (
                      <Text xs danger>
                        ограничение до:
                        {textFormatter(amount)}
                      </Text>
                    ) : (
                      ""
                    )}
                  </p>
                </Col>
              </Row>
              <Row align="center" className="mt-24">
                <Col xs={4}>
                  <Title>
                    Себестоимость
                    <span className="required_point">*</span>
                  </Title>
                </Col>
                <Col xs={8}>
                  <BaseMarkInput
                    disabled={true}
                    id={"sebestTovar"}
                    value={get(values, "sebestTovar", 0)}
                  />
                </Col>
              </Row>
              <TextArea
                id={"kommentariya"}
                label={"Коментарии"}
                value={get(values, "kommentariya", "")}
                disabled={disable}
                handleInput={(e: string) => {
                  setFieldValue("kommentariya", e);
                }}
              />
            </Col>
          </Row>
          <Row className="mt-24" justify={"between"}>
            <Col xs={12} className="align-center">
              {isEqual(disable, false) && (
                <FlexBox justify={"space-between"} align={"center"}>
                  <Title bold sm>
                    Табличная часть<span className="required_point">*</span>
                  </Title>
                  {get(values, "tovarId.value", false) &&
                    get(values, "kolTovara", false) && (
                      <ReactSVG
                        src={add_green}
                        className="svg_icon"
                        onClick={() => handleOpenModal(null)}
                      />
                    )}
                </FlexBox>
              )}
            </Col>
            <Col xs={12} style={{ padding: 0 }} className={"my-16"}>
              <hr />
            </Col>
          </Row>

          <Row
            style={{ position: "relative", minWidth: "100%" }}
            className="responsive_overflow"
          >
            <Col xs={12}>
              <Col>
                <BaseTable
                  tableHeader={[
                    "№",
                    "Тип списание",
                    "Товар",
                    "Материал",
                    "Полуфабрикат",
                    "Заказ-наряд",
                    "Склад ГП",
                    "Склад",
                    "Ед.изм.",
                    "Количество",
                    "Сумма",
                    "Комментарии",
                    "",
                  ]}
                >
                  {!isEmpty(
                    get(subStandard, "data.spisanieBrakDetails", [])
                  ) ? (
                    get(subStandard, "data.spisanieBrakDetails", []).map(
                      (item: any, index: number) => (
                        <tr key={index + 1}>
                          <td>{index + 1}</td>
                          <td>{get(item, "imyaTipSpisaniya", "-")}</td>
                          <td>{get(item, "imyaTovara", "-")}</td>
                          <td>{get(item, "imyaMateriala", "-")}</td>
                          <td>{get(item, "imyaPolufabrikata", "-")}</td>
                          <td>{get(item, "nomerZakazNaryad", "-")}</td>
                          <td>{get(item, "imyaSkladGP", "-")}</td>
                          <td>{get(item, "imyaSklada", "-")}</td>
                          <td>{get(item, "imyaEdIzm", "-")}</td>
                          <td>
                            <NumericFormat
                              displayType="text"
                              value={get(item, "kol", "")}
                              thousandSeparator
                              fixedDecimalScale={false}
                              decimalScale={2}
                            />
                          </td>
                          <td>
                            <NumericFormat
                              displayType="text"
                              value={get(item, "summa", "")}
                              thousandSeparator
                              decimalScale={2}
                            />
                          </td>
                          <td>{get(item, "kommentariya", "-")}</td>
                          <td>
                            <ActionBase
                              handleUpdate={handleOpenModal}
                              handleDelete={() =>
                                dispatch(
                                  movementSubstandardProduct({
                                    type: ACTION.DELETE,
                                    id: index,
                                  })
                                )
                              }
                              disable={disable}
                              id={index}
                            />
                          </td>
                        </tr>
                      )
                    )
                  ) : (
                    <tr>
                      <td colSpan={13}> Нет Реквизиты</td>
                    </tr>
                  )}
                </BaseTable>
              </Col>
            </Col>
          </Row>
          <Row className="mb-16 mt-32 text-end">
            <Col xs={12}>
              <HasAccess>
                {({ userCan }: any) =>
                  userCan({
                    permission: `${DOCUMENT_KEY.SPISANIYA_BRAK}_CREATE`,
                    page: DOCUMENT_KEY.SPISANIYA_BRAK,
                    department: MAIN_DEPARTMENT.DOCUMENTS,
                    type: PROTECTED_TYPE.PERMISSIONS,
                  }) && (
                    <FormButton
                      disabled={disable}
                      loading={isSubmitting}
                      handleClick={handleSubmit}
                    >
                      {PAGE.DONE}
                    </FormButton>
                  )
                }
              </HasAccess>
              <Button danger handleClick={() => navigate(-1)}>
                {PAGE.CLOSE}
              </Button>
            </Col>
          </Row>
        </Form>
      ) : (
        <LoadingAddPages />
      )}
    </Content>
  );
};

export default AddSubstandardProductContainer;
