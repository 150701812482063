import { useAppDispatch, useAppSelector } from "app/hook";
import {
  fetchMovementMaterials,
  fetchMovementMaterialsById,
  fetchMovementMaterialsByTempId,
  fetchMovementMaterialsStat,
  fetchNotFilledMovementMaterials,
  movementMaterialSubject,
} from "app/slices/documentSlices/movementMaterialSlices/movementMaterialSlices";
import { detailsMovement } from "app/slices/documentSlices/movementMaterialSlices/type";
import { prixodDetails } from "app/slices/documentSlices/receivedMaterialSlices/type";
import {
  fetchOwnWarHouse,
  fetchSkladCM,
} from "app/slices/handbookSlices/wareHouseSlices/wareHouseSlices";
import {
  movementMaterialsFilter,
  movementMaterialsForm,
} from "app/states/documents";
import { unitsMeasurementOptions } from "app/states/documents/selectStates";
import {
  getSkladCM,
  getWarHouseData,
  loadingAllSklad,
} from "app/states/handbooks/selectStates";
import add_green from "assets/images/icons/add_green.svg";
import {
  ActionBase,
  BaseInput,
  BaseSelect,
  BaseTable,
  Button,
  Content,
  DatePicker,
  FlexBox,
  Form,
  FormButton,
  FormInput,
  FormSelect,
  LoadingAddPages,
  ReactModal,
  Text,
  TextArea,
  Title,
} from "components";
import BaseMarkInput from "components/BaseMarkInput/BaseMarkInput";
import { useFormik } from "formik";
import { forEach, get, isEmpty, isEqual } from "lodash";
import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import { Col, Row } from "react-grid-system";
import { NumericFormat } from "react-number-format";
import { useLocation, useNavigate } from "react-router-dom";
import { ReactSVG } from "react-svg";
import { toast } from "react-toastify";
import HasAccess from "routers/HasAccess";
import { DOCUMENT_KEY, MAIN_DEPARTMENT } from "routers/constants";
import DocumentServices from "services/apiServices/documents";
import {
  MaterialDetailsType,
  NumberDocType,
  SimpleItem,
} from "types/documentsType";
import { ACTION, MESSAGES, PAGE, PROTECTED_TYPE } from "utilities/constants";
import {
  calcTotalAmount,
  checkDateYear,
  removeValueOption,
  textFormatter,
} from "utilities/helpers";
import { MaterialDetailsSchema } from "utilities/schema/document/documentSchemas";

//  Добавить Перемещение сырья и материалов
const AddNewDocToTable = ({
  handleOpenModal,
  handleCloseModal,
  skladId,
  dataTable,
  id,
}: {
  handleOpenModal?: () => void;
  handleCloseModal: () => void;
  skladId: number | null;
  dataTable: Array<MaterialDetailsType>;
  id: number | null | undefined;
}) => {
  const dispatch = useAppDispatch();
  const unitsMeasurementDropdowns = useAppSelector(unitsMeasurementOptions);
  const [materialOptions, setMaterialOptions] = useState();
  const [loadingOptions, setLoading] = useState(false);
  const details = dataTable?.find((value) => value.materialId === id);
  const onSubmit = (values: detailsMovement) => {
    const res = {
      ...values,
      materialId: get(values, "materialId.value", null),
      imyaMateriala: get(values, "materialId.label", ""),
      material: get(values, "materialId", null),
    };
    if (isEmpty(details)) {
      dispatch(movementMaterialSubject({ data: res, type: ACTION.ADD }));
    } else {
      dispatch(
        movementMaterialSubject({
          data: res,
          type: ACTION.UPDATE,
          id,
        })
      );
    }
    resetForm();
    handleCloseModal();
  };

  const fetchMaterialPrice = useCallback(() => {
    setLoading(true);
    DocumentServices.FetchMovementMaterialPrices({ skladId }).then((res) => {
      setLoading(false);
      if (res.status === 200) {
        const list = get(res, "data.data", []).map((item: prixodDetails) => ({
          label: item.imyaMateriala,
          value: item.materialId,
          edIzmId: item.edIzmId,
          sena: item.sena,
          kol: item.kol,
          imyaEdIzm: item.imyaEdIzm,
        }));
        setMaterialOptions(list);
      }
    });
  }, [skladId]);
  const { values, handleSubmit, setFieldValue, resetForm } = useFormik({
    enableReinitialize: true,
    initialValues: {
      imyaEdIzm: get(details, "imyaEdIzm", ""),
      materialId: {
        value: get(details, "materialId", ""),
        label: get(details, "imyaMateriala", ""),
      },
      edIzmId: get(details, "edIzmId", null),
      kol: get(details, "kol", ""),
      maxKol: get(details, "maxKol", 0),
      sena: get(details, "sena", 0),
      summa: get(details, "summa", 0),
      imyaMateriala: get(details, "imyaMateriala", ""),
    },
    validationSchema: MaterialDetailsSchema,
    onSubmit,
  });

  const handleCalcTotalAmt = useCallback(() => {
    const totalAmt = calcTotalAmount({
      amount: get(values, "sena", ""),
      quantity: get(values, "kol", ""),
    });
    setFieldValue("summa", totalAmt);
  }, [values.sena, values.kol]);

  useEffect(() => {
    fetchMaterialPrice();
  }, [fetchMaterialPrice]);

  useEffect(() => {
    handleCalcTotalAmt();
  }, [handleCalcTotalAmt]);
  return (
    <>
      <Col xs={12} className="mt-16 text-left">
        <Title sm bold>
          Ввод данных
        </Title>
      </Col>
      <Col xs={12} style={{ padding: 0 }} className={"my-16"}>
        <hr />
      </Col>
      <Form onSubmit={handleSubmit} align={"center"}>
        <Col xs={12} className={"px-24"}>
          <FormSelect
            isLoading={loadingOptions}
            id={"materialId"}
            label={"Сырьё и материал"}
            required={true}
            isSearchable
            value={get(values, "materialId.value", "")}
            defaultValue={get(values, "materialId", "")}
            options={removeValueOption({
              currentOption: materialOptions,
              removeValueList: dataTable,
              valueName: "materialId",
              isUpdate: get(details, "material", null),
            })}
            handleValueLabel={(e: any) => {
              setFieldValue("materialId", e);
              setFieldValue("edIzmId", e.edIzmId);
              setFieldValue("imyaEdIzm", e.imyaEdIzm);
              setFieldValue("sena", e.sena);
              setFieldValue("maxKol", e.kol);
            }}
          />
          <Row align="center" className="mt-24">
            <Col xs={4} style={{ paddingRight: 0 }}>
              <Title>
                Количество<span className="required_point">*</span>
              </Title>
            </Col>
            <Col xs={8} className="form-input-date_picker">
              <Row>
                <Col xs={7}>
                  <BaseMarkInput
                    id={"kol"}
                    value={get(values, "kol", "")}
                    placeholder={` до: ${textFormatter(
                      get(values, "maxKol", 0)
                    )}`}
                    handleInput={(e: number) => {
                      setFieldValue("kol", e);
                    }}
                    max={get(values, "maxKol", 0)}
                    maxLength={40}
                  />
                  {Number(get(values, "kol", 0)) >
                    Number(get(values, "maxKol", 0)) && (
                      <Text xs danger>
                        ограничение количество до:
                        {textFormatter(get(values, "maxKol", 0))}
                      </Text>
                    )}
                </Col>
                <Col xs={5} style={{ paddingLeft: 0 }}>
                  <FlexBox>
                    <Text xs>Ед.изм:</Text>
                    <BaseSelect
                      id={"edIzmId"}
                      isSearchable
                      value={get(values, "edIzmId", "")}
                      options={unitsMeasurementDropdowns}
                      isDisabled={true}
                    />
                  </FlexBox>
                </Col>
              </Row>
            </Col>
          </Row>
          <FormInput
            label={"Цена"}
            value={get(values, "sena", "")}
            disabled={true}
            required={true}
          />
          <FormInput
            label={"Сумма"}
            value={get(values, "summa", "")}
            thousandSeparator={true}
            fixedDecimalScale={true}
            disabled={true}
            required={true}
          />
          <Col xs={12} className="text-right mt-24 mb-16">
            <Button
              className="mr-8"
              handleClick={handleSubmit}
              green
              inability={
                Number(get(values, "maxKol", 0)) < Number(get(values, "kol", 0))
              }
            >
              {get(details, "edIzmId", null) ? PAGE.CHANGE : PAGE.ADD}
            </Button>
            <Button
              onClick={() => {
                handleCloseModal();
                resetForm();
              }}
              danger
            >
              Закрыть
            </Button>
          </Col>
        </Col>
      </Form>
    </>
  );
};

const AddMovementMaterialContainer = () => {
  const navigate = useNavigate();
  const [disable, setDisable] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const dispatch = useAppDispatch();
  const { state } = useLocation();
  const getWareHouse = useAppSelector(getSkladCM);
  const getOwnWareHouse = useAppSelector(getWarHouseData);
  let loadingOwnSklad = useAppSelector(loadingAllSklad);
  const filter = useAppSelector(movementMaterialsFilter);
  const movementMaterialData = useAppSelector(movementMaterialsForm);
  const [materialId, setMaterial] = useState<number | null | undefined>();
  let LOADING = get(movementMaterialData, "loading", false);
  const handleCloseModal = useCallback(() => {
    setOpenModal(false);
  }, []);

  const handleOpenModal = useCallback((id?: number | null | undefined) => {
    setOpenModal(true);
    setMaterial(id);
  }, []);

  const onSubmit = (values: any) => {
    const subjects = get(movementMaterialData, "data.details", []).map(
      (item: detailsMovement) => ({
        materialId: get(item, "materialId", ""),
        edIzmId: get(item, "edIzmId", ""),
        kol: get(item, "kol", ""),
        sena: get(item, "sena", ""),
        summa: get(item, "summa", ""),
      })
    );
    let valuesToSbm: any = {};

    forEach(values, (value: any, key: any) => {
      valuesToSbm[key] = ["dataDoc", "nomerDoc", "kommentariya"].includes(key)
        ? value
        : get(value, "value", "");
    });

    try {
      DocumentServices.AddMovementMaterials({
        transactionTime: new Date(),
        resultCode: null,
        resultMsg: null,
        data: {
          ...valuesToSbm,
          dataDoc: !isEqual(get(values, "dataDoc", null), null)
            ? get(values, "dataDoc", "")
            : moment(new Date()).format("YYYY-MM-DD"),
          details: subjects,
        },
      }).then((res) => {
        if (get(res, "status", "") == 200) {
          navigate(-1);
          dispatch(fetchMovementMaterials(filter));
          dispatch(fetchMovementMaterialsStat());
          toast.success(MESSAGES.ADDED);
          if (get(state, "id", false)) {
            DocumentServices.RemoveTempsReceivedMaterials(
              get(state, "id", "")
            ).then((res) => {
              // error
              if (get(res, "status", "") == 200) {
                navigate(-1);
                dispatch(fetchMovementMaterialsStat());
                if (get(state, "id", false)) {
                  DocumentServices.RemoveTempsMovementMaterials(
                    get(state, "id", "")
                  ).then((res) => {
                    if (get(res, "status", "") == 200) {
                      dispatch(fetchNotFilledMovementMaterials());
                    }
                  });
                }
              }
            });
          }
        } else {
          setSubmitting(false);
        }
      });
    } catch (err) {
      console.log("err", err);
    }
  };

  const { values, setFieldValue, handleSubmit, isSubmitting, setSubmitting } =
    useFormik({
      enableReinitialize: true,
      initialValues: {
        ...get(movementMaterialData, "data.formIntVls", {}),
        dataDoc: get(state, 'id', false) ? get(movementMaterialData, "data.formIntVls.dataDoc", '') : ''
      },
      onSubmit,
    });

  const saveNotFullFilledForm = useCallback(() => {
    const subjects = get(movementMaterialData, "data.details", []).map(
      (item: prixodDetails) => ({
        materialId: get(item, "materialId", ""),
        imyaMateriala: get(item, "imyaMateriala", ""),
        imyaEdIzm: get(item, "imyaEdIzm", ""),
        edIzmId: get(item, "edIzmId", ""),
        kol: get(item, "kol", ""),
        sena: get(item, "sena", ""),
        summa: get(item, "summa", 0),
      })
    );
    let valuesToSbm: any = {};

    forEach(values, (value: any, key: any) => {
      valuesToSbm[key] = ["dataDoc", "nomerDoc"].includes(key)
        ? value
        : get(value, "value", "");
    });
    try {
      DocumentServices.AddNotFilledMovementMaterials({
        transactionTime: new Date(),
        resultCode: null,
        resultMsg: null,
        data: {
          ...valuesToSbm,
          dataDoc: !isEqual(get(values, "dataDoc", null), null)
            ? get(values, "dataDoc", "")
            : moment(new Date()).format("YYYY-MM-DD"),
          id: get(state, "id", null),
          imyaSkladOtgruzka: get(values, "skladOtgruzkaId.label", ""),
          imyaSkladPrixod: get(values, "skladPrixodId.label", ""),
          litsoPrixod: get(values, "litsoPrixod", ""),
          litsoOtgruzka: get(values, "litsoOtgruzka", ""),
          details: subjects,
        },
      }).then((res) => {
        if (get(res, "status", "") == 200) {
          navigate(-1);
          dispatch(fetchNotFilledMovementMaterials());
          toast.success(MESSAGES.ADDED);
        }
      });
    } catch (err) {
      console.log("err", err);
    }
  }, [dispatch, navigate, values, movementMaterialData]);

  const getDocNumber = useCallback((date: NumberDocType) => {
    DocumentServices.GetMovementMaterialsNumber(date).then(({ data }) => {
      setFieldValue("nomerDoc", get(data, "data", ""));
      if (get(data, "transactionTime", "")) {
        const transactionTime = get(data, "transactionTime", "");
        const localMaxDate = moment.utc(transactionTime).add(5, 'hours').format("YYYY-MM-DD HH:mm");
        const localDate = moment.utc(transactionTime).add(5, 'hours').format("YYYY-MM-DD");
        setFieldValue("maxDate", localMaxDate);
        if (isEmpty(get(values, 'dataDoc', ''))) {
          setFieldValue("dataDoc", localDate);
        }
      }
    });
  }, [values]);

  const handleClickTemp = useCallback(() => {
    if (get(state, "id", false)) {
      if (get(state, "isTemp", false)) {
        dispatch(fetchMovementMaterialsByTempId(get(state, "id", "")));
      } else {
        dispatch(fetchMovementMaterialsById(get(state, "id", "")));
        setDisable(true);
      }
    }
  }, [dispatch, state]);

  useEffect(() => {
    handleClickTemp();
  }, [handleClickTemp]);
  const callDetails = useCallback(() => {
    if (isEmpty(get(state, "id", null))) {
      getDocNumber({});
      dispatch(fetchSkladCM({}));
      dispatch(fetchOwnWarHouse({ tip: 1, all: true }));
    }
  }, [dispatch]);
  useEffect(() => {
    callDetails();
  }, [dispatch]);
  return (
    <Content className={"min_height"}>
      {/* modal */}
      <ReactModal isOpen={openModal}>
        <AddNewDocToTable
          handleCloseModal={handleCloseModal}
          skladId={get(values, "skladOtgruzkaId.value", "")}
          dataTable={get(movementMaterialData, "data.details", [])}
          id={materialId}
        />
      </ReactModal>
      {isEqual(LOADING, false) ? (
        <Form onSubmit={handleSubmit}>
          <Row>
            <Col xs={12}>
              <FlexBox
                bold
                sm
                justify={"space-between"}
                style={{ width: "100%" }}
              >
                <Title bold sm>
                  Реквизиты
                </Title>
                <Button
                  green
                  handleClick={saveNotFullFilledForm}
                  disabled={disable}
                >
                  Сохранить
                </Button>
              </FlexBox>
            </Col>
            <Col xs={12} style={{ padding: 0 }} className={"my-16"}>
              <hr />
            </Col>
          </Row>
          <Row justify="start">
            <Col xs={6}>
              <Row align="center">
                <Col xs={4}>
                  <Title>
                    Номер<span className="required_point">*</span>
                  </Title>
                </Col>
                <Col xs={8} className="form-input-date_picker">
                  <Row>
                    <Col xs={6}>
                      <BaseInput
                        id={"nomerDoc"}
                        value={get(values, "nomerDoc", "")}
                      />
                    </Col>
                    <Col xs={6} style={{ paddingLeft: 0 }}>
                      <FlexBox>
                        <Text>от:</Text>
                        <DatePicker
                          id={"dataDoc"}
                          value={get(values, "dataDoc", null)}
                          handleDate={(e: any) => {
                            getDocNumber({
                              year: checkDateYear({
                                currentYear: get(values, "dataDoc", null),
                                checkYear: moment(e).format("YYYY-MM-DD"),
                              }),
                            });
                            setFieldValue(
                              "dataDoc",
                              moment(e).format("YYYY-MM-DD")
                            );
                          }}
                          dateFormat="dd.MM.yyyy"
                          disabled={disable}
                          // maxDate={new Date()}
                          maxDate={new Date(get(values, 'maxDate', new Date()))}
                        />
                      </FlexBox>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col xs={6}>
              <Row align="center" className="mt-24">
                <Col xs={4}>
                  <Title>
                    Склад отгрузки<span className="required_point">*</span>
                  </Title>
                </Col>

                <Col xs={8}>
                  <BaseSelect
                    id={"skladOtgruzkaId"}
                    isSearchable
                    isLoading={loadingOwnSklad}
                    value={get(values, "skladOtgruzkaId.value", "")}
                    options={getOwnWareHouse}
                    defaultValue={get(values, "skladOtgruzkaId", "")}
                    handleValueLabel={(e: any) => {
                      setFieldValue("skladOtgruzkaId", e);
                      setFieldValue(
                        "litsoOtgruzka",
                        get(e, "imyaFizLitsa", null)
                      );
                      if (
                        isEqual(e, get(values, "skladPrixodId", false)) &&
                        e !== get(values, "skladOtgruzkaId", {})
                      ) {
                        setFieldValue("skladPrixodId", null);
                        setFieldValue("litsoPrixod", null);
                      }
                      if (e !== get(values, "skladOtgruzkaId", {})) {
                        dispatch(
                          movementMaterialSubject({
                            data: null,
                            type: ACTION.ADD,
                          })
                        );
                      }
                    }}
                    isDisabled={disable}
                  />
                </Col>
              </Row>
              <Row align="center" className="mt-24">
                <Col xs={4}>
                  <Title>
                    Мат.отв.лицо<span className="required_point">*</span>
                  </Title>
                </Col>
                <Col xs={8}>
                  <BaseInput
                    disabled={true}
                    value={get(values, "litsoOtgruzka", "")}
                  />
                </Col>
              </Row>
              <TextArea
                id={"kommentariya"}
                label={"Коментарии"}
                disabled={disable}
                value={get(values, "kommentariya", "")}
                handleInput={(e: string) => {
                  setFieldValue("kommentariya", e);
                }}
              />
            </Col>
            <Col xs={6}>
              <Row align="center" className="mt-24">
                <Col xs={4} style={{ paddingRight: 0 }}>
                  <Title>
                    Склад оприходования<span className="required_point">*</span>
                  </Title>
                </Col>
                <Col xs={8}>
                  <BaseSelect
                    id={"skladPrixodId"}
                    isSearchable
                    defaultValue={get(values, "skladPrixodId", {})}
                    value={get(values, "skladPrixodId.value", "")}
                    options={getWareHouse.filter(
                      (item: SimpleItem) =>
                        item.value !== get(values, "skladOtgruzkaId.value", "")
                    )}
                    handleValueLabel={(e: any) => {
                      setFieldValue("skladPrixodId", e);
                      setFieldValue("litsoPrixod", get(e, "imyafizList", null));
                    }}
                    isDisabled={disable}
                  />
                </Col>
              </Row>
              <Row align="center" className="mt-24">
                <Col xs={4}>
                  <Title>
                    Мат.отв.лицо<span className="required_point">*</span>
                  </Title>
                </Col>
                <Col xs={8}>
                  <BaseInput
                    disabled={true}
                    value={
                      get(values, "litsoPrixod", "")
                        ? get(values, "litsoPrixod", "")
                        : ""
                    }
                  />
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className="mt-24" justify={"between"}>
            <Col xs={12} className="align-center">
              <FlexBox justify={"space-between"} align={"center"}>
                <Title bold sm>
                  Табличная часть<span className="required_point">*</span>
                </Title>
                {get(values, "skladOtgruzkaId.value", false) && !disable && (
                  <ReactSVG
                    src={add_green}
                    className="svg_icon"
                    onClick={() => handleOpenModal()}
                  />
                )}
              </FlexBox>
            </Col>
            <Col xs={12} style={{ padding: 0 }} className={"my-16"}>
              <hr />
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <Col>
                <BaseTable
                  tableHeader={[
                    "№",
                    "Сырьё и материал",
                    "Ед. изм",
                    "Количество",
                    "Цена",
                    "Сумма",
                    "",
                  ]}
                >
                  {!isEmpty(get(movementMaterialData, "data.details", [])) ? (
                    get(movementMaterialData, "data.details", []).map(
                      (item: prixodDetails, index: number) => (
                        <tr key={index + 1}>
                          <td>{index + 1}</td>
                          <td>{get(item, "imyaMateriala", "-")}</td>
                          <td>{get(item, "imyaEdIzm", "-")}</td>
                          <td>
                            <NumericFormat
                              displayType="text"
                              value={get(item, "kol", "")}
                              thousandSeparator
                              fixedDecimalScale={false}
                            />
                          </td>
                          <td>
                            <NumericFormat
                              displayType="text"
                              value={get(item, "sena", "")}
                              thousandSeparator
                              decimalScale={2}
                            />
                          </td>
                          <td>
                            <NumericFormat
                              displayType="text"
                              value={get(item, "summa", "")}
                              thousandSeparator
                              decimalScale={2}
                            />
                          </td>
                          <td>
                            <ActionBase
                              handleUpdate={handleOpenModal}
                              handleDelete={() =>
                                dispatch(
                                  movementMaterialSubject({
                                    type: ACTION.DELETE,
                                    id: get(item, "materialId", null),
                                  })
                                )
                              }
                              disable={disable}
                              id={get(item, "materialId", null)}
                            />
                          </td>
                        </tr>
                      )
                    )
                  ) : (
                    <tr>
                      <td colSpan={8}> Нет данных</td>
                    </tr>
                  )}
                </BaseTable>
              </Col>
            </Col>
          </Row>
          <Row className={"mb-16 mt-32 text-end"}>
            <Col xs={12}>
              {/* <Button dark className={"mr-16"} type="submit">
              Печать
            </Button> */}
              <HasAccess>
                {({ userCan }: any) =>
                  userCan({
                    permission: `${DOCUMENT_KEY.PEREMESH_SM}_CREATE`,
                    page: DOCUMENT_KEY.PEREMESH_SM,
                    department: MAIN_DEPARTMENT.DOCUMENTS,
                    type: PROTECTED_TYPE.PERMISSIONS,
                  }) && (
                    <FormButton
                      disabled={disable}
                      loading={isSubmitting}
                      handleClick={handleSubmit}
                    >
                      {PAGE.DONE}
                    </FormButton>
                  )
                }
              </HasAccess>
              <Button danger handleClick={() => navigate(-1)}>
                {PAGE.CLOSE}
              </Button>
            </Col>
          </Row>
        </Form>
      ) : (
        <LoadingAddPages />
      )}
    </Content>
  );
};

export default AddMovementMaterialContainer;
