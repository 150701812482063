import { useAppDispatch, useAppSelector } from "app/hook";
import {
  changeCashSubject,
  fetchRegisterParishCash,
  fetchRegisterParishCashById,
  fetchRegisterParishCashStat,
  fetchRegisterParishCashTemp,
  fetchRegisterParishCashTempById,
  handleFormChangeCash,
} from "app/slices/documentSlices/registerParishCashSlices/registerParishCashSlices";
import { fetchSalesContracts } from "app/slices/documentSlices/registerSalesCntSlice/registerSalesCntSlice";
import { fetchIncomes } from "app/slices/enumerationsSlices/incomeSlices/incomeSlices";
import { fetchAllCounterpart } from "app/slices/handbookSlices/counterpartSlices/counterpartSlices";
import { registerParishCashForm } from "app/states/documents";
import {
  contractorOptions,
  getOnlyContractorIdOptions,
  loadingConterpart,
  loadingDogovar,
} from "app/states/documents/selectStates";
import { getIncomeOptionsData } from "app/states/enumerations/selectStates";
import add_green from "assets/images/icons/add_green.svg";
import {
  ActionBase,
  BaseInput,
  BaseMaskInput,
  BaseSelect,
  BaseTable,
  Button,
  Content,
  DatePicker,
  FlexBox,
  Form,
  FormButton,
  FormInput,
  Input,
  LoadingAddPages,
  ReactModal,
  Text,
  TextArea,
  Title,
} from "components";
import { useFormik } from "formik";
import { forEach, get, isEmpty, isEqual } from "lodash";
import moment from "moment";
import { useCallback, useEffect, useState, useMemo } from "react";
import { Col, Row } from "react-grid-system";
import { NumericFormat } from "react-number-format";
import { useLocation, useNavigate } from "react-router-dom";
import { ReactSVG } from "react-svg";
import { toast } from "react-toastify";
import HasAccess from "routers/HasAccess";
import { DOCUMENT_KEY, MAIN_DEPARTMENT } from "routers/constants";
import DocumentServices from "services/apiServices/documents";
import { ACTION, MESSAGES, PAGE, PROTECTED_TYPE } from "utilities/constants";
import {
  checkDateYear,
  removeValueOption,
  textFormatter,
} from "utilities/helpers";
import { regisCashDetailsSchema } from "utilities/schema/document/documentSchemas";
import { NumberDocType } from "types/documentsType";

// Добавить Регистрация прихода безналичных денег
type OptionType = {
  label: string;
  value: number | string;
};

type ContractDetails = {
  nomerDoc: string;
  dataDoc: string;
  kontragentId: OptionType | {};
  dataIsp: string;
  sposobOplataId: OptionType | {};
  sposobDostavkaId: OptionType | {};
};

const AddNewDocToTable = ({
  handleOpenModal,
  handleCloseModal,
  tableData,
  docId,
  id,
}: {
  handleOpenModal?: () => void;
  handleCloseModal: () => void;
  tableData: Array<any>;
  docId: string | null;
  id: number | null;
}) => {
  const dispatch = useAppDispatch();
  const contractorDropdowns = useAppSelector(contractorOptions);
  const typeIncome = useAppSelector(getIncomeOptionsData);
  const [maxAmount, setMaxAmount] = useState<any>(0);
  let loading_kotragent = useAppSelector(loadingConterpart);
  let loading_dogovor = useAppSelector(loadingDogovar);
  const details = tableData?.find((item: any, index: number) => index === id);

  const onSubmit = (values: any) => {
    if (isEmpty(details)) {
      dispatch(handleFormChangeCash(values));
    } else {
      dispatch(changeCashSubject({ type: ACTION.UPDATE, data: values, id }));
    }
    resetForm();
    handleCloseModal();
  };

  const { values, handleSubmit, setFieldValue, resetForm } = useFormik({
    initialValues: {
      tipPrixodaId: get(details, "tipPrixodaId", null),
      kontragentId: get(details, "kontragentId", ""),
      imyaTipPrixoda: get(details, "imyaTipPrixoda", null),
      imyaKontragent: get(details, "imyaKontragent", null),
      nomerDogProdaja: get(details, "nomerDogProdaja", null),
      dogProdajaId: get(details, "dogProdajaId", null),
      kommentariya: get(details, "kommentariya", ""),
      summa: get(details, "summa", ""),
    },
    enableReinitialize: true,
    validationSchema: regisCashDetailsSchema,
    onSubmit,
  });
  const getSaleContracts = useAppSelector((store) =>
    getOnlyContractorIdOptions(store, get(values, "kontragentId", null))
  );

  const fetchDogDetails = useCallback(() => {
    if (get(values, "tipPrixodaId", "")) {
      switch (get(values, "tipPrixodaId", "").toString()) {
        case "1":
          dispatch(fetchAllCounterpart({ all: true, type: 5 }));
          dispatch(
            fetchSalesContracts({
              all: true,
              filterPage: "PRIXOD_D",
              kontragentId: get(values, "kontragentId", null),
            })
          );
          break;
        case "2":
          dispatch(fetchAllCounterpart({ all: true, type: 2 }));
          break;
        case "3":
          dispatch(
            fetchAllCounterpart({ all: true, type: 1, filterPage: "PRIXOD_D" })
          );
          break;
        default:
          break;
      }
    }
  }, [dispatch, values.kontragentId, values.tipPrixodaId]);

  const fetchSelectors = useCallback(() => {
    dispatch(fetchIncomes({ all: true }));
  }, [dispatch]);

  const fetchPriceByKontragent = useCallback(() => {
    try {
      if (
        get(values, "kontragentId", null) &&
        isEqual(get(values, "tipPrixodaId", ""), 3)
      ) {
        const params = {
          id: get(values, "kontragentId", null),
          prixodBDId: docId,
        };
        DocumentServices.FetchPriceByArrivalCash(params).then(({ data }) => {
          setMaxAmount(data.data);
        });
      }
    } catch (error) {
      console.log(error);
    }
  }, [values.kontragentId]);

  const fetchPriceWidthDogovorId = useCallback(() => {
    try {
      if (
        get(values, "kontragentId", null) &&
        get(values, "dogProdajaId", null) &&
        isEqual(get(values, "tipPrixodaId", ""), 1)
      ) {
        const paramps = {
          kontragentId: get(values, "kontragentId"),
          dogProdajaId: get(values, "dogProdajaId", null),
          prixodBDId: docId,
        };
        DocumentServices.FetchPriceByArrivalCashWidthDog(paramps).then(
          ({ data }) => {
            if (data) {
              setMaxAmount(data.data);
            }
          }
        );
      }
    } catch (error) {
      console.log(error);
    }
  }, [values.kontragentId, values.dogProdajaId]);

  useEffect(() => {
    fetchSelectors();
  }, [fetchSelectors]);

  useEffect(() => {
    fetchPriceWidthDogovorId();
  }, [fetchPriceWidthDogovorId]);

  useEffect(() => {
    fetchDogDetails();
  }, [fetchDogDetails]);

  useEffect(() => {
    fetchPriceByKontragent();
  }, [fetchPriceByKontragent]);
  return (
    <>
      <Col xs={12} className="mt-16 text-left">
        <Title sm bold>
          Ввод данных
        </Title>
      </Col>
      <Col xs={12} style={{ padding: 0 }} className={"my-16"}>
        <hr />
      </Col>
      <Form onSubmit={handleSubmit} align={"center"}>
        <Col xs={12} className={"px-24"}>
          <Row align="center">
            <Col xs={4}>
              <Title>
                Тип прихода
                <span className="required_point">*</span>
              </Title>
            </Col>
            <Col xs={8}>
              <BaseSelect
                id={"tipPrixodaId"}
                isSearchable
                value={get(values, "tipPrixodaId", "")}
                options={typeIncome}
                handleValueLabel={(e: any) => {
                  setFieldValue("tipPrixodaId", e.value);
                  setFieldValue("imyaTipPrixoda", e.label);
                  if (!isEqual(e.value, get(values, "tipPrixodaId", false))) {
                    setFieldValue("kontragentId", null);
                    setFieldValue("imyaKontragent", null);
                    setFieldValue("zakazNaryadId", null);
                    setFieldValue("imyaZakazNaryada", null);
                    setFieldValue("dogProdajaId", null);
                    setFieldValue("nomerDogProdaja", null);
                    setFieldValue("summa", "");
                    setMaxAmount(null);
                  }
                }}
              />
            </Col>
          </Row>
          <Row align="center" className="mt-24">
            <Col xs={4}>
              <Title>
                Контрагент
                <span className="required_point">*</span>
              </Title>
            </Col>
            <Col xs={8}>
              <BaseSelect
                id={"kontragentId"}
                isSearchable
                isLoading={loading_kotragent}
                value={get(values, "kontragentId", "")}
                defaultValue={{
                  label: get(details, "imyaKontragent", null),
                  value: get(details, "kontragentId"),
                }}
                options={contractorDropdowns}
                handleValueLabel={(e: any) => {
                  setFieldValue("kontragentId", e.value);
                  setFieldValue("imyaKontragent", e.label);
                  if (
                    !isEqual(e.value, values.kontragentId) &&
                    !isEqual(get(values, "tipPrixodaId", null), 2)
                  ) {
                    setFieldValue("zakazNaryadId", null);
                    setFieldValue("imyaZakazNaryada", null);
                    setFieldValue("dogProdajaId", null);
                    setFieldValue("nomerDogProdaja", "");
                    setFieldValue("summa", "");
                    setMaxAmount(null);
                  }
                }}
              />
            </Col>
          </Row>
          {isEqual(get(values, "tipPrixodaId", 0), 1) && (
            <Row align="center" className="mt-24">
              <Col xs={4} style={{ paddingRight: 0 }}>
                <Title>
                  Договор продажа
                  <span className="required_point">*</span>
                </Title>
              </Col>
              <Col xs={8}>
                <BaseSelect
                  id={"dogProdajaId"}
                  value={get(values, "dogProdajaId", "")}
                  defaultValue={{
                    value: get(values, "dogProdajaId", null),
                    label: get(values, "nomerDogProdaja", null),
                  }}
                  isLoading={loading_dogovor}
                  options={getSaleContracts}
                  // options={removeValueOption({
                  //   currentOption: getSaleContracts,
                  //   removeValueList: tableData,
                  //   valueName: "dogProdajaId",
                  //   isUpdate: details
                  //     ? {
                  //         value: get(details, "dogProdajaId", null),
                  //         label: get(details, "nomerDogProdaja", null),
                  //       }
                  //     : null,
                  // })}
                  handleValueLabel={(e: any) => {
                    setFieldValue("dogProdajaId", e.value);
                    setFieldValue("nomerDogProdaja", e.label);

                    if (!isEqual(e.value, get(values, "dogProdajaId", false))) {
                      setFieldValue("summa", "");
                    }
                  }}
                />
              </Col>
            </Row>
          )}
          <Row align="center" className="mt-24">
            <Col xs={4} style={{ paddingRight: 0 }}>
              <Title>
                Сумма
                <span className="required_point">*</span>
              </Title>
            </Col>
            <Col xs={8}>
              <BaseMaskInput
                id={"summa"}
                placeholder={
                  maxAmount !== null &&
                    [1, 3].includes(Number(get(values, "tipPrixodaId", "")))
                    ? `до: ${textFormatter(maxAmount)}`
                    : ""
                }
                value={get(values, "summa", "")}
                handleInput={(e: any) => setFieldValue("summa", e)}
              />
              <p>
                {get(values, "summa", 0) > maxAmount &&
                  isEmpty(details) &&
                  maxAmount !== null &&
                  [1, 3].includes(Number(get(values, "tipPrixodaId", ""))) ? (
                  <Text xs danger>
                    ограничение до:
                    {textFormatter(maxAmount)}
                  </Text>
                ) : (
                  ""
                )}
              </p>
            </Col>
          </Row>
          <Input
            id={"kommentariya"}
            label={"Коментарии"}
            value={get(values, "kommentariya", "") || ""}
            handleInput={(e: string) => {
              setFieldValue("kommentariya", e);
            }}
          />
          <Col xs={12} className="text-right mt-24 mb-16">
            <Button
              className="mr-8"
              handleClick={handleSubmit}
              green
              inability={
                maxAmount !== null &&
                isEmpty(details) &&
                Number(get(values, "summa", null)) > Number(maxAmount)
              }
            >
              {get(details, "tipPrixodaId", null) ? PAGE.CHANGE : PAGE.ADD}
            </Button>
            <Button
              onClick={() => {
                handleCloseModal();
                resetForm();
              }}
              danger
            >
              Закрыть
            </Button>
          </Col>
        </Col>
      </Form>
    </>
  );
};
const AddRegisterParishCashContainer = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { state } = useLocation();
  const registerCash = useAppSelector(registerParishCashForm);
  let LOADING = get(registerCash, "loading", []);
  const [openModal, setOpenModal] = useState(false);
  const [disable, setDisable] = useState(false);
  const handleCloseModal = useCallback(() => {
    setOpenModal(false);
  }, []);
  const [detailId, setDetailsId] = useState<number | null>(null);
  const handleOpenModal = useCallback((id: number | null) => {
    setOpenModal(true);
    setDetailsId(id);
  }, []);
  //   add new
  const onSubmit = (values: ContractDetails) => {
    if (get(state, "isUpdate", false)) {
      let valuesToSbm: any = {};

      forEach(values, (value: any, key: any) => {
        valuesToSbm[key] = ["dataDoc", "nomerDoc", "kommentariya"].includes(key)
          ? value
          : get(value, "value", "");
      });

      try {
        DocumentServices.UpdateRegisterParishCash({
          transactionTime: new Date(),
          resultCode: null,
          resultMsg: null,
          data: {
            ...valuesToSbm,
            dataDoc: !isEqual(get(values, "dataDoc", null), null)
              ? get(values, "dataDoc", "")
              : moment(new Date()).format("YYYY-MM-DD"),
            id: get(state, "id", null),
            prixodBDDetailsList: get(
              registerCash,
              "data.prixodBDDetailsList",
              []
            ),
          },
        }).then((res) => {
          setSubmitting(false);
          if (get(res, "status", "") == 200) {
            navigate(-1);
            dispatch(fetchRegisterParishCash({}));
            dispatch(fetchRegisterParishCashTemp());
            toast.success(MESSAGES.ADDED);
          }
        });
      } catch (err) {
        console.log("err", err);
      }
    } else {
      const subjects = get(registerCash, "data.prixodBDDetailsList", []).map(
        (item: any) => ({
          tipPrixodaId: get(item, "tipPrixodaId", ""),
          kontragentId: get(item, "kontragentId", ""),
          zakazNaryadId: get(item, "zakazNaryadId", ""),
          dogProdajaId: get(item, "dogProdajaId", ""),
          summa: get(item, "summa", null),
          kommentariya: get(item, "kommentariya", ""),
        })
      );
      let valuesToSbm: any = {};
      forEach(
        values,
        (value: any, key: any) =>
        (valuesToSbm[key] = ["nomerDoc", "dataDoc", "kommentariya"].includes(
          key
        )
          ? value
          : get(value, "value", ""))
      );

      try {
        DocumentServices.AddRegisterParishCash({
          transactionTime: new Date(),
          resultCode: null,
          resultMsg: null,
          data: {
            ...valuesToSbm,
            dataDoc: !isEqual(get(values, "dataDoc", null), null)
              ? get(values, "dataDoc", "")
              : moment(new Date()).format("YYYY-MM-DD"),
            prixodBDDetailsList: subjects,
          },
        }).then((res) => {
          setSubmitting(false);

          if (get(res, "status", "") == 200) {
            dispatch(fetchRegisterParishCash({}));
            dispatch(fetchRegisterParishCashStat());
            navigate(-1);
            toast.success(MESSAGES.ADDED);
          }
          setSubmitting(false);
        });
      } catch (err) {
        console.log("err", err);
      }
    }
  };
  const { values, setFieldValue, handleSubmit, setSubmitting, isSubmitting } =
    useFormik({
      enableReinitialize: true,
      initialValues: {
        ...get(registerCash, "data.formIntVls", {}),
        dataDoc: get(state, 'id', false) ? get(registerCash, "data.formIntVls.dataDoc", "") : '',
      },
      onSubmit,
    });
  const getDocNumber = useCallback((date: NumberDocType) => {
    DocumentServices.RegisterParishCashNumber(date)
      .then(({ data }) => {
        setFieldValue("nomerDoc", get(data, "data", ""));
        if (get(data, "transactionTime", "")) {
          const transactionTime = get(data, "transactionTime", "");
          const localMaxDate = moment.utc(transactionTime).add(5, 'hours').format("YYYY-MM-DD HH:mm");
          const localDate = moment.utc(transactionTime).add(5, 'hours').format("YYYY-MM-DD");
          setFieldValue("maxDate", localMaxDate);
          if (isEmpty(get(values, 'dataDoc', ''))) {
            setFieldValue("dataDoc", localDate);
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  const handleCheckTemp = useCallback(() => {
    if (get(state, "id", false)) {
      if (get(state, "isTemp", false)) {
        dispatch(fetchRegisterParishCashTempById(get(state, "id", "")));
      } else if (get(state, "isUpdate", null)) {
        dispatch(fetchRegisterParishCashById(get(state, "id", "")))
          .then((res: any) => {
            console.log('first', res)
            if (get(res, "payload.transactionTime", "")) {
              const transactionTime = get(res, "payload.transactionTime", "");
              const localDate = moment.utc(transactionTime).add(5, 'hours').format("YYYY-MM-DD");
              setFieldValue("maxDate", localDate);
              // if (isEmpty(get(values, 'dataDoc', ''))) {
              //   setFieldValue("dataDoc", localDate);
              // }
            }
          });
      } else {
        dispatch(fetchRegisterParishCashById(get(state, "id", "")));
        setDisable(true);
      }
    } else {
      getDocNumber({});
    }
  }, [dispatch, state]);

  let sumWithInitial = get(registerCash, "data.prixodBDDetailsList", []).reduce(
    (accumulator: any, currentValue: any) => accumulator + currentValue.summa,
    0
  );

  useEffect(() => {
    handleCheckTemp();
  }, [dispatch, handleCheckTemp]);

  return (
    <Content className={"min_height"}>
      {/* modal */}
      <ReactModal isOpen={openModal}>
        <AddNewDocToTable
          handleCloseModal={handleCloseModal}
          tableData={get(registerCash, "data.prixodBDDetailsList", [])}
          id={detailId}
          docId={get(state, "id", null)}
        />
      </ReactModal>
      {isEqual(LOADING, false) ? (
        <Form onSubmit={handleSubmit}>
          <Row>
            <Col xs={12}>
              <FlexBox justify={"space-between"} style={{ width: "100%" }}>
                <Title bold sm>
                  Реквизитыc
                </Title>
                {/* {!get(state, "isUpdate", false) && (
                  <Button
                    green
                    handleClick={saveNotFullFilledForm}
                    disabled={disable}
                  >
                    Сохранить
                  </Button>
                )} */}
              </FlexBox>
            </Col>
            <Col xs={12} style={{ padding: 0 }} className={"my-16"}>
              <hr />
            </Col>
          </Row>
          <Row>
            <Col xs={6}>
              <Row align="center">
                <Col xs={4}>
                  <Title>
                    Номер<span className="required_point">*</span>
                  </Title>
                </Col>
                <Col xs={8} className="form-input-date_picker">
                  <Row>
                    <Col xs={6}>
                      <BaseInput
                        id={"nomerDoc"}
                        value={get(values, "nomerDoc", "")}
                      />
                    </Col>
                    <Col xs={6} style={{ paddingLeft: 0 }}>
                      <FlexBox>
                        <Text>от:</Text>
                        <DatePicker
                          id={"dataDoc"}
                          value={get(values, "dataDoc", null)}
                          handleDate={(e: any) => {
                            if (
                              checkDateYear({
                                checkYear: moment(e).format("YYYY-MM-DD"),
                                currentYear: get(values, "dataDoc", null),
                              })
                            ) {
                              getDocNumber({
                                year: checkDateYear({
                                  currentYear: get(values, "dataDoc", null),
                                  checkYear: moment(e).format("YYYY-MM-DD"),
                                }),
                              });
                            }
                            setFieldValue(
                              "dataDoc",
                              moment(e).format("YYYY-MM-DD")
                            );
                          }}
                          dateFormat="dd.MM.yyyy"
                          disabled={disable}
                          maxDate={new Date(get(values, 'maxDate', new Date()))}
                        />
                      </FlexBox>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <FormInput
                label={"Общая сумма"}
                id={"sebestTovar"}
                value={sumWithInitial}
                disabled={true}
              />
              <TextArea
                id={"kommentariya"}
                label={"Коментарии"}
                value={get(values, "kommentariya", "")}
                disabled={disable}
                handleInput={(e: string) => {
                  setFieldValue("kommentariya", e);
                }}
              />
            </Col>
          </Row>
          <Row className="mt-24" justify={"between"}>
            <Col xs={12} className="align-center">
              {isEqual(disable, false) && (
                <FlexBox justify={"space-between"} align={"center"}>
                  <Title bold sm>
                    Табличная часть<span className="required_point">*</span>
                  </Title>
                  <ReactSVG
                    src={add_green}
                    className="svg_icon"
                    onClick={() => handleOpenModal(null)}
                  />
                </FlexBox>
              )}
            </Col>
            <Col xs={12} style={{ padding: 0 }} className={"my-16"}>
              <hr />
            </Col>
          </Row>

          <Row>
            <Col xs={12}>
              <Col className="responsive_overflow">
                <BaseTable
                  tableHeader={[
                    "№",
                    "Тип прихода",
                    "Контрагент",
                    "Договор продажа",
                    "Сумма",
                    "Коментарии",
                    "",
                  ]}
                >
                  {!isEmpty(
                    get(registerCash, "data.prixodBDDetailsList", [])
                  ) ? (
                    get(registerCash, "data.prixodBDDetailsList", []).map(
                      (item: any, index: number) => (
                        <tr key={index + 1}>
                          <td>{index + 1}</td>
                          <td>{get(item, "imyaTipPrixoda", "-")}</td>
                          <td>{get(item, "imyaKontragent", "-")}</td>
                          <td>{get(item, "nomerDogProdaja", "-")}</td>
                          <td>
                            <NumericFormat
                              displayType="text"
                              value={get(item, "summa", "")}
                              thousandSeparator
                              fixedDecimalScale={false}
                              decimalScale={2}
                            />
                          </td>
                          <td>{get(item, "kommentariya", "-")}</td>
                          <td>
                            <ActionBase
                              handleUpdate={handleOpenModal}
                              handleDelete={() =>
                                dispatch(
                                  changeCashSubject({
                                    type: ACTION.DELETE,
                                    id: index,
                                  })
                                )
                              }
                              disable={disable}
                              id={index}
                            />
                          </td>
                        </tr>
                      )
                    )
                  ) : (
                    <tr>
                      <td colSpan={10}> Нет Реквизиты</td>
                    </tr>
                  )}
                </BaseTable>
              </Col>
            </Col>
          </Row>
          <Row className="mb-16 mt-32 text-end">
            <Col xs={12}>
              {get(state, "isUpdate", null) ? (
                <Button
                  primary
                  className={"mr-16"}
                  loading={isSubmitting}
                  handleClick={handleSubmit}
                >
                  {PAGE.CHANGE}
                </Button>
              ) : (
                <HasAccess>
                  {({ userCan }: any) =>
                    userCan({
                      permission: `${DOCUMENT_KEY.PRIXOD_BD}_CREATE`,
                      page: DOCUMENT_KEY.PRIXOD_BD,
                      department: MAIN_DEPARTMENT.DOCUMENTS,
                      type: PROTECTED_TYPE.PERMISSIONS,
                    }) && (
                      <FormButton
                        disabled={disable}
                        loading={isSubmitting}
                        handleClick={handleSubmit}
                      >
                        {PAGE.DONE}
                      </FormButton>
                    )
                  }
                </HasAccess>
              )}
              <Button danger handleClick={() => navigate(-1)}>
                {PAGE.CLOSE}
              </Button>
            </Col>
          </Row>
        </Form>
      ) : (
        <LoadingAddPages />
      )}
    </Content>
  );
};

export default AddRegisterParishCashContainer;
