import { useAppDispatch, useAppSelector } from "app/hook";
import {
  changeDetailsManualPost,
  clearManualPostingDetails,
  fetchAllManualPosting,
  fetchManualPostingStat,
  handleAddSubjectPosting,
  subjectManualPosting,
} from "app/slices/documentSlices/manualPostingSlice/manualPostingSlice";
import { fetchMenageAccount } from "app/slices/handbookSlices/menageAccountSlices/menageAccountSlices";
import {
  manualPostingDetails,
  manualPostingFilter,
} from "app/states/documents";
import { getDebutCreditData } from "app/states/handbooks/selectStates";
import add_green from "assets/images/icons/add_green.svg";
import {
  ActionBase,
  BaseInput,
  BaseTable,
  Button,
  Content,
  DatePicker,
  FlexBox,
  Form,
  FormButton,
  FormSelect,
  Input,
  LoadingAddPages,
  ReactModal,
  Text,
  TextArea,
  Title,
} from "components";
import BaseMarkInput from "components/BaseMarkInput/BaseMarkInput";
import { useFormik } from "formik";
import { get, isEmpty, isEqual } from "lodash";
import moment from "moment";
import { useCallback, useEffect, useRef, useState } from "react";
import { Col, Row } from "react-grid-system";
import { NumericFormat } from "react-number-format";
import { useLocation, useNavigate } from "react-router-dom";
import { ReactSVG } from "react-svg";
import { toast } from "react-toastify";
import HasAccess from "routers/HasAccess";
import { DOCUMENT_KEY, MAIN_DEPARTMENT } from "routers/constants";
import DocumentServices from "services/apiServices/documents";
import { ACTION, MESSAGES, PAGE, PROTECTED_TYPE } from "utilities/constants";
import { manualPostingSchemaDetails } from "utilities/schema/document/documentSchemas";
import { NumberDocType } from "types/documentsType";
import { checkDateYear } from "utilities/helpers";

type ManualPosting = {
  numberDoc: string;
  dataDoc: string;
};
type SubcontoList = { subkontoId: string; subkontoImya: string };
type DebutSubType = {
  debitSubkonto1?: Array<SubcontoList>;
  debitSubkonto2?: Array<SubcontoList>;
  debitSubkonto3?: Array<SubcontoList>;
};
type KreditSubType = {
  kreditSubkonto1?: Array<SubcontoList>;
  kreditSubkonto2?: Array<SubcontoList>;
  kreditSubkonto3?: Array<SubcontoList>;
};
type SubcontoState = {
  subkonto1: boolean;
  subkonto2?: boolean;
  subkonto3?: boolean;
};
enum REKVIZET_NAMES {
  DEBUT_1 = "DEBUT_1",
  DEBUT_2 = "DEBUT_2",
  DEBUT_3 = "DEBUT_3",
  KREDT_1 = "KREDT_1",
  KREDT_2 = "KREDT_2",
  KREDT_3 = "KREDT_3",
  KOL = "KOL",
}

const INFO_TEXT = "Подобная информация уже добавлена";

// Ручные проводки −> Добавить
export const AddContractSubjects = ({
  handleOpenModal,
  handleCloseModal,
  tableDetails,
  id,
}: {
  handleOpenModal?: () => void;
  handleCloseModal: () => void;
  tableDetails: Array<any>;
  id: number | null;
}) => {
  const dispatch = useAppDispatch();
  const debutCreditOptions = useAppSelector(getDebutCreditData);
  const [debutKolAllow, setDebutKol] = useState(false);
  const [creditKolAllow, setCreditKol] = useState(false);
  const details = tableDetails?.find((item, index) => index === id);
  const [debutSubDetails, setDebutSubDetails] = useState<SubcontoState>({
    subkonto1: false,
    subkonto2: false,
    subkonto3: false,
  });
  const [kreditSubDetails, setKreditSubDetails] = useState<SubcontoState>({
    subkonto1: false,
    subkonto2: false,
    subkonto3: false,
  });
  const [debSubkonto1, setDebSubconto1] = useState<Array<any> | null>([]);
  const [debSubkonto2, setDebSubconto2] = useState<Array<any> | null>([]);
  const [debSubkonto3, setDebSubconto3] = useState<Array<any> | null>([]);

  const [kirSubkonto1, setKirSubconto1] = useState<Array<any> | null>([]);
  const [kirSubkonto2, setKirSubconto2] = useState<Array<any> | null>([]);
  const [kirSubkonto3, setKirSubconto3] = useState<Array<any> | null>([]);
  //  loading
  const [debutLoaders, setDebutLoaders] = useState({
    sub1: false,
    sub2: false,
    sub3: false,
  });
  const [kreditLoaders, setKreditLoaders] = useState({
    sub1: false,
    sub2: false,
    sub3: false,
  });
  // const debutLoaders = useRef({ sub1: false, sub2: false, sub3: false })
  // field names
  const deep = [
    "debitSchet",
    "debitSubkonto1Id",
    "debitSubkonto2Id",
    "debitSubkonto3Id",
    "kreditSchet",
    "kreditSubkonto1Id",
    "kreditSubkonto2Id",
    "kreditSubkonto3Id",
  ];

  const onSubmit = (values: any) => {
    if (values && tableDetails) {
      const found = tableDetails.some((elem) => {
        if (
          isEqual(get(elem, `${deep[0]}`), get(values, `${deep[0]}`)) &&
          isEqual(get(elem, `${deep[1]}`), get(values, `${deep[1]}`)) &&
          isEqual(get(elem, `${deep[2]}`), get(values, `${deep[2]}`)) &&
          isEqual(get(elem, `${deep[3]}`), get(values, `${deep[3]}`)) &&
          isEqual(get(elem, `${deep[4]}`), get(values, `${deep[4]}`)) &&
          isEqual(get(elem, `${deep[5]}`), get(values, `${deep[5]}`)) &&
          isEqual(get(elem, `${deep[6]}`), get(values, `${deep[6]}`)) &&
          isEqual(get(elem, `${deep[7]}`), get(values, `${deep[7]}`))
        ) {
          return true;
        } else {
          return false;
        }
      });

      // allow when trying to change the object, but give an error when re-entering
      if (found && isEmpty(details)) {
        toast.info(INFO_TEXT);
      } else {
        // check kol

        // add
        if (isEmpty(details)) {
          // if have not details
          dispatch(handleAddSubjectPosting(values));
          handleCloseModal();
          resetForm();
        } else {
          !checkIsAvailable({
            kschot: get(values, "kreditSchet", null),
            dschot: get(values, "debitSchet", null),
            rekvizetName: REKVIZET_NAMES.KOL,
          })
            ? dispatch(
              changeDetailsManualPost({
                type: ACTION.UPDATE,
                data: { ...values, kol: null },
                id,
              })
            )
            : dispatch(
              changeDetailsManualPost({
                type: ACTION.UPDATE,
                data: values,
                id,
              })
            );
          handleCloseModal();
          resetForm();
        }
      }
    } else {
      dispatch(handleAddSubjectPosting(values));
      handleCloseModal();
      resetForm();
    }
  };
  const { values, handleSubmit, resetForm, setFieldValue, errors, touched } =
    useFormik({
      initialValues: {
        debitSchet: get(details, "debitSchet", ""),
        debitSubkonto1: get(details, "debitSubkonto1", ""),
        debitSubkonto1Id: get(details, "debitSubkonto1Id", null),
        debitSubkonto2: get(details, "debitSubkonto2", ""),
        debitSubkonto2Id: get(details, "debitSubkonto2Id", null),
        debitSubkonto3: get(details, "debitSubkonto3", ""),
        debitSubkonto3Id: get(details, "debitSubkonto3Id", null),
        kreditSchet: get(details, "kreditSchet", ""),
        kreditSubkonto1: get(details, "kreditSubkonto1", null),
        kreditSubkonto1Id: get(details, "kreditSubkonto1Id", null),
        kreditSubkonto2: get(details, "kreditSubkonto2", null),
        kreditSubkonto2Id: get(details, "kreditSubkonto2Id", null),
        kreditSubkonto3: get(details, "kreditSubkonto3", null),
        kreditSubkonto3Id: get(details, "kreditSubkonto3Id", null),
        kol: get(details, "kol", null),
        summa: get(details, "summa", null),
        kommentari: get(details, "kommentari", ""),
      },
      enableReinitialize: true,
      validationSchema: manualPostingSchemaDetails,
      onSubmit,
    });
  const checkIsAvailable = ({ dschot, rekvizetName, kschot }: any) => {
    if (kschot || dschot) {
      switch (rekvizetName) {
        case REKVIZET_NAMES.DEBUT_1:
          if (!isEqual(get(values, "debitSchet.subkonto1", null), null)) {
            return true;
          }
          return false;
        case REKVIZET_NAMES.DEBUT_2:
          if (!isEqual(get(values, "debitSchet.subkonto2", null), null)) {
            return true;
          }
          return false;
        case REKVIZET_NAMES.DEBUT_3:
          if (!isEqual(get(values, "debitSchet.subkonto3", null), null)) {
            return true;
          }
          return false;
        case REKVIZET_NAMES.KREDT_1:
          if (get(values, "kreditSchet.subkonto1", null)) {
            return true;
          }
          return false;
        case REKVIZET_NAMES.KREDT_2:
          if (get(values, "kreditSchet.subkonto2", null)) {
            return true;
          }
          return false;
        case REKVIZET_NAMES.KREDT_3:
          if (get(values, "kreditSchet.subkonto3", null)) {
            return true;
          }
          return false;
        case REKVIZET_NAMES.KOL:
          if (debutKolAllow || creditKolAllow) {
            return true;
          }
          return false;
      }
    }
  };

  const fetchSubkonto = useCallback(() => {
    const req = details
      ? {
        schet: get(values, "debitSchet.value", null),
        subkonto1Id: debutSubDetails?.subkonto1
          ? values.debitSubkonto1Id
          : null,
        subkonto2Id: debutSubDetails?.subkonto2
          ? values.debitSubkonto2Id
          : null,
      }
      : {
        schet: get(values, "debitSchet.value", null),
        subkonto1Id: values.debitSubkonto1Id,
        subkonto2Id: values.debitSubkonto2Id,
      };
    // subkonto 1
    if (
      get(req, "schet", null) &&
      isEmpty(get(values, "debitSubkonto1Id", null))
    ) {
      setDebutLoaders({ ...debutLoaders, sub1: true });
      DocumentServices.FetchManualPostingSubconto(req)
        .then(({ data }) => {
          setDebutLoaders({ ...debutLoaders, sub1: false });
          const subcontoList = get(data, "data.list", []).map(
            (item: SubcontoList) => ({
              label: get(item, "subkontoImya", ""),
              value: get(item, "subkontoId", ""),
            })
          );
          switch (data.data.type.toString()) {
            case "1":
              setDebSubconto1(subcontoList);
              break;
            case "2":
              setDebSubconto2(subcontoList);
              break;
            case "3":
              setDebSubconto3(subcontoList);
              break;
            default:
              break;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
    // subkonto 2
    if (
      get(values, "debitSchet.value", null) &&
      get(values, "debitSubkonto1Id", null) &&
      isEmpty(get(values, "debitSubkonto2Id", null))
    ) {
      setDebutLoaders({ ...debutLoaders, sub1: true, sub2: true });
      DocumentServices.FetchManualPostingSubconto({
        schet: get(values, "debitSchet.value", null),
        subkonto1Id: get(values, "debitSubkonto1Id", null),
      })
        .then(({ data }) => {
          setDebutLoaders({ ...debutLoaders, sub1: false, sub2: false });

          const subcontoList = get(data, "data.list", []).map(
            (item: SubcontoList) => ({
              label: get(item, "subkontoImya", ""),
              value: get(item, "subkontoId", ""),
            })
          );
          setDebSubconto2(subcontoList);
        })
        .catch((err) => console.log(err));
    }
    // subkonto 3
    if (
      get(values, "debitSchet.value", null) &&
      get(values, "debitSubkonto1Id", null) &&
      get(values, "debitSubkonto2Id", null)
    ) {
      setDebutLoaders({ sub1: true, sub2: true, sub3: true });
      DocumentServices.FetchManualPostingSubconto({
        schet: get(values, "debitSchet.value", null),
        subkonto1Id: get(values, "debitSubkonto1Id", null),
        subkonto2Id: get(values, "debitSubkonto2Id", null),
      })
        .then(({ data }) => {
          setDebutLoaders({ sub1: false, sub2: false, sub3: false });
          const subcontoList = get(data, "data.list", []).map(
            (item: SubcontoList) => ({
              label: get(item, "subkontoImya", ""),
              value: get(item, "subkontoId", ""),
            })
          );
          setDebSubconto3(subcontoList);
        })
        .catch((err) => console.log(err));
    }
  }, [
    values.debitSchet,
    values.debitSubkonto1Id,
    values.debitSubkonto2Id,
    values.debitSubkonto3Id,
    details,
  ]);

  const fetchKreditSubkonto = useCallback(() => {
    const req = details
      ? {
        schet: get(values, "kreditSchet.value", null),
        subkonto1Id: kreditSubDetails?.subkonto1
          ? values.kreditSubkonto1Id
          : null,
        subkonto2Id: kreditSubDetails?.subkonto2
          ? values.kreditSubkonto2Id
          : null,
      }
      : {
        schet: get(values, "kreditSchet.value", null),
        subkonto1Id: values.kreditSubkonto1Id,
        subkonto2Id: values.kreditSubkonto2Id,
      };
    // subkonot 1

    if (
      get(req, "schet", null) &&
      isEmpty(get(values, "kreditSubkonto1Id", null))
    ) {
      console.log("rund");
      setKreditLoaders({ ...kreditLoaders, sub1: true });
      DocumentServices.FetchManualPostingSubconto(req)
        .then(({ data }: any) => {
          setKreditLoaders({ ...kreditLoaders, sub1: false });
          const subcontoList = get(data, "data.list", []).map(
            (item: SubcontoList) => ({
              label: get(item, "subkontoImya", ""),
              value: get(item, "subkontoId", ""),
            })
          );
          switch (data.data.type.toString()) {
            case "1":
              setKirSubconto1(subcontoList);
              break;
            case "2":
              setKirSubconto2(subcontoList);
              break;
            case "3":
              setKirSubconto3(subcontoList);
              break;
            default:
              break;
          }
        })
        .catch((err) => console.log(err));
    }
    // subkonto 2
    if (
      get(values, "kreditSchet.value", null) &&
      get(values, "kreditSubkonto1Id", null) &&
      isEmpty(get(values, "kreditSubkonto2Id", null))
    ) {
      setKreditLoaders({ ...kreditLoaders, sub1: true, sub2: true });
      DocumentServices.FetchManualPostingSubconto({
        schet: get(values, "kreditSchet.value", null),
        subkonto1Id: get(values, "kreditSubkonto1Id", null),
      })
        .then(({ data }) => {
          setKreditLoaders({ ...kreditLoaders, sub1: false, sub2: false });
          const subcontoList = get(data, "data.list", []).map(
            (item: SubcontoList) => ({
              label: get(item, "subkontoImya", ""),
              value: get(item, "subkontoId", ""),
            })
          );
          setKirSubconto2(subcontoList);
        })
        .catch((err) => console.log(err));
    }
    //  subkonot 3
    if (
      get(values, "kreditSchet.value", null) &&
      get(values, "kreditSubkonto1Id", null) &&
      get(values, "kreditSubkonto2Id", null)
    ) {
      setKreditLoaders({
        ...kreditLoaders,
        sub1: true,
        sub2: true,
        sub3: true,
      });
      DocumentServices.FetchManualPostingSubconto({
        schet: get(values, "kreditSchet.value", null),
        subkonto1Id: get(values, "kreditSubkonto1Id", null),
        subkonto2Id: get(values, "kreditSubkonto2Id", null),
      })
        .then(({ data }) => {
          setKreditLoaders({
            ...kreditLoaders,
            sub1: false,
            sub2: false,
            sub3: false,
          });
          const subcontoList = get(data, "data.list", []).map(
            (item: SubcontoList) => ({
              label: get(item, "subkontoImya", ""),
              value: get(item, "subkontoId", ""),
            })
          );
          setKirSubconto3(subcontoList);
        })
        .catch((err) => console.log(err));
    }
  }, [
    values.kreditSchet,
    values.kreditSubkonto1Id,
    values.kreditSubkonto2Id,
    values.kreditSubkonto3Id,
    details,
    id,
  ]);
  useEffect(() => {
    fetchSubkonto();
  }, [fetchSubkonto]);
  // values.debitSchet,
  // values.debitSubkonto1Id,
  // values.debitSubkonto2Id,
  // details,
  useEffect(() => {
    fetchKreditSubkonto();
  }, [
    fetchKreditSubkonto,
    // values.kreditSchet,
    // values.kreditSubkonto1Id,
    // values.kreditSubkonto2Id,
    // details,
  ]);
  return (
    <>
      <Form onSubmit={handleSubmit}>
        <Col xs={12} className="mt-16 text-left">
          <Title sm bold>
            {PAGE.ADD_DATA}
          </Title>
        </Col>
        <Col xs={12} style={{ padding: 0 }} className={"my-16"}>
          <hr />
        </Col>
        <Col xs={12} className={"px-24"}>
          <FormSelect
            isSearchable
            value={get(values, "debitSchet", "")}
            label={"Дебет счет"}
            isDisabled={debutLoaders.sub1}
            defaultValue={{
              label: get(values, "debitSchet.label", ""),
              value: get(values, "debitSchet.value", ""),
            }}
            required={true}
            options={debutCreditOptions}
            className="mt-24"
            handleValueLabel={(e: any) => {
              setFieldValue("debitSchet", e);
              setDebutKol(get(e, "kol", false));
              if (!isEqual(e.value, values.debitSchet)) {
                // clearing
                setDebSubconto1([]);
                setDebSubconto2([]);
                setDebSubconto3([]);
                setFieldValue("debitSubkonto1Id", null);
                setFieldValue("debitSubkonto1", null);
                setFieldValue("debitSubkonto2Id", null);
                setFieldValue("debitSubkonto2", null);
                setFieldValue("debitSubkonto3", null);
                setFieldValue("debitSubkonto3Id", null);
              }
            }}
          />
          {checkIsAvailable({
            dschot: get(values, "debitSchet", null),
            rekvizetName: REKVIZET_NAMES.DEBUT_1,
          }) && (
              <FormSelect
                isSearchable
                isLoading={debutLoaders.sub1}
                value={get(values, "debitSubkonto1Id", "")}
                label={"Субконто1"}
                required={true}
                defaultValue={{
                  label: get(values, "debitSubkonto1", ""),
                  value: get(values, "debitSubkonto1Id", ""),
                }}
                isDisabled={debutLoaders.sub2}
                options={debSubkonto1}
                className="mt-24"
                handleValueLabel={(e: any) => {
                  setFieldValue("debitSubkonto1Id", e.value);
                  setFieldValue("debitSubkonto1", e.label);
                  setDebutSubDetails({ ...debutSubDetails, subkonto1: true });

                  if (!isEqual(e.value, values.debitSubkonto1Id)) {
                    setDebSubconto2([]);
                    setDebSubconto3([]);
                    setFieldValue("debitSubkonto2", null);
                    setFieldValue("debitSubkonto2Id", null);
                    setFieldValue("debitSubkonto3Id", null);
                  }
                }}
              />
            )}
          {checkIsAvailable({
            dschot: get(values, "debitSchet", null),
            rekvizetName: REKVIZET_NAMES.DEBUT_2,
          }) && (
              <FormSelect
                isSearchable
                value={get(values, "debitSubkonto2Id", "")}
                label={"Субконто2"}
                required={true}
                isLoading={debutLoaders.sub2}
                isDisabled={debutLoaders.sub3}
                defaultValue={{
                  label: get(values, "debitSubkonto2", ""),
                  value: get(values, "debitSubkonto2Id", ""),
                }}
                options={debSubkonto2}
                className="mt-24"
                handleValueLabel={(e: any) => {
                  setFieldValue("debitSubkonto2Id", e.value);
                  setFieldValue("debitSubkonto2", e.label);
                  setDebutSubDetails({ ...debutSubDetails, subkonto2: true });
                  if (!isEqual(e.value, values.debitSubkonto2Id)) {
                    setDebSubconto3([]);
                    setFieldValue("debitSubkonto3Id", null);
                  }
                }}
              />
            )}
          {checkIsAvailable({
            dschot: get(values, "debitSchet", null),
            rekvizetName: REKVIZET_NAMES.DEBUT_3,
          }) && (
              <FormSelect
                isSearchable
                value={get(values, "debitSubkonto3Id", "")}
                label={"Субконто3"}
                isLoading={debutLoaders.sub3}
                required={true}
                defaultValue={{
                  label: get(values, "debitSubkonto3", ""),
                  value: get(values, "debitSubkonto3Id", ""),
                }}
                options={debSubkonto3}
                className="mt-24"
                handleValueLabel={(e: any) => {
                  setFieldValue("debitSubkonto3Id", e.value);
                  setFieldValue("debitSubkonto3", e.label);
                  setDebutSubDetails({ ...debutSubDetails, subkonto3: true });
                }}
              />
            )}
          <FormSelect
            isSearchable
            value={get(values, "kreditSchet.value", "")}
            label={"Кредит счет"}
            isDisabled={kreditLoaders.sub1}
            required={true}
            defaultValue={{
              label: get(values, "kreditSchet.label", ""),
              value: get(values, "kreditSchet.value", ""),
            }}
            options={debutCreditOptions}
            className="mt-24"
            handleValueLabel={(e: any) => {
              setFieldValue("kreditSchet", e);
              setCreditKol(get(e, "kol", false));
              if (!isEqual(e.value, values.kreditSchet)) {
                setKirSubconto1([]);
                setKirSubconto2([]);
                setKirSubconto3([]);
                setFieldValue("kreditSubkonto1", null);
                setFieldValue("kreditSubkonto1Id", null);
                setFieldValue("kreditSubkonto2", null);
                setFieldValue("kreditSubkonto2Id", null);
                setFieldValue("kreditSubkonto3", null);
                setFieldValue("kreditSubkonto3Id", null);
              }
            }}
          />
          {checkIsAvailable({
            kschot: get(values, "kreditSchet", null),
            rekvizetName: REKVIZET_NAMES.KREDT_1,
          }) && (
              <FormSelect
                isSearchable
                isLoading={kreditLoaders.sub1}
                isDisabled={kreditLoaders.sub2}
                value={get(values, "kreditSubkonto1Id", "")}
                label={"Субконто1"}
                required={true}
                defaultValue={{
                  label: get(values, "kreditSubkonto1", ""),
                  value: get(values, "kreditSubkonto1Id", ""),
                }}
                options={kirSubkonto1}
                className="mt-24"
                handleValueLabel={(e: any) => {
                  setFieldValue("kreditSubkonto1Id", e.value);
                  setFieldValue("kreditSubkonto1", e.label);
                  setKreditSubDetails({ ...kreditSubDetails, subkonto1: true });
                  if (!isEqual(e.value, values.kreditSubkonto1Id)) {
                    setKirSubconto2([]);
                    setKirSubconto3([]);
                    setFieldValue("kreditSubkonto2", null);
                    setFieldValue("kreditSubkonto2Id", null);
                    setFieldValue("kreditSubkonto3Id", null);
                  }
                }}
              />
            )}
          {checkIsAvailable({
            kschot: get(values, "kreditSchet", null),
            rekvizetName: REKVIZET_NAMES.KREDT_2,
          }) && (
              <FormSelect
                isSearchable
                value={get(values, "kreditSubkonto2Id", "")}
                label={"Субконто2"}
                isLoading={kreditLoaders.sub2}
                isDisabled={kreditLoaders.sub3}
                required={true}
                defaultValue={{
                  label: get(values, "kreditSubkonto2", ""),
                  value: get(values, "kreditSubkonto2Id", ""),
                }}
                options={kirSubkonto2}
                className="mt-24"
                handleValueLabel={(e: any) => {
                  setFieldValue("kreditSubkonto2Id", e.value);
                  setFieldValue("kreditSubkonto2", e.label);
                  setKreditSubDetails({ ...kreditSubDetails, subkonto2: true });
                  setKreditSubDetails({ ...kreditSubDetails, subkonto3: true });
                  if (!isEqual(e.value, values.kreditSubkonto2Id)) {
                    setKirSubconto3([]);
                    setFieldValue("kreditSubkonto3Id", null);
                  }
                }}
              />
            )}
          {checkIsAvailable({
            kschot: get(values, "kreditSchet", null),
            rekvizetName: REKVIZET_NAMES.KREDT_3,
          }) && (
              <FormSelect
                value={get(values, "kreditSubkonto3Id", "")}
                label={"Субконто3"}
                isLoading={kreditLoaders.sub3}
                required={true}
                defaultValue={{
                  label: get(values, "kreditSubkonto3", ""),
                  value: get(values, "kreditSubkonto3Id", ""),
                }}
                isSearchable
                options={kirSubkonto3}
                className="mt-24"
                handleValueLabel={(e: any) => {
                  setFieldValue("kreditSubkonto3Id", e.value);
                  setFieldValue("kreditSubkonto3", e.label);
                  setKreditSubDetails({ ...kreditSubDetails, subkonto3: true });
                }}
              />
            )}
          {checkIsAvailable({
            kschot: get(values, "kreditSchet", null),
            dschot: get(values, "debitSchet", null),
            rekvizetName: REKVIZET_NAMES.KOL,
          }) && (
              <Row align="center" className="mt-24">
                <Col xs={4} style={{ paddingRight: 0 }}>
                  <Title>
                    Количество
                    <span className="required_point">*</span>
                  </Title>
                </Col>
                <Col xs={8}>
                  <BaseMarkInput
                    id={"kol"}
                    value={get(values, "kol", "") || ""}
                    handleInput={(e: number) => {
                      setFieldValue("kol", e);
                    }}
                    min={1}
                    maxLength={40}
                  />
                </Col>
              </Row>
            )}
          <Row align="center" className="mt-24">
            <Col xs={4} style={{ paddingRight: 0 }}>
              <Title>
                Сумма
                <span className="required_point">*</span>
              </Title>
            </Col>
            <Col xs={8}>
              <BaseMarkInput
                id={"summa"}
                value={get(values, "summa", null) || ""}
                handleInput={(e: number) => {
                  setFieldValue("summa", e);
                }}
                min={1}
                maxLength={40}
              />
            </Col>
          </Row>
          <Input
            id={"kommentari"}
            label={"Коментарии"}
            value={get(values, "kommentari", "") || ""}
            handleInput={(e: string) => {
              setFieldValue("kommentari", e);
            }}
          />
          <Col xs={12} className="text-right mt-24 mb-16">
            <Button className="mr-8" green type="submit">
              {get(details, "debitSchet", null) ? PAGE.CHANGE : PAGE.ADD}
            </Button>

            <Button
              onClick={() => {
                handleCloseModal();
                resetForm();
              }}
              danger
            >
              Закрыть
            </Button>
          </Col>
        </Col>
      </Form>
    </>
  );
};
const AddManualPostingContainer = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { state } = useLocation();
  const filter = useAppSelector(manualPostingFilter);
  const manualDetails = useAppSelector(manualPostingDetails);
  const [disable, setDisable] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [detailId, setDetailsId] = useState<number | null>(null);
  const [LOADING, setLoading] = useState(true);
  const handleCloseModal = useCallback(() => {
    setOpenModal(false);
  }, []);

  const handleOpenModal = useCallback((id: number | null) => {
    setOpenModal(true);
    setDetailsId(id);
  }, []);
  const onSubmit = (values: ManualPosting) => {
    const detailsSubject = get(manualDetails, "data", null).map(
      (item: any) => ({
        ...item,
        debitSchet: get(item, "debitSchet.value", null),
        kreditSchet: get(item, "kreditSchet.value", ""),
      })
    );

    try {
      DocumentServices.AddManualPosting({
        transactionTime: new Date(),
        resultCode: null,
        resultMsg: null,
        data: {
          ...values,
          dataDoc: get(values, "dataDoc", ""),
          details: detailsSubject,
        },
      }).then((res) => {
        if (get(res, "status", "") == 200) {
          navigate(-1);
          dispatch(fetchAllManualPosting(filter));
          dispatch(fetchManualPostingStat());
          dispatch(clearManualPostingDetails());
          toast.success(MESSAGES.ADDED);
        }
        setSubmitting(false);
      });
    } catch (err) {
      console.log("err", err);
    }
  };
  const initialValues: any = {
    nomerDoc: "",
    dataDoc: '',
    kommentariya: "",
  };
  const { values, setFieldValue, handleSubmit, setSubmitting, isSubmitting } =
    useFormik({
      enableReinitialize: true,
      initialValues,
      onSubmit,
    });

  const handleSetInitialValues = (data: any) => {
    dispatch(subjectManualPosting(data.details));
    setFieldValue("nomerDoc", get(data, "nomerDoc", ""));
    setFieldValue("dataDoc", get(data, "dataDoc", ""));
    setFieldValue("kommentariya", get(data, "kommentariya", ""));
  };

  const getDocNumber = useCallback(
    (date: NumberDocType) => {
      try {
        if (isEmpty(get(state, "id", ""))) {
          DocumentServices.GetManualPostingNumber(date).then(({ data }) => {
            setFieldValue("nomerDoc", get(data, "data", ""));
            if (get(data, "transactionTime", "")) {
              const transactionTime = get(data, "transactionTime", "");
              const localMaxDate = moment.utc(transactionTime).add(5, 'hours').format("YYYY-MM-DD HH:mm");
              const localDate = moment.utc(transactionTime).add(5, 'hours').format("YYYY-MM-DD");
              setFieldValue("maxDate", localMaxDate);
              if (isEmpty(get(values, 'dataDoc', ''))) {
                setFieldValue("dataDoc", localDate);
              }
            }
          });
        }
      } catch (err) {
        console.log(err);
      }
    },
    [setFieldValue, state, values]
  );

  const handleCheckTemp = useCallback(() => {
    if (get(state, "id", false)) {
      DocumentServices.FetchManualPostingById(get(state, "id", "")).then(
        (res) => {
          if (get(res, "status", "") == 200) {
            let respond = get(res, "data.data", {});
            handleSetInitialValues(respond);
            setLoading(false);
          } else {
            setLoading(false);
          }
        }
      );
      setDisable(true);
    } else {
      setLoading(false);
      dispatch(fetchMenageAccount({ all: true }));
      getDocNumber({});
    }
  }, [dispatch, state]);

  useEffect(() => {
    handleCheckTemp();
  }, [handleCheckTemp]);
  // tset
  return (
    <Content className={"min_height"}>
      <ReactModal isOpen={openModal}>
        <AddContractSubjects
          handleCloseModal={handleCloseModal}
          tableDetails={get(manualDetails, "data", [])}
          id={detailId}
        />
      </ReactModal>
      {isEqual(LOADING, false) ? (
        <Form onSubmit={handleSubmit}>
          <Row>
            <Col xs={12}>
              <FlexBox style={{ width: "100%" }}>
                <Title bold sm>
                  Реквизиты
                </Title>
              </FlexBox>
            </Col>
            <Col xs={12} style={{ padding: 0 }} className={"my-16"}>
              <hr />
            </Col>
          </Row>
          <Row>
            <Col xs={6}>
              <Row align="center">
                <Col xs={4}>
                  <Title>
                    Номер<span className="required_point">*</span>
                  </Title>
                </Col>
                <Col xs={8} className="form-input-date_picker">
                  <Row>
                    <Col xs={6}>
                      <BaseInput
                        name={"nomerDoc"}
                        id={"nomerDoc"}
                        value={get(values, "nomerDoc", "")}
                      />
                    </Col>
                    <Col
                      xs={6}
                      style={{
                        paddingLeft: 0,
                      }}
                    >
                      <FlexBox>
                        <Text>от:</Text>
                        <DatePicker
                          id={"dataDoc"}
                          value={get(values, "dataDoc", "")}
                          handleDate={(e: any) => {
                            getDocNumber({
                              year: checkDateYear({
                                currentYear: get(values, "dataDoc", null),
                                checkYear: moment(e).format("YYYY-MM-DD"),
                              }),
                            });
                            setFieldValue(
                              "dataDoc",
                              moment(e).format("YYYY-MM-DD")
                            );
                          }}
                          // maxDate={new Date()}
                          maxDate={new Date(get(values, 'maxDate', new Date()))}
                          disabled={disable}
                          dateFormat="dd.MM.yyyy"
                        />
                      </FlexBox>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <TextArea
                id={"kommentariya"}
                label={"Коментарии"}
                disabled={disable}
                value={get(values, "kommentariya", "")}
                handleInput={(e: string) => {
                  setFieldValue("kommentariya", e);
                }}
              />
            </Col>
          </Row>

          <Row className="mt-24" justify={"between"}>
            <Col xs={12} className="align-center">
              <FlexBox justify={"space-between"} align={"center"}>
                <Title bold sm>
                  Табличная часть
                </Title>
                {isEmpty(get(state, "id", null)) && (
                  <ReactSVG
                    src={add_green}
                    className="svg_icon"
                    onClick={() => handleOpenModal(null)}
                  />
                )}
              </FlexBox>
            </Col>
            <Col xs={12} style={{ padding: 0 }} className={"my-16"}>
              <hr />
            </Col>
          </Row>

          <Row
            style={{
              position: "relative",
              minHeight: "35vh",
              minWidth: "100%",
            }}
            className="responsive_overflow"
          >
            <Col xs={12}>
              <Col>
                <BaseTable
                  tableHeader={[
                    "№",
                    "Дебет счет",
                    "Субконто1",
                    "Субконто2",
                    "Субконто3",
                    "Кредит счет",
                    "Субконто1",
                    "Субконто2",
                    "Субконто3",
                    "Количество",
                    "Сумма",
                    "Комментарии",
                    "",
                  ]}
                >
                  {!isEmpty(get(manualDetails, "data", null)) ? (
                    get(manualDetails, "data", []).map(
                      (item: any, index: number) => (
                        <tr key={index + 1}>
                          <td>{index + 1}</td>
                          <td>
                            {get(state, "id", false)
                              ? get(item, "debitSchet", "")
                              : get(item, "debitSchet.label", "")}
                          </td>
                          <td>{get(item, "debitSubkonto1", "")}</td>
                          <td>{get(item, "debitSubkonto2", 0)}</td>
                          <td>{get(item, "debitSubkonto3", 0)}</td>
                          <td>
                            {get(state, "id", false)
                              ? get(item, "kreditSchet", "")
                              : get(item, "kreditSchet.label", "")}
                          </td>
                          <td>{get(item, "kreditSubkonto1", 0)}</td>
                          <td>{get(item, "kreditSubkonto2", 0)}</td>
                          <td>{get(item, "kreditSubkonto3", 0)}</td>
                          <td>
                            {get(item, "kol", false) ? (
                              <NumericFormat
                                displayType="text"
                                value={get(item, "kol", 0)}
                                thousandSeparator={true}
                                decimalScale={2}
                              />
                            ) : (
                              ""
                            )}
                          </td>
                          <td>
                            <NumericFormat
                              displayType="text"
                              value={get(item, "summa", 0)}
                              thousandSeparator={true}
                              decimalScale={2}
                            />
                          </td>
                          <td>{get(item, "kommentari", "-")}</td>
                          <td>
                            <ActionBase
                              handleUpdate={handleOpenModal}
                              handleDelete={() =>
                                dispatch(
                                  changeDetailsManualPost({
                                    type: ACTION.DELETE,
                                    id: index,
                                  })
                                )
                              }
                              disable={disable}
                              id={index}
                            />
                          </td>
                        </tr>
                      )
                    )
                  ) : (
                    <tr>
                      <td colSpan={13}>Нет Данных</td>
                    </tr>
                  )}
                </BaseTable>
              </Col>
            </Col>
          </Row>
          <Row className={"mb-16 mt-32 text-end"}>
            <Col xs={12}>
              {/* <Button dark className={"mr-16"} type="submit">
                            Печать
                        </Button> */}
              <HasAccess>
                {({ userCan }: any) =>
                  userCan({
                    permission: `${DOCUMENT_KEY.RUCH_PROV}_CREATE`,
                    page: DOCUMENT_KEY.RUCH_PROV,
                    department: MAIN_DEPARTMENT.DOCUMENTS,
                    type: PROTECTED_TYPE.PERMISSIONS,
                  }) && (
                    <FormButton
                      disabled={disable}
                      loading={isSubmitting}
                      handleClick={handleSubmit}
                    >
                      {PAGE.DONE}
                    </FormButton>
                  )
                }
              </HasAccess>
              <Button danger handleClick={() => navigate(-1)}>
                {PAGE.CLOSE}
              </Button>
            </Col>
          </Row>
        </Form>
      ) : (
        <LoadingAddPages />
      )}
    </Content>
  );
};

export default AddManualPostingContainer;
