import { useAppDispatch, useAppSelector } from "app/hook";
import {
  clearFormDataMaterials,
  fetchNotFilledReceivedMaterials,
  fetchReceivedMaterials,
  fetchReceivedMaterialsById,
  fetchReceivedMaterialsByTempId,
  fetchReceivedMaterialsStat,
  movementReceivedMaterialSub,
} from "app/slices/documentSlices/receivedMaterialSlices/receivedMaterialSlices";
import { prixodDetails } from "app/slices/documentSlices/receivedMaterialSlices/type";
import { fetchPurchaseContracts } from "app/slices/documentSlices/registerPurchaseSlices/registerPurchaseSlices";
import {
  clearContractorsState,
  fetchAllCounterpart,
} from "app/slices/handbookSlices/counterpartSlices/counterpartSlices";
import { fetchRateVAT } from "app/slices/handbookSlices/rateVATSlices/rateVATSlices";
import {
  clearRowMaterialData,
  fetchRawMaterial,
} from "app/slices/handbookSlices/rawMaterialSlices/rawMaterialSlices";
import { fetchOwnSkladCM } from "app/slices/handbookSlices/wareHouseSlices/wareHouseSlices";
import {
  receivedMaterialsFilter,
  receivedMaterialsForm,
} from "app/states/documents";
import {
  contractorOptions,
  getPurchaseContractsSubjectData,
  loadingConterpart,
  loadingRegisterPruchase,
  unitsMeasurementOptions,
} from "app/states/documents/selectStates";
import {
  getDriversData,
  getMaterialData,
  getSkladCM,
  getTruckData,
  getVatRateData,
  loadingDrivers,
  loadingRawMaterial,
  loadingSklad,
  loadingTruck,
} from "app/states/handbooks/selectStates";
import add_green from "assets/images/icons/add_green.svg";
import {
  ActionBase,
  BaseInput,
  BaseSelect,
  BaseTable,
  Button,
  Content,
  DatePicker,
  FlexBox,
  Form,
  FormButton,
  FormInput,
  LoadingAddPages,
  ReactModal,
  Text,
  TextArea,
  Title,
} from "components";
import BaseMarkInput from "components/BaseMarkInput/BaseMarkInput";
import { useFormik } from "formik";
import { forEach, get, isEmpty, isEqual } from "lodash";
import { AddRowMaterialModal } from "modules/statics/spravochnik/containers/RawMaterialsContainer";
import moment from "moment";
import { useCallback, useEffect, useState, useMemo } from "react";
import { Col, Row } from "react-grid-system";
import { NumericFormat } from "react-number-format";
import { useLocation, useNavigate } from "react-router-dom";
import { ReactSVG } from "react-svg";
import { toast } from "react-toastify";
import HasAccess from "routers/HasAccess";
import { DOCUMENT_KEY, MAIN_DEPARTMENT } from "routers/constants";
import DocumentServices from "services/apiServices/documents";
import { ACTION, MESSAGES, PAGE, PROTECTED_TYPE } from "utilities/constants";
import {
  calcAmountWithVAT,
  calcTotalAmount,
  calcTotalPrice,
  removeValueOption,
  textFormatter,
} from "utilities/helpers";
import { MaterialDetailsSchema } from "utilities/schema/document/documentSchemas";
import { fetchDrivers } from "app/slices/handbookSlices/driversSlices/driversSlices";
import { fetchTrucks } from "app/slices/handbookSlices/truckSlices/truckSlices";

// Добавить Поступление сырья и материалов
const AddNewDocToTable = ({
  handleOpenModal,
  handleCloseModal,
  stavkaNDSId,
  documentId,
  dataTable,
  id,
  setIsOpenMaterial,
  showAdding,
}: {
  handleOpenModal?: () => void;
  handleCloseModal: () => void;
  stavkaNDSId?: number | string | null;
  documentId?: string | null;
  dataTable?: Array<any>;
  id?: number | null | undefined;
  setIsOpenMaterial: any;
  showAdding: boolean;
}) => {
  const dispatch = useAppDispatch();
  const loadingRow = useAppSelector(loadingRawMaterial);
  const [loadingProduct, setLoadingProduct] = useState<boolean>(false);
  const getMaterialOptions = useAppSelector(getMaterialData);
  const unitsMeasurementDropdowns = useAppSelector(unitsMeasurementOptions);
  const vatRate = useAppSelector(getVatRateData);
  const [materialOptions, setMaterialOptions] = useState<any>();
  const details = dataTable?.find((values) => values.materialId === id);
  const onSubmit = (values: any) => {
    const res = {
      ...values,
      materialId: get(values, "materialId.value", null),
      imyaMateriala: get(values, "materialId.label", ""),
      material: get(values, "materialId", {}),
      imyaEdIzm: get(values, "materialId.imyaEdIzm", ""),
      summa: get(values, "totalAmt", 0),
      vsego: get(values, "total", 0),
      summaNDS: get(values, "summaNDS", 0),
      nds: get(values, "nds", 0),
    };
    if (isEmpty(details)) {
      dispatch(movementReceivedMaterialSub({ data: res, type: ACTION.ADD }));
    } else {
      dispatch(
        movementReceivedMaterialSub({ data: res, type: ACTION.UPDATE, id })
      );
    }
    resetForm();
    handleCloseModal();
  };

  const { values, handleSubmit, setFieldValue, resetForm } = useFormik({
    initialValues: {
      edIzmId: get(details, "edIzmId", ""),
      kol: get(details, "kol", ""),
      sena: get(details, "sena", ""),
      materialId: {
        value: get(details, "materialId", ""),
        label: get(details, "imyaMateriala", ""),
        edIzmId: get(details, "edIzmId", ""),
        imyaEdIzm: get(details, "imyaEdIzm", ""),
      },
      totalAmt: get(details, "totalAmt", ""),
      nds: get(details, "nds", 0),
      total: get(details, "total", ""),
      summaNDS: get(details, "summaNDS", 0),
      maxKol: get(details, "maxKol", 0),
    },
    enableReinitialize: true,
    validationSchema: MaterialDetailsSchema,
    onSubmit,
  });
  // if available 'documentId' fetch option material select by documentId
  const fetchMaterialList = useCallback(() => {
    if (showAdding) {
      dispatch(fetchRawMaterial({ all: true, tip: "SM" }));
      setMaterialOptions([]);
    } else if (documentId && !showAdding) {
      setLoadingProduct(true);
      DocumentServices.FetchMaterialPurchaseById(documentId)
        .then((res) => {
          setLoadingProduct(false);
          const list = get(res, "data.data", []).map((item: prixodDetails) => ({
            value: item.materialId,
            label: item.imyaMateriala,
            edIzmId: item.edIzmId,
            imyaEdIzm: item.imyaEdIzm,
            nds: item.nds,
            kol: item.kol,
            sena: item.sena,
          }));
          setMaterialOptions(list);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      dispatch(fetchRawMaterial({ all: true, tip: "SM" }));
    }
  }, [documentId, showAdding]);

  const handleCalcTotalAmt = useCallback(() => {
    const totalAmt = calcTotalAmount({
      amount: get(values, "sena", ""),
      quantity: get(values, "kol", ""),
    });
    setFieldValue("totalAmt", totalAmt);
  }, [values.sena, values.kol]);

  //  fetch 'nds' sub by calculation
  const handleCalcAmtWithVAT = useCallback(() => {
    if (stavkaNDSId && values.totalAmt && isEmpty(documentId)) {
      const finedVAT: any = vatRate.find(
        (item) => get(item, "value", 0) == stavkaNDSId
      );
      const amountWithVAT = calcAmountWithVAT({
        amount: get(values, "totalAmt", ""),
        VAT: get(finedVAT, "percentage", 0),
        kol: get(values, "kol", 0),
        sena: get(values, "sena", 0),
      });
      setFieldValue("summaNDS", amountWithVAT);
    } else {
      const amountWithVAT = calcAmountWithVAT({
        amount: get(values, "totalAmt", ""),
        VAT: get(values, "nds", 0),
        kol: get(values, "kol", 0),
        sena: get(values, "sena", 0),
      });
      setFieldValue("summaNDS", amountWithVAT);
    }
  }, [values.kol, values.totalAmt, values.nds]);
  const handleClose = () => {
    handleCloseModal();
    resetForm();
  };
  const handleCalcTotal = useCallback(() => {
    if (isEmpty(documentId)) {
      const finedVAT: any = vatRate.find(
        (item) => get(item, "value", 0) == stavkaNDSId
      );
      const totalSum = calcTotalPrice({
        nds: get(finedVAT, "percentage", 0),
        totalAmount: get(values, "totalAmt", 0),
      });
      setFieldValue("total", totalSum);
    } else {
      const totalSum = calcTotalPrice({
        nds: get(values, "nds", 0),
        totalAmount: get(values, "totalAmt", 0),
      });
      setFieldValue("total", totalSum);
    }
  }, [values.nds, values.totalAmt]);

  useEffect(() => {
    fetchMaterialList();
    return () => {
      setMaterialOptions([]);
    };
  }, [documentId, showAdding]);

  useEffect(() => {
    handleCalcTotalAmt();
  }, [handleCalcTotalAmt]);

  useEffect(() => {
    handleCalcTotal();
  }, [handleCalcTotal]);

  useEffect(() => {
    handleCalcAmtWithVAT();
  }, [handleCalcAmtWithVAT]);

  return (
    <>
      <Col xs={12} className="mt-16 text-left">
        <Title sm bold>
          Ввод данных
        </Title>
      </Col>
      <Col xs={12} style={{ padding: 0 }} className={"my-16"}>
        <hr />
      </Col>
      <Form onSubmit={handleSubmit} align={"center"}>
        <Col xs={12} className={"px-24"}>
          <Row align="center" className="mt-24">
            <Col xs={4}>
              <Title>
                Сырья и материалов<span className="required_point">*</span>
              </Title>
            </Col>
            <Col xs={8}>
              <HasAccess>
                {({ userCan }: any) =>
                  userCan({
                    permission: "SM_CREATE",
                    page: "SM",
                    department: "SPRAVOCHNIKI",
                    type: PROTECTED_TYPE.PERMISSIONS,
                  }) ? (
                    <BaseSelect
                      id={"materialId"}
                      isSearchable
                      isLoading={loadingRow || loadingProduct}
                      value={get(values, "materialId.value", "")}
                      defaultValue={get(values, "materialId", {})}
                      options={
                        isEmpty(materialOptions) && showAdding
                          ? removeValueOption({
                            currentOption: getMaterialOptions,
                            removeValueList: dataTable,
                            valueName: "materialId",
                            isUpdate: get(details, "material", null),
                          })
                          : removeValueOption({
                            currentOption: materialOptions,
                            removeValueList: dataTable,
                            valueName: "materialId",
                            isUpdate: get(details, "material", null),
                          })
                      }
                      handleValueLabel={(e: any) => {
                        setFieldValue("materialId", e);
                        setFieldValue("imyaEdIzm", get(e, "imyaEdIzm", ""));
                        setFieldValue("edIzmId", get(e, "edIzmId", ""));
                        setFieldValue("nds", get(e, "nds", 0));
                        setFieldValue("sena", get(e, "sena", ""));
                        setFieldValue(
                          "maxKol",
                          Number(get(e, "kol", 0)).toFixed(2)
                        );
                      }}
                      isCustomMenu={showAdding}
                      menuButtonTitle={"+ Добавить"}
                      handleAddNewOption={() => setIsOpenMaterial(true)}
                    />
                  ) : (
                    <BaseSelect
                      id={"materialId"}
                      isSearchable
                      isLoading={loadingRow || loadingProduct}
                      value={get(values, "materialId.value", "")}
                      defaultValue={get(values, "materialId", {})}
                      options={
                        isEmpty(materialOptions) && showAdding
                          ? removeValueOption({
                            currentOption: getMaterialOptions,
                            removeValueList: dataTable,
                            valueName: "materialId",
                            isUpdate: get(details, "material", null),
                          })
                          : removeValueOption({
                            currentOption: materialOptions,
                            removeValueList: dataTable,
                            valueName: "materialId",
                            isUpdate: get(details, "material", null),
                          })
                      }
                      handleValueLabel={(e: any) => {
                        setFieldValue("materialId", e);
                        setFieldValue("imyaEdIzm", get(e, "imyaEdIzm", ""));
                        setFieldValue("edIzmId", get(e, "edIzmId", ""));
                        setFieldValue("nds", get(e, "nds", 0));
                        setFieldValue("sena", get(e, "sena", ""));
                        setFieldValue(
                          "maxKol",
                          Number(get(e, "kol", 0)).toFixed(2)
                        );
                      }}
                      isCustomMenu={false}
                      menuButtonTitle={"+ Добавить"}
                      handleAddNewOption={() => setIsOpenMaterial(true)}
                    />
                  )
                }
              </HasAccess>
            </Col>
          </Row>
          <Row align="center" className="mt-24">
            <Col xs={4} style={{ paddingRight: 0 }}>
              <Title>
                Количество <span className="required_point">*</span>
              </Title>
            </Col>
            <Col xs={8} className="form-input-date_picker">
              <Row>
                <Col xs={7}>
                  {!isEmpty(documentId) ? (
                    <BaseMarkInput
                      id={"kol"}
                      value={get(values, "kol", "")}
                      placeholder={`до: ${textFormatter(
                        get(values, "maxKol", 0)
                      )}`}
                      handleInput={(e: number) => {
                        setFieldValue("kol", e);
                      }}
                      max={get(values, "maxKol", 0)}
                    />
                  ) : (
                    <BaseMarkInput
                      id={"kol"}
                      value={get(values, "kol", "")}
                      handleInput={(e: number) => {
                        setFieldValue("kol", e);
                      }}
                    />
                  )}
                  {!isEmpty(documentId) &&
                    Number(get(values, "kol", 0)) >
                    Number(get(values, "maxKol", 0)) && (
                      <Text xs danger>
                        ограничение количество до:
                        {textFormatter(get(values, "maxKol", 0))}
                      </Text>
                    )}
                </Col>
                <Col xs={5} style={{ paddingLeft: 0 }}>
                  <FlexBox>
                    <Text xs>Ед.изм.:</Text>
                    <BaseSelect
                      id={"edIzmId"}
                      isSearchable
                      value={get(values, "edIzmId", "")}
                      options={unitsMeasurementDropdowns}
                      isDisabled={true}
                    />
                  </FlexBox>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row align="center" className="mt-24">
            <Col xs={4} style={{ paddingRight: 0 }}>
              <Title>
                Цена <span className="required_point">*</span>
              </Title>
            </Col>
            <Col xs={8}>
              <BaseMarkInput
                value={get(values, "sena", "")}
                handleInput={(e: number) => {
                  setFieldValue("sena", e);
                }}
                maxLength={40}
                disabled={!isEmpty(documentId)}
              />
            </Col>
          </Row>
          <FormInput
            label={"Сумма"}
            value={get(values, "totalAmt", "")}
            thousandSeparator={true}
            fixedDecimalScale={true}
            disabled={true}
            required={true}
          />
          <FormInput
            disabled={true}
            value={get(values, "summaNDS", "")}
            label={"Сумма НДС"}
            thousandSeparator={true}
            fixedDecimalScale={true}
            required={true}
          />
          <FormInput
            label={"Всего"}
            value={get(values, "total", "")}
            thousandSeparator={true}
            fixedDecimalScale={true}
            disabled={true}
            required={true}
          />

          <Col xs={12} className="text-right mt-24 mb-16">
            {!isEmpty(documentId) ? (
              <Button
                className="mr-8"
                handleClick={handleSubmit}
                green
                inability={
                  Number(get(values, "maxKol", 0)) <
                    Number(get(values, "kol", 0))
                    ? true
                    : false
                }
              >
                {get(details, "edIzmId", null) ? PAGE.CHANGE : PAGE.ADD}
              </Button>
            ) : (
              <Button className="mr-8" handleClick={handleSubmit} green>
                {get(details, "edIzmId", null) ? PAGE.CHANGE : PAGE.ADD}
              </Button>
            )}
            <Button onClick={handleClose} danger>
              Закрыть
            </Button>
          </Col>
        </Col>
      </Form>
    </>
  );
};

const AddReceivedRowMatContainer = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { state } = useLocation();
  const [openModal, setOpenModal] = useState(false);
  const getWareHouse = useAppSelector(getSkladCM);
  const contractorDropdowns = useAppSelector(contractorOptions);
  const getPurchaseContracts = useAppSelector(getPurchaseContractsSubjectData);
  const drivers = useAppSelector(getDriversData);
  const truck = useAppSelector(getTruckData);
  const filter = useAppSelector(receivedMaterialsFilter);
  const receivedSubjects = useAppSelector(receivedMaterialsForm);
  const [disable, setDisable] = useState(false);
  const [materialId, setMaterial] = useState<number | null | undefined>();
  const [isOpenMaterial, setIsOpenMaterial] = useState(false);
  // loadings
  let LOADING = get(receivedSubjects, "loading", false);
  let loadingSkladData = useAppSelector(loadingSklad);
  let loadingKontragent = useAppSelector(loadingConterpart);
  let loadingPurchaseData = useAppSelector(loadingRegisterPruchase);
  let loadingDriverData = useAppSelector(loadingDrivers);
  let loadingTruckData = useAppSelector(loadingTruck);
  const handleCloseModal = useCallback(() => {
    setOpenModal(false);
  }, []);

  const handleOpenModal = useCallback((id: number | null | undefined) => {
    setOpenModal(true);
    setMaterial(id);
  }, []);

  const onSubmit = (values: any) => {
    const cntrcSubjects = get(receivedSubjects, "data.prixodSMDetails", []).map(
      (item: prixodDetails) => ({
        materialId: get(item, "materialId", ""),
        imyaMateriala: get(item, "imyaMateriala"),
        edIzmId: get(item, "edIzmId", ""),
        kol: get(item, "kol", ""),
        sena: get(item, "sena", ""),
        nds: get(item, "nds", 0),
        summaNDS: get(item, "summaNDS", 0),
      })
    );
    let valuesToSbm: any = {};

    forEach(values, (value: any, key: any) => {
      valuesToSbm[key] = ["dataDoc", "nomerDoc", "kommentariya"].includes(key)
        ? value
        : get(value, "value", "");
    });

    try {
      DocumentServices.AddReceivedMaterials({
        transactionTime: new Date(),
        resultCode: null,
        resultMsg: null,
        data: {
          ...valuesToSbm,
          dataDoc: !isEqual(get(values, "dataDoc", null), null)
            ? get(values, "dataDoc", "")
            : moment(new Date()).format("YYYY-MM-DD"),
          prixodSMDetails: cntrcSubjects,
        },
      }).then((res) => {
        if (get(res, "status", "") == 200) {
          navigate(-1);
          dispatch(fetchReceivedMaterials(filter));
          dispatch(fetchReceivedMaterialsStat());
          toast.success(MESSAGES.ADDED);
          if (get(state, "id", false)) {
            DocumentServices.RemoveTempsReceivedMaterials(
              get(state, "id", "")
            ).then((res) => {
              // error
              if (get(res, "status", "") == 200) {
                navigate(-1);
                dispatch(fetchReceivedMaterialsStat());
              }
            });
          }
        }
        setSubmitting(false);
      });
    } catch (err) {
      console.log("err", err);
    }
  };

  const { values, setFieldValue, handleSubmit, setSubmitting, isSubmitting } =
    useFormik({
      enableReinitialize: true,
      initialValues: {
        ...get(receivedSubjects, "data.formIntVls", {}),
        dataDoc: get(state, 'id', false) ? get(receivedSubjects, "data.formIntVls.dataDoc", '') : ''
      },
      onSubmit,
    });
  const saveNotFullFilledForm = useCallback(() => {
    const cntrcSubjects = get(receivedSubjects, "data.prixodSMDetails", []).map(
      (item: prixodDetails) => ({
        materialId: get(item, "materialId", ""),
        imyaMateriala: get(item, "imyaMateriala", ""),
        imyaEdIzm: get(item, "imyaEdIzm", ""),
        edIzmId: get(item, "edIzmId", ""),
        kol: get(item, "kol", ""),
        sena: get(item, "sena", ""),
        summaNDS: get(item, "summaNDS", 0),
        total: get(item, "total", 0),
        totalAmt: get(item, "totalAmt", 0),
      })
    );
    let valuesToSbm: any = {};

    forEach(values, (value: any, key: any) => {
      valuesToSbm[key] = ["dataDoc", "nomerDoc"].includes(key)
        ? value
        : get(value, "value", "");
    });
    try {
      DocumentServices.AddNotFilledReceivedMaterials({
        transactionTime: new Date(),
        resultCode: null,
        resultMsg: null,
        data: {
          ...valuesToSbm,
          dataDoc: !isEqual(get(values, "dataDoc", null), null)
            ? get(values, "dataDoc", "")
            : moment(new Date()).format("YYYY-MM-DD"),
          id: get(state, "id", null),
          imyaPodotchet: get(values, "podotchet.label", "Официально"),
          imyaKontragent: get(values, "kontragentId.label", ""),
          imyaSklada: get(values, "skladId.label", ""),
          imyaVoditeli: get(values, "voditeliId.label", ""),
          gosNomer: get(values, "avtoId.label", ""),
          nomerDogPokupka: get(values, "dogPokupkaId.label", ""),
          prixodSMDetails: cntrcSubjects,
        },
      }).then((res) => {
        if (get(res, "status", "") == 200) {
          navigate(-1);
          dispatch(fetchNotFilledReceivedMaterials());
          toast.success(MESSAGES.ADDED);
        }
      });
    } catch (err) {
      console.log("err", err);
    }
  }, [dispatch, navigate, values, receivedSubjects]);

  const getSearchByContract = useCallback(() => {
    if (get(values, "kontragentId.value", null) && !get(state, "id", null)) {
      dispatch(
        fetchPurchaseContracts({
          all: true,
          kontragentId: get(values, "kontragentId.value", null),
          filterPage: "PRIXOD_SM",
        })
      );
    }
  }, [values.kontragentId]);

  const fetchAllRawMaterialData = useCallback(() => {
    dispatch(fetchRawMaterial({ ...filter, all: true, InAscOrder: false }))
  }, [dispatch]);

  // const getDocNumber = useCallback(() => {
  // DocumentServices.GetReceivedMaterialsNumber().then(({ data }) => {
  //   setFieldValue("nomerDoc", get(data, "data", ""));
  // });
  // }, []);

  const fetchParams = useCallback(() => {
    if (isEmpty(get(state, "id", null))) {
      if (isEqual(get(values, "podotchet.value", ""), 0)) {
        dispatch(
          fetchAllCounterpart({
            all: true,
            filterPage: "PRIXOD_SM",
          })
        );
      } else {
        dispatch(
          fetchAllCounterpart({
            all: true,
            type: 1,
            filterPage: "PRIXOD_SM",
          })
        );
      }
    }
  }, [dispatch, values.podotchet]);
  //
  const getGosNumber = useCallback(() => {
    if (get(values, "avtoId.value", null)) {
      const find = truck.find(
        (item: any) => item.value == get(values, "avtoId.value", 0)
      );
      setFieldValue("avtoId.label", get(find, "label", ""));
    } else {
      setFieldValue("avtoId.label", "");
      setFieldValue("avtoId", null);
    }
  }, [values.voditeliId]);

  const handleClickTemp = useCallback(() => {
    if (get(state, "id", false)) {
      if (get(state, "isTemp", false)) {
        dispatch(fetchReceivedMaterialsByTempId(get(state, "id", "")))
      } else {
        dispatch(fetchReceivedMaterialsById(get(state, "id", "")));
        setDisable(true);
      }
    } else {
      dispatch(fetchRateVAT({ all: true }));
      dispatch(fetchOwnSkladCM({}))
        .then((res: any) => {
          if (get(res, "payload.transactionTime", "")) {
            const transactionTime = get(res, "payload.transactionTime", "");
            const localMaxDate = moment.utc(transactionTime).add(5, 'hours').format("YYYY-MM-DD HH:mm");
            const localDate = moment.utc(transactionTime).add(5, 'hours').format("YYYY-MM-DD");
            setFieldValue("maxDate", localMaxDate);
            if (isEmpty(get(values, 'dataDoc', ''))) {
              setFieldValue("dataDoc", localDate);
            }
          }
        });
      dispatch(fetchDrivers({ all: true }));
      dispatch(fetchTrucks({ all: true }));
      //  now not cengerate number
      // getDocNumber();
    }
  }, [dispatch, state, values]);

  useEffect(() => {
    getGosNumber();
  }, [getGosNumber, values.voditeliId]);
  useEffect(() => {
    fetchParams();
  }, [fetchParams]);
  useEffect(() => {
    getSearchByContract();
  }, [getSearchByContract]);
  useEffect(() => {
    handleClickTemp();
    return () => {
      dispatch(clearFormDataMaterials());
    };
  }, []);
  return (
    <Content className={"min_height"} style={{ position: "relative" }}>
      {/* modal */}
      <ReactModal isOpen={openModal}>
        <AddNewDocToTable
          handleCloseModal={handleCloseModal}
          stavkaNDSId={get(values, "stavkaNDSId.value", 0)}
          documentId={get(values, "dogPokupkaId.value", "")}
          dataTable={get(receivedSubjects, "data.prixodSMDetails", [])}
          id={materialId}
          showAdding={!isEqual(get(values, "podotchet.value", 0), 0)}
          setIsOpenMaterial={setIsOpenMaterial}
        />
      </ReactModal>
      {/* material */}
      <ReactModal isOpen={isOpenMaterial}>
        <AddRowMaterialModal
          handleCloseModal={() => setIsOpenMaterial(false)}
          afterSubmitAction={() => fetchAllRawMaterialData()}
        />
      </ReactModal>
      {isEqual(LOADING, false) ? (
        <Form onSubmit={handleSubmit}>
          <Row>
            <Col xs={12}>
              <FlexBox justify={"space-between"} style={{ width: "100%" }}>
                <Title bold sm>
                  Реквизиты
                </Title>
                <Button
                  green
                  handleClick={saveNotFullFilledForm}
                  disabled={disable}
                >
                  Сохранить
                </Button>
              </FlexBox>
            </Col>
            <Col xs={12} style={{ padding: 0 }} className={"my-16"}>
              <hr />
            </Col>
          </Row>
          <Row>
            <Col xs={6}>
              <Row align="center">
                <Col xs={4}>
                  <Title>
                    Номер<span className="required_point">*</span>
                  </Title>
                </Col>
                <Col xs={8} className="form-input-date_picker">
                  <Row>
                    <Col xs={6}>
                      <BaseInput
                        id={"nomerDoc"}
                        value={get(values, "nomerDoc", "")}
                        handleInput={(e: string) => {
                          setFieldValue("nomerDoc", e);
                        }}
                        disabled={disable}
                      />
                    </Col>
                    <Col xs={6} style={{ paddingLeft: 0 }}>
                      <FlexBox>
                        <Text>от:</Text>
                        <DatePicker
                          id={"dataDoc"}
                          value={get(values, "dataDoc", null)}
                          handleDate={(e: any) => {
                            setFieldValue(
                              "dataDoc",
                              moment(e).format("YYYY-MM-DD")
                            );
                          }}
                          dateFormat="dd.MM.yyyy"
                          disabled={disable}
                          // maxDate={new Date()}
                          maxDate={new Date(get(values, 'maxDate', new Date()))}
                        />
                      </FlexBox>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col xs={6}>
              <Row align="center" className="mt-24">
                <Col xs={4}>
                  <Title>
                    Поступление <span className="required_point">*</span>
                  </Title>
                </Col>
                <Col xs={8}>
                  <BaseSelect
                    id={"podotchet"}
                    isSearchable
                    value={get(values, "podotchet.value", "")}
                    options={[
                      { label: "Oт поставщика", value: 0 },
                      { label: "Через подотчетного лица", value: 1 },
                    ]}
                    handleValueLabel={(e: any) => {
                      if (e !== values.podotchet) {
                        setFieldValue("podotchet", e);
                        setFieldValue("kontragentId.label", null);
                        setFieldValue("kontragentId.value", null);
                        setFieldValue("dogPokupkaId.label", null);
                        setFieldValue("dogPokupkaId.value", null);
                        dispatch(clearFormDataMaterials());
                      } else {
                        setFieldValue("podotchet", e);
                      }
                    }}
                    isDisabled={disable || loadingPurchaseData}
                  />
                </Col>
              </Row>
              <Row align="center" className="mt-24">
                <Col xs={4}>
                  <Title>
                    Склад сырья и материалов
                    <span className="required_point">*</span>
                  </Title>
                </Col>
                <Col xs={8}>
                  <BaseSelect
                    id={"skladId"}
                    isSearchable
                    isLoading={loadingSkladData}
                    value={get(values, "skladId.value", "")}
                    options={getWareHouse}
                    handleValueLabel={(e: any) => {
                      setFieldValue("skladId", e);
                    }}
                    isDisabled={disable || loadingPurchaseData}
                  />
                </Col>
              </Row>
              <Row align="center" className="mt-24">
                <Col xs={4}>
                  <Title>
                    Контрагент<span className="required_point">*</span>
                  </Title>
                </Col>
                <Col xs={8}>
                  <BaseSelect
                    id={"kontragentId"}
                    isSearchable
                    isLoading={loadingKontragent}
                    value={get(values, "kontragentId.value", "")}
                    defaultValue={get(values, "kontragentId", {})}
                    options={contractorDropdowns}
                    handleValueLabel={(e: any) => {
                      if (
                        e !== values.kontragentId &&
                        isEqual(get(values, "podotchet.value", ""), 0)
                      ) {
                        setFieldValue("kontragentId", e);
                        setFieldValue("dogPokupkaId", null);
                        dispatch(
                          movementReceivedMaterialSub({
                            data: null,
                            type: ACTION.ADD,
                          })
                        );
                      } else {
                        setFieldValue("kontragentId", e);
                      }
                    }}
                    isDisabled={disable || loadingPurchaseData}
                  />
                </Col>
              </Row>
              {isEqual(get(values, "podotchet.value", 0), 0) && (
                <Row align="center" className="mt-24">
                  <Col xs={4} style={{ paddingRight: 0 }}>
                    <Title>
                      Договор<span className="required_point">*</span>
                    </Title>
                  </Col>
                  <Col xs={8}>
                    <BaseSelect
                      id={"dogPokupkaId"}
                      isSearchable
                      isLoading={loadingPurchaseData}
                      value={get(values, "dogPokupkaId.value", "")}
                      defaultValue={get(values, "dogPokupkaId", {})}
                      options={
                        values.kontragentId.value ? getPurchaseContracts : []
                      }
                      handleValueLabel={(e: any) => {
                        if (e !== values.dogPokupkaId) {
                          setFieldValue("dogPokupkaId", e);
                          dispatch(clearRowMaterialData());
                          dispatch(
                            movementReceivedMaterialSub({
                              data: null,
                              type: ACTION.ADD,
                            })
                          );
                        } else {
                          setFieldValue("dogPokupkaId", e);
                        }
                      }}
                      isDisabled={disable}
                    />
                  </Col>
                </Row>
              )}
              <TextArea
                id={"kommentariya"}
                label={"Коментарии"}
                value={get(values, "kommentariya", "")}
                disabled={disable}
                handleInput={(e: string) => {
                  setFieldValue("kommentariya", e);
                }}
              />
            </Col>
            <Col xs={6}>
              <Row align="center" className="mt-32">
                <Col xs={4}>
                  <Title>
                    Водитель<span className="required_point">*</span>
                  </Title>
                </Col>
                <Col xs={8}>
                  <BaseSelect
                    id={"voditeliId"}
                    isSearchable
                    isClearable={
                      !isEmpty(get(values, "voditeliId.label", null))
                    }
                    defaultValue={get(values, "voditeliId", "")}
                    value={get(values, "voditeliId.value", "")}
                    isLoading={loadingDriverData}
                    options={drivers}
                    handleValueLabel={(e: any) => {
                      setFieldValue("voditeliId", e);
                      setFieldValue("avtoId.value", get(e, "avto", null));
                    }}
                    isDisabled={disable}
                  />
                </Col>
              </Row>
              <Row align="center" className="mt-24">
                <Col xs={4}>
                  <Title>
                    Автомобиль <span className="required_point">*</span>
                  </Title>
                </Col>
                <Col xs={8}>
                  <BaseSelect
                    id={"avtoId"}
                    isSearchable
                    isLoading={loadingTruckData}
                    value={get(values, "avtoId.value", "")}
                    defaultValue={get(values, "avtoId", "")}
                    options={truck}
                    handleValueLabel={(e: any) => {
                      setFieldValue("avtoId", e);
                    }}
                    isDisabled={disable}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className="mt-24" justify={"between"}>
            <Col xs={12} className="align-center">
              <FlexBox justify={"space-between"} align={"center"}>
                <Title bold sm>
                  Табличная часть<span className="required_point">*</span>
                </Title>
                {!disable && (
                  <>
                    {isEqual(get(values, "podotchet.value", 0), 1) ||
                      (!isEmpty(get(values, "dogPokupkaId.value", "")) &&
                        isEmpty(get(state, "id", null))) ? (
                      <ReactSVG
                        src={add_green}
                        className="svg_icon"
                        onClick={() => handleOpenModal(null)}
                      />
                    ) : (
                      ""
                    )}
                  </>
                )}
              </FlexBox>
            </Col>
            <Col xs={12} style={{ padding: 0 }} className={"my-16"}>
              <hr />
            </Col>
          </Row>

          <Row>
            <Col xs={12}>
              <Col>
                <BaseTable
                  tableHeader={[
                    "№",
                    "Сырьё и материал",
                    "Ед. изм",
                    "Количество",
                    "Цена",
                    "Сумма",
                    "Сумма НДС",
                    "Всего",
                    "",
                  ]}
                >
                  {!isEmpty(
                    get(receivedSubjects, "data.prixodSMDetails", [])
                  ) ? (
                    get(receivedSubjects, "data.prixodSMDetails", []).map(
                      (item: prixodDetails, index: number) => (
                        <tr key={index + 1}>
                          <td>{index + 1}</td>
                          <td>{get(item, "imyaMateriala", "-")}</td>
                          <td>{get(item, "imyaEdIzm", "-")}</td>
                          <td>
                            <NumericFormat
                              displayType="text"
                              value={get(item, "kol", "")}
                              thousandSeparator
                              decimalScale={2}
                            />
                          </td>
                          <td>
                            <NumericFormat
                              displayType="text"
                              value={get(item, "sena", "")}
                              thousandSeparator
                              decimalScale={2}
                            />
                          </td>
                          <td>
                            <NumericFormat
                              displayType="text"
                              value={get(item, "summa", "")}
                              thousandSeparator
                              decimalScale={2}
                            />
                          </td>
                          <td>
                            <NumericFormat
                              displayType="text"
                              value={get(item, "summaNDS", "")}
                              thousandSeparator
                              decimalScale={2}
                            />
                          </td>
                          <td>
                            <NumericFormat
                              displayType="text"
                              value={get(item, "vsego", "")}
                              thousandSeparator
                              decimalScale={2}
                            />
                          </td>
                          <td>
                            {!disable && (
                              <ActionBase
                                handleUpdate={handleOpenModal}
                                handleDelete={() =>
                                  dispatch(
                                    movementReceivedMaterialSub({
                                      type: ACTION.DELETE,
                                      id: get(item, "materialId", null),
                                    })
                                  )
                                }
                                disable={disable}
                                id={get(item, "materialId", null)}
                              />
                            )}
                          </td>
                        </tr>
                      )
                    )
                  ) : (
                    <tr>
                      <td colSpan={9}> Нет Реквизиты</td>
                    </tr>
                  )}
                </BaseTable>
              </Col>
            </Col>
          </Row>
          <Row className={"mb-16 mt-32 text-end"}>
            <Col xs={12}>
              {/* <Button dark className={"mr-16"} type="submit">
                            Печать
                        </Button> */}
              <HasAccess>
                {({ userCan }: any) =>
                  userCan({
                    permission: `${DOCUMENT_KEY.PRIXOD_SM}_CREATE`,
                    page: DOCUMENT_KEY.PRIXOD_SM,
                    department: MAIN_DEPARTMENT.DOCUMENTS,
                    type: PROTECTED_TYPE.PERMISSIONS,
                  }) && (
                    <FormButton
                      disabled={disable}
                      loading={isSubmitting}
                      handleClick={handleSubmit}
                    >
                      {PAGE.DONE}
                    </FormButton>
                  )
                }
              </HasAccess>
              <Button danger handleClick={() => navigate(-1)}>
                {PAGE.CLOSE}
              </Button>
            </Col>
          </Row>
        </Form>
      ) : (
        <LoadingAddPages />
      )}
    </Content>
  );
};

export default AddReceivedRowMatContainer;
