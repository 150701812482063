import { useAppDispatch, useAppSelector } from "app/hook";
import { prixodDetails } from "app/slices/documentSlices/receivedMaterialSlices/type";
import { clearPurchaseProductsState } from "app/slices/documentSlices/registerPurchaseSlices/registerPurchaseSlices";
import {
  addSubjectReturnProduct,
  clearFormDataReturnProduct,
  fetchNotFilledReturnProduct,
  fetchReturnProduct,
  fetchReturnProductById,
  fetchReturnProductByTempId,
  fetchReturnProductStat,
  movementReturnMaterialRealSubject,
} from "app/slices/documentSlices/returnProductsSlices/returnProductSlices";
import {
  clearSalesProduct,
  fetchSalesProduct,
} from "app/slices/documentSlices/salesProductSlices/salesProductSlices";
import { fetchAllCounterpart } from "app/slices/handbookSlices/counterpartSlices/counterpartSlices";
import { returnProductFilter, returnProductForm } from "app/states/documents";
import {
  getSaleProductDataOptions,
  loadingConterpart,
  loadingSalesProductData,
} from "app/states/documents/selectStates";
import { getCounterpartData } from "app/states/handbooks/selectStates";
import add_green from "assets/images/icons/add_green.svg";
import axios from "axios";
import {
  ActionBase,
  BaseInput,
  BaseSelect,
  BaseTable,
  Button,
  Content,
  DatePicker,
  FlexBox,
  Form,
  FormButton,
  FormSelect,
  LoadingAddPages,
  ReactModal,
  Text,
  TextArea,
  Title,
} from "components";
import BaseMarkInput from "components/BaseMarkInput/BaseMarkInput";
import { useFormik } from "formik";
import { forEach, get, isEmpty, isEqual } from "lodash";
import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import { Col, Row } from "react-grid-system";
import { NumericFormat } from "react-number-format";
import { useLocation, useNavigate } from "react-router-dom";
import { ReactSVG } from "react-svg";
import { toast } from "react-toastify";
import HasAccess from "routers/HasAccess";
import { DOCUMENT_KEY, MAIN_DEPARTMENT } from "routers/constants";
import DocumentServices from "services/apiServices/documents";
import { NumberDocType, ProductDetailsTable } from "types/documentsType";
import { ACTION, MESSAGES, PAGE, PROTECTED_TYPE } from "utilities/constants";
import {
  calcAmountWithVAT,
  calcTotalAmount,
  checkDateYear,
  removeValueOption,
  textFormatter,
} from "utilities/helpers";
import { ProductDetailsSchema } from "utilities/schema/document/documentSchemas";
const cancel = axios.CancelToken.source();
//  Добавить Перемещение сырья и материалов
const AddNewDocToTable = ({
  handleOpenModal,
  handleCloseModal,
  documentId,
  tableData,
  id,
}: {
  handleOpenModal?: () => void;
  handleCloseModal: () => void;
  documentId: string | null;
  tableData: Array<ProductDetailsTable>;
  id: number | null | undefined;
}) => {
  const dispatch = useAppDispatch();
  const [productOption, setProductOptions] = useState<Array<any>>([]);
  const details = tableData?.find((value) => value.tovarId === id);
  const [loadingDetails, setLoadingDetails] = useState<boolean>(false);
  const onSubmit = (values: any) => {
    const res = {
      ...values,
      tovar: get(values, "tovarId", null),
      tovarId: get(values, "tovarId.value", null),
      imyaTovar: get(values, "tovarId.label", ""),
    };

    if (isEmpty(details)) {
      dispatch(addSubjectReturnProduct(res));
    } else {
      dispatch(
        movementReturnMaterialRealSubject({
          data: res,
          type: ACTION.UPDATE,
          id,
        })
      );
    }
    resetForm();
    handleCloseModal();
  };

  const { values, handleSubmit, setFieldValue, resetForm } = useFormik({
    enableReinitialize: true,
    initialValues: {
      imyaEdIzm: get(details, "imyaEdIzm", null),
      tovarId: {
        value: get(details, "tovarId", null),
        label: get(details, "imyaTovar", null),
      },
      edIzmId: get(details, "edIzmId", null),
      kol: get(details, "kol", null),
      maxKol: get(details, "maxKol", 0),
      tsena: get(details, "tsena", 0),
      nds: get(details, "nds", 0),
      summaNDS: get(details, "summaNDS", 0),
      summa: get(details, "summa", 0),
      vsego: get(details, "vsego", 0),
    },
    validationSchema: ProductDetailsSchema,
    onSubmit,
  });

  const fetchProductPrice = useCallback(() => {
    try {
      setLoadingDetails(true);
      DocumentServices.FetchReturnProductDetails({ id: documentId }).then(
        (res) => {
          setLoadingDetails(false);
          if (res.status === 200) {
            const listProduct = get(res, "data.data", []).map((item: any) => ({
              label: item.imyaTovara,
              value: item.tovarId,
              imyaEdIzm: item.imyaEdIzm,
              edIzmId: item.edIzmId,
              kol: item.kol,
              sena: item.sena,
              nds: item.nds,
            }));
            setProductOptions(listProduct);
          }
        }
      );
    } catch (error) {
      console.log(error);
    }
  }, [documentId, values.tovarId]);

  const handleCalcsumma = useCallback(() => {
    const summa = calcTotalAmount({
      amount: get(values, "tsena", ""),
      quantity: get(values, "kol", ""),
    });
    setFieldValue("summa", summa);
  }, [values.tsena, values.kol]);

  const handleCalcAmtWithVAT = useCallback(() => {
    if (values.summa) {
      const amountWithVAT = calcAmountWithVAT({
        amount: get(values, "summa", ""),
        VAT: get(values, "nds", 0),
        kol: get(values, "kol", null),
        sena: get(values, "tsena", ""),
      });
      setFieldValue("summaNDS", amountWithVAT);
      setFieldValue(
        "vsego",
        Number(amountWithVAT) + Number(get(values, "summa", 0))
      );
    }
  }, [values.kol, values.summa, values.nds, setFieldValue]);
  useEffect(() => {
    handleCalcsumma();
  }, [handleCalcsumma]);
  useEffect(() => {
    handleCalcAmtWithVAT();
  }, [values.kol, values.summa]);
  useEffect(() => {
    fetchProductPrice();
    return () => {
      setProductOptions([]);
    };
  }, [documentId]);

  return (
    <>
      <Form onSubmit={handleSubmit}>
        <Col xs={12} className="mt-16 text-left">
          <Title sm bold>
            Ввод данных
          </Title>
        </Col>
        <Col xs={12} style={{ padding: 0 }} className={"my-16"}>
          <hr />
        </Col>
        <Col xs={12} className={"px-24"}>
          <FormSelect
            id={"tovarId"}
            label={"Товар"}
            required={true}
            isSearchable
            value={get(values, "tovarId.value", "")}
            defaultValue={get(values, "tovarId", "")}
            isLoading={loadingDetails}
            options={removeValueOption({
              currentOption: productOption,
              removeValueList: tableData,
              valueName: "tovarId",
              isUpdate: get(details, "tovar", null),
            })}
            handleValueLabel={(e: any) => {
              setFieldValue("tovarId", e);
              setFieldValue("edIzmId", e.edIzmId);
              setFieldValue("imyaEdIzm", e.imyaEdIzm);
              setFieldValue("maxKol", get(e, "kol", ""));
              setFieldValue("nds", e.nds);
              setFieldValue("tsena", e.sena);
            }}
          />
          <Row align="center" className="mt-24">
            <Col xs={4} style={{ paddingRight: 0 }}>
              <Title>
                Количество<span className="required_point">*</span>
              </Title>
            </Col>
            <Col xs={8}>
              <BaseMarkInput
                id={"kol"}
                placeholder={`до: ${textFormatter(get(values, "maxKol", 0))}`}
                value={get(values, "kol", "") || " "}
                handleInput={(e: number) => {
                  setFieldValue("kol", e);
                }}
                min={1}
              />
              {Number(get(values, "maxKol", 0)) <
                Number(get(values, "kol", 0)) && (
                  <Text xs danger>
                    ограничение количество до:
                    {textFormatter(get(values, "maxKol", 0))}
                  </Text>
                )}
            </Col>
          </Row>
          <Col xs={12} className="text-right mt-24 mb-16">
            <Button
              className="mr-8"
              green
              handleClick={handleSubmit}
              inability={
                Number(get(values, "maxKol", 0)) >=
                  Number(get(values, "kol", 0))
                  ? false
                  : true
              }
            >
              {get(details, "edIzmId", null) ? PAGE.CHANGE : PAGE.ADD}
            </Button>
            <Button
              onClick={() => {
                handleCloseModal();
                resetForm();
              }}
              danger
            >
              Закрыть
            </Button>
          </Col>
        </Col>
      </Form>
    </>
  );
};

const AddReturnProductContainer = () => {
  const navigate = useNavigate();
  const [openModal, setOpenModal] = useState(false);
  const dispatch = useAppDispatch();
  const { state } = useLocation();
  const filter = useAppSelector(returnProductFilter);
  const returnProduct = useAppSelector(returnProductForm);
  const counterparts = useAppSelector(getCounterpartData);
  const getSalesOptions = useAppSelector(getSaleProductDataOptions);
  const [disable, setDisable] = useState(false);
  const [productId, setProductId] = useState<number | null | undefined>(null);
  // loadings
  let LOADING = get(returnProduct, "loading", false);
  let loadingKontragent = useAppSelector(loadingConterpart);
  let loadingDok = useAppSelector(loadingSalesProductData);
  const handleCloseModal = useCallback(() => {
    setOpenModal(false);
  }, []);

  const handleOpenModal = useCallback((id?: number | null | undefined) => {
    setOpenModal(true);
    setProductId(id);
  }, []);

  const onSubmit = (values: any) => {
    const subjects = get(returnProduct, "data.details", []).map(
      (item: prixodDetails) => ({
        tovarId: get(item, "tovarId", ""),
        edIzmId: get(item, "edIzmId", ""),
        kol: get(item, "kol", ""),
        tsena: get(item, "tsena", ""),
        nds: get(item, "nds", ""),
        summaNDS: get(item, "summaNDS", ""),
      })
    );

    let valuesToSbm: any = {};

    forEach(values, (value: any, key: any) => {
      valuesToSbm[key] = ["dataDoc", "nomerDoc", "kommentariya"].includes(key)
        ? value
        : get(value, "value", "");
    });
    try {
      DocumentServices.AddReturnProduct({
        transactionTime: new Date(),
        resultCode: null,
        resultMsg: null,
        data: {
          ...valuesToSbm,
          dataDoc: !isEqual(get(values, "dataDoc", null), null)
            ? get(values, "dataDoc", "")
            : moment(new Date()).format("YYYY-MM-DD"),
          details: subjects,
        },
      }).then((res: any) => {
        if (get(res, "status", "") == 200) {
          navigate(-1);
          dispatch(fetchReturnProduct(filter));
          dispatch(fetchReturnProductStat());
          toast.success(MESSAGES.ADDED);
          if (get(state, "id", false)) {
            DocumentServices.RemoveTempsReturnProduct(
              get(state, "id", "")
            ).then((res) => {
              // error
              if (get(res, "status", "") == 200) {
                navigate(-1);
                dispatch(fetchReturnProductStat());
                dispatch(clearFormDataReturnProduct());
                if (get(state, "id", false)) {
                  DocumentServices.RemoveTempsReturnMaterials(
                    get(state, "id", "")
                  ).then((res) => {
                    if (get(res, "status", "") == 200) {
                      dispatch(fetchNotFilledReturnProduct());
                    }
                  });
                }
              }
            });
          }
        }
        setSubmitting(false);
      });
    } catch (err) {
      console.log("err", err);
    }
  };
  const { values, setFieldValue, handleSubmit, setSubmitting, isSubmitting } =
    useFormik({
      enableReinitialize: true,
      initialValues: {
        ...get(returnProduct, "data.formIntVls", {}),
        dataDoc: get(state, 'id', false) ? get(returnProduct, "data.formIntVls.dataDoc", '') : ''
      },
      onSubmit,
    });
  const saveNotFullFilledForm = useCallback(() => {
    const subjects = get(returnProduct, "data.details", []).map(
      (item: prixodDetails) => ({
        tovarId: get(item, "materialId", ""),
        edIzmId: get(item, "edIzmId", ""),
        kol: get(item, "kol", ""),
        tsena: get(item, "tsena", ""),
        nds: get(item, "nds", ""),
        summaNDS: get(item, "summaNDS", ""),
      })
    );

    let valuesToSbm: any = {};

    forEach(values, (value: any, key: any) => {
      valuesToSbm[key] = ["dataDoc", "nomerDoc"].includes(key)
        ? value
        : get(value, "value", "");
    });
    try {
      DocumentServices.AddNotFilledReturnProduct({
        transactionTime: new Date(),
        resultCode: null,
        resultMsg: null,
        data: {
          ...valuesToSbm,
          dataDoc: !isEqual(get(values, "dataDoc", null), null)
            ? get(values, "dataDoc", "")
            : moment(new Date()).format("YYYY-MM-DD"),
          details: subjects,
        },
      }).then((res) => {
        if (get(res, "status", "") == 200) {
          navigate(-1);
          dispatch(fetchNotFilledReturnProduct());
          toast.success(MESSAGES.ADDED);
        }
      });
    } catch (err) {
      console.log("err", err);
    }
  }, [dispatch, navigate, values, returnProduct]);

  const getDocNumber = useCallback((date: NumberDocType) => {
    DocumentServices.GetReturnProductNumber(date).then(({ data }) => {
      setFieldValue("nomerDoc", get(data, "data", ""));
      if (get(data, "transactionTime", "")) {
        const transactionTime = get(data, "transactionTime", "");
        const localMaxDate = moment.utc(transactionTime).add(5, 'hours').format("YYYY-MM-DD HH:mm");
        const localDate = moment.utc(transactionTime).add(5, 'hours').format("YYYY-MM-DD");
        setFieldValue("maxDate", localMaxDate);
        if (isEmpty(get(values, 'dataDoc', ''))) {
          setFieldValue("dataDoc", localDate);
        }
      }
    });
  }, [values]);

  useEffect(() => {
    if (!get(state, "id", false)) {
      const cancelReq = dispatch(
        fetchAllCounterpart({ all: true, filterPage: "VOZVRAT_TOV" })
      );
      return () => {
        cancelReq.abort();
      };
    }
  }, []);
  useEffect(() => {
    // getSearchByContract();
    if (get(values, "kontragentId.value", null) && !get(state, "id", false)) {
      const cancelReq = dispatch(
        fetchSalesProduct({
          all: true,
          kontragentId: get(values, "kontragentId.value", null),
          filterPage: "VOZVRAT_TOV",
        })
      );
      return () => {
        cancelReq.abort();
      };
    }
  }, [values.kontragentId]);
  const handleClickTemp = useCallback(() => {
    if (get(state, "id", false)) {
      if (get(state, "isTemp", false)) {
        dispatch(fetchReturnProductByTempId(get(state, "id", "")));
      } else {
        dispatch(fetchReturnProductById(get(state, "id", "")));
        setDisable(true);
      }
    } else {
      getDocNumber({});
    }
  }, [dispatch, state]);

  useEffect(() => {
    handleClickTemp();
    return () => {
      cancel.cancel();
      dispatch(clearFormDataReturnProduct());
      // dispatch(clearPurchaseProductsState());
      dispatch(clearSalesProduct());
    };
  }, [handleClickTemp]);

  return (
    <Content className={"min_height"}>
      {/* modal */}
      <ReactModal isOpen={openModal}>
        <AddNewDocToTable
          handleCloseModal={handleCloseModal}
          documentId={get(values, "docOsnovanieId.value", "")}
          tableData={get(returnProduct, "data.details", [])}
          id={productId}
        />
      </ReactModal>
      {isEqual(LOADING, false) ? (
        <Form onSubmit={handleSubmit}>
          <Row>
            <Col xs={12}>
              <FlexBox
                bold
                sm
                justify={"space-between"}
                style={{ width: "100%" }}
              >
                <Title bold sm>
                  Реквизиты
                </Title>
                <Button
                  green
                  handleClick={saveNotFullFilledForm}
                  disabled={disable}
                >
                  Сохранить
                </Button>
              </FlexBox>
            </Col>
            <Col xs={12} style={{ padding: 0 }} className={"my-16"}>
              <hr />
            </Col>
          </Row>
          <Row justify="start">
            <Col xs={6}>
              <Row align="center">
                <Col xs={4}>
                  <Title>
                    Номер<span className="required_point">*</span>
                  </Title>
                </Col>
                <Col xs={8} className="form-input-date_picker">
                  <Row>
                    <Col xs={6}>
                      <BaseInput
                        id={"nomerDoc"}
                        value={get(values, "nomerDoc", "")}
                      />
                    </Col>
                    <Col xs={6} style={{ paddingLeft: 0 }}>
                      <FlexBox>
                        <Text>от:</Text>
                        <DatePicker
                          id={"dataDoc"}
                          value={get(values, "dataDoc", null)}
                          handleDate={(e: any) => {
                            getDocNumber({
                              year: checkDateYear({
                                currentYear: get(values, "dataDoc", null),
                                checkYear: moment(e).format("YYYY-MM-DD"),
                              }),
                            });
                            setFieldValue(
                              "dataDoc",
                              moment(e).format("YYYY-MM-DD")
                            );
                          }}
                          dateFormat="dd.MM.yyyy"
                          disabled={disable}
                          // maxDate={new Date()}

                          maxDate={new Date(get(values, 'maxDate', new Date()))}
                        />
                      </FlexBox>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col xs={6} className="mt-8">
              <Row align="center" className="mt-24">
                <Col xs={4}>
                  <Title>
                    Контрагент <span className="required_point">*</span>
                  </Title>
                </Col>
                <Col xs={8}>
                  <BaseSelect
                    isSearchable
                    id={"kontragentId"}
                    value={get(values, "kontragentId.value", "")}
                    isLoading={loadingKontragent}
                    options={counterparts}
                    defaultValue={get(values, "kontragentId", {})}
                    handleValueLabel={(e: any) => {
                      setFieldValue("kontragentId", e);
                      if (!isEqual(e, get(values, "kontragentId", false))) {
                        setFieldValue("docOsnovanieId", null);
                        setFieldValue("dogProdajaId", null);
                        setFieldValue("zakazNaryadId", null);
                        setFieldValue("territoriyaId", null);
                        setFieldValue("stavkaNDSId", null);
                        setFieldValue("skladGPId", null);
                        setFieldValue("sposobOplatiId", null);
                        dispatch(addSubjectReturnProduct(null));
                      }
                    }}
                    isDisabled={disable || loadingDok}
                  />
                </Col>
              </Row>
              <Row align="center" className="mt-24">
                <Col xs={4}>
                  <Title>
                    Док. основания для возврата товара{" "}
                    <span className="required_point">*</span>
                  </Title>
                </Col>
                <Col xs={8}>
                  <BaseSelect
                    id={"docOsnovanieId"}
                    value={get(values, "docOsnovanieId.value", "")}
                    defaultValue={get(values, "docOsnovanieId", {})}
                    isLoading={loadingDok}
                    options={getSalesOptions}
                    handleValueLabel={(e: any) => {
                      setFieldValue("docOsnovanieId", e);
                      setFieldValue("dogProdajaId", e.dogProdajaId);
                      setFieldValue("zakazNaryadId", e.zakazNaryadId);
                      setFieldValue("territoriyaId", e.territoriyaId);
                      setFieldValue("stavkaNDSId", e.stavkaNDSId);
                      setFieldValue("skladGPId", e.skladGPId);
                      setFieldValue("sposobOplatiId", e.sposobOplatiId);
                      if (!isEqual(e, get(values, "docOsnovanieId", false))) {
                        dispatch(addSubjectReturnProduct(null));
                      }
                    }}
                    isDisabled={disable}
                  />
                </Col>
              </Row>

              <Row align="center" className="mt-24">
                <Col xs={4} style={{ paddingRight: 0 }}>
                  <Title>
                    Заказ-наряд<span className="required_point">*</span>
                  </Title>
                </Col>
                <Col xs={8}>
                  <BaseInput
                    value={get(values, "zakazNaryadId.label", "")}
                    disabled={true}
                  />
                </Col>
              </Row>
              <Row align="center" className="mt-24">
                <Col xs={4} style={{ paddingRight: 0 }}>
                  <Title>
                    Договор<span className="required_point">*</span>
                  </Title>
                </Col>
                <Col xs={8}>
                  <BaseInput
                    value={get(values, "dogProdajaId.label", "")}
                    disabled={true}
                  />
                </Col>
              </Row>
              <TextArea
                id={"kommentariya"}
                disabled={disable}
                label={"Коментарии"}
                value={get(values, "kommentariya", "")}
                handleInput={(e: string) => {
                  setFieldValue("kommentariya", e);
                }}
              />
            </Col>
            <Col xs={6} className="mt-8">
              <Row align="center" className="mt-24">
                <Col xs={4} style={{ paddingRight: 0 }}>
                  <Title>
                    Территория<span className="required_point">*</span>
                  </Title>
                </Col>
                <Col xs={8}>
                  <BaseInput
                    value={get(values, "territoriyaId.label", "")}
                    disabled={true}
                  />
                </Col>
              </Row>
              <Row align="center" className="mt-24">
                <Col xs={4} style={{ paddingRight: 0 }}>
                  <Title>
                    Склад готовой продукции
                    <span className="required_point">*</span>
                  </Title>
                </Col>
                <Col xs={8}>
                  <BaseInput
                    value={get(values, "skladGPId.label", "")}
                    disabled={true}
                  />
                </Col>
              </Row>
              <Row align="center" className="mt-24">
                <Col xs={4} style={{ paddingRight: 0 }}>
                  <Title>
                    Ставка НДС<span className="required_point">*</span>
                  </Title>
                </Col>
                <Col xs={8}>
                  <BaseInput
                    value={get(values, "stavkaNDSId.label", "")}
                    disabled={true}
                  />
                </Col>
              </Row>
              <Row align="center" className="mt-24">
                <Col xs={4} style={{ paddingRight: 0 }}>
                  <Title>
                    Способ оплаты<span className="required_point">*</span>
                  </Title>
                </Col>
                <Col xs={8}>
                  <BaseInput
                    value={get(values, "sposobOplatiId.label", "")}
                    disabled={true}
                  />
                </Col>
              </Row>
            </Col>
          </Row>

          <Row className="mt-48" justify={"between"}>
            <Col xs={12} className="align-center">
              <FlexBox justify={"space-between"} align={"center"}>
                <Title bold sm>
                  Табличная часть<span className="required_point">*</span>
                </Title>
                {!isEmpty(get(values, "docOsnovanieId.value", null)) &&
                  isEqual(disable, false) ? (
                  <ReactSVG
                    src={add_green}
                    className="svg_icon"
                    onClick={() => handleOpenModal(null)}
                  />
                ) : (
                  ""
                )}
              </FlexBox>
            </Col>
            <Col xs={12} style={{ padding: 0 }} className={"my-16"}>
              <hr />
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <Col>
                <BaseTable
                  tableHeader={[
                    "№",
                    "Товар",
                    "Ед. изм",
                    "Количество",
                    "Цена",
                    "Сумма",
                    "Сумма НДС",
                    "Всего",
                    "",
                  ]}
                >
                  {!isEmpty(get(returnProduct, "data.details", [])) ? (
                    get(returnProduct, "data.details", []).map(
                      (item: prixodDetails, index: number) => (
                        <tr key={index + 1}>
                          <td>{index + 1}</td>
                          <td>{get(item, "imyaTovar", "-")}</td>
                          <td>{get(item, "imyaEdIzm", "-")}</td>
                          <td>
                            <NumericFormat
                              displayType="text"
                              value={get(item, "kol", "")}
                              thousandSeparator
                              decimalScale={2}
                            />
                          </td>
                          <td>
                            <NumericFormat
                              displayType="text"
                              value={get(item, "tsena", "")}
                              thousandSeparator
                              decimalScale={2}
                            />
                          </td>
                          <td>
                            <NumericFormat
                              displayType="text"
                              value={get(item, "summa", "")}
                              thousandSeparator
                              decimalScale={2}
                            />
                          </td>
                          <td>
                            <NumericFormat
                              displayType="text"
                              value={get(item, "summaNDS", "")}
                              thousandSeparator
                              decimalScale={2}
                            />
                          </td>
                          <td>
                            <NumericFormat
                              displayType="text"
                              value={get(item, "vsego", "")}
                              thousandSeparator
                              decimalScale={2}
                            />
                          </td>
                          <td>
                            <ActionBase
                              disable={disable}
                              handleUpdate={handleOpenModal}
                              handleDelete={() =>
                                dispatch(
                                  movementReturnMaterialRealSubject({
                                    id: get(item, "tovarId", null),
                                    type: ACTION.DELETE,
                                  })
                                )
                              }
                              id={get(item, "tovarId", null)}
                            />
                          </td>
                        </tr>
                      )
                    )
                  ) : (
                    <tr>
                      <td colSpan={10}> Нет данных</td>
                    </tr>
                  )}
                </BaseTable>
              </Col>
            </Col>
          </Row>
          <Row className={"mb-16 mt-32 text-end"}>
            <Col xs={12}>
              <HasAccess>
                {({ userCan }: any) =>
                  userCan({
                    permission: `${DOCUMENT_KEY.VOZVRAT_TOV_RGP}_CREATE`,
                    page: DOCUMENT_KEY.VOZVRAT_TOV_RGP,
                    department: MAIN_DEPARTMENT.DOCUMENTS,
                    type: PROTECTED_TYPE.PERMISSIONS,
                  }) && (
                    <FormButton
                      disabled={disable}
                      loading={isSubmitting}
                      handleClick={handleSubmit}
                    >
                      {PAGE.DONE}
                    </FormButton>
                  )
                }
              </HasAccess>

              <Button danger handleClick={() => navigate(-1)}>
                {PAGE.CLOSE}
              </Button>
            </Col>
          </Row>
        </Form>
      ) : (
        <LoadingAddPages />
      )}
    </Content>
  );
};

export default AddReturnProductContainer;
