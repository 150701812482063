import { useAppDispatch, useAppSelector } from "app/hook";
import {
  clearFormChangeSemiProduct,
  fetchChangeSemiProduct,
  fetchChangeSemiProductById,
  fetchChangeSemiProductStat,
  fetchTempChangeSemiProduct,
  fetchTempChangeSemiProductById,
  movementChangeSemiProductSubject,
} from "app/slices/documentSlices/changeSemiProductSlices/changeSemiProductSlices";
import { detailsMovement } from "app/slices/documentSlices/movementMaterialSlices/type";
import { prixodDetails } from "app/slices/documentSlices/receivedMaterialSlices/type";
import {
  changeSemiProductFilter,
  changeSemiProductForm,
} from "app/states/documents";
import add_green from "assets/images/icons/add_green.svg";
import axios from "axios";
import {
  ActionBase,
  BaseInput,
  BaseTable,
  Button,
  Content,
  DatePicker,
  FlexBox,
  Form,
  FormButton,
  FormSelect,
  Input,
  LoadingAddPages,
  ReactModal,
  Text,
  TextArea,
  Title,
} from "components";
import BaseMarkInput from "components/BaseMarkInput/BaseMarkInput";
import { useFormik } from "formik";
import { use } from "i18next";
import { forEach, get, isEmpty, isEqual } from "lodash";
import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import { Col, Row } from "react-grid-system";
import { NumericFormat } from "react-number-format";
import { useLocation, useNavigate } from "react-router-dom";
import { ReactSVG } from "react-svg";
import { toast } from "react-toastify";
import HasAccess from "routers/HasAccess";
import { DOCUMENT_KEY, MAIN_DEPARTMENT } from "routers/constants";
import DocumentServices from "services/apiServices/documents";
import { ACTION, MESSAGES, PAGE, PROTECTED_TYPE } from "utilities/constants";
import {
  checkDateYear,
  removeValueOption,
  textFormatter,
} from "utilities/helpers";
import { changeSemiProductDetailsSchema } from "utilities/schema/document/documentSchemas";
import { NumberDocType } from "types/documentsType";

//  Добавить Изменение параметров полуфабрикатов на складе
interface DetailsData {
  polufabrikatId: any;
  edIzmId: number | string | null;
  imyaEdIzm: string | null;
  maxKol: any;
  kol: number | string | null;
}
const AddNewDocToTable = ({
  handleOpenModal,
  handleCloseModal,
  dataTable,
  id,
  zn1Id,
  zn2Id,
}: {
  handleOpenModal?: () => void;
  handleCloseModal: () => void;
  dataTable: Array<any>;
  id: number | string | null | undefined;
  zn1Id: string | null;
  zn2Id: string | null;
}) => {
  const dispatch = useAppDispatch();
  const [productOptions, setProductOptions] = useState<any>([]);
  const details = dataTable?.find((item) => item.polufabrikatId === id);
  let cancelReq = axios.CancelToken.source();
  const [loading, setLoading] = useState(false);
  const onSubmit = (values: DetailsData) => {
    const res = {
      ...values,
      poifablrikat: get(values, "poifablrikat", {}),
      polufabrikatId: get(values, "polufabrikatId.value", null),
      imyaPolufabrikat: get(values, "polufabrikatId.label", ""),
    };
    if (res) {
      if (isEmpty(details)) {
        dispatch(
          movementChangeSemiProductSubject({ data: res, type: ACTION.ADD })
        );
      } else {
        dispatch(
          movementChangeSemiProductSubject({
            data: res,
            type: ACTION.UPDATE,
            id,
          })
        );
      }
      resetForm();
      handleCloseModal();
    }
  };
  // get porifabrikat details  by order id
  const fetchPFComponents = useCallback(() => {
    if (zn1Id && zn2Id && isEmpty(details)) {
      setLoading(true);
      DocumentServices.FetchPolifabrikatSemiChangeProduct({
        zn1Id,
        zn2Id,
        cancelReq: cancelReq.token,
      })
        .then(({ data }) => {
          setLoading(false);
          if (data.data) {
            const productComponent = get(data, "data", []).map((item: any) => ({
              label: get(item, "imyaPolufabrikat", ""),
              value: get(item, "polufabrikatId", ""),
              edIzmId: get(item, "edIzmId"),
              imyaEdIzm: get(item, "imyaEdIzm", ""),
              maxKol: get(item, "maxKol", ""),
            }));
            setProductOptions(productComponent);
          }
        })
        .catch((err) => console.log(err));
    } else {
      setProductOptions([]);
    }
  }, [zn1Id, zn2Id]);
  const { values, handleSubmit, setFieldValue, resetForm } = useFormik({
    enableReinitialize: true,
    initialValues: {
      imyaEdIzm: get(details, "imyaEdIzm", null),
      polufabrikatId: {
        value: get(details, "polufabrikatId", null),
        label: get(details, "imyaPolufabrikat", null),
      },
      edIzmId: get(details, "edIzmId", null),
      kol: get(details, "kol", null),
      maxKol: get(details, "maxKol", 0),
    },
    onSubmit,
    validationSchema: changeSemiProductDetailsSchema,
  });
  const handleClose = () => {
    resetForm();
    handleCloseModal();
  };
  useEffect(() => {
    fetchPFComponents();
    return () => {
      cancelReq.cancel();
      setProductOptions([]);
    };
  }, [fetchPFComponents]);
  return (
    <>
      <Col xs={12} className="mt-16 text-left">
        <Title sm bold>
          Ввод данных
        </Title>
      </Col>
      <Col xs={12} style={{ padding: 0 }} className={"my-16"}>
        <hr />
      </Col>
      <Form onSubmit={handleSubmit} align={"center"}>
        <Col xs={12} className={"px-24"}>
          <FormSelect
            id={"polufabrikatId"}
            label={"Полуфабрикат"}
            defaultValue={get(values, "polufabrikatId", {})}
            required={true}
            isLoading={loading}
            isSearchable
            value={get(values, "polufabrikatId.value", "")}
            options={removeValueOption({
              currentOption: productOptions,
              removeValueList: dataTable,
              valueName: "polufabrikatId",
              isUpdate: details
                ? {
                  value: get(details, "polufabrikatId", null),
                  label: get(details, "imyaPolufabrikat", null),
                  maxKol: get(details, "maxKol", 0),
                }
                : "",
            })}
            handleValueLabel={(e: any) => {
              setFieldValue("polufabrikatId", e);
              setFieldValue("edIzmId", e.edIzmId);
              setFieldValue("imyaEdIzm", e.imyaEdIzm);
              setFieldValue("maxKol", e.maxKol);
            }}
          />

          <Row align="center" className="mt-24">
            <Col xs={4} style={{ paddingRight: 0 }}>
              <Title>
                Количество
                <span className="required_point">*</span>
              </Title>
            </Col>
            <Col xs={8}>
              <BaseMarkInput
                id={"kol"}
                placeholder={`до: ${textFormatter(get(values, "maxKol", ""))}`}
                value={get(values, "kol", null) || " "}
                handleInput={(e: number) => {
                  setFieldValue("kol", e);
                }}
                min={1}
                maxLength={40}
              />
              {Number(get(values, "kol", 0)) >
                Number(get(values, "maxKol", 0)) && (
                  <Text xs danger>
                    ограничение количество до:{" "}
                    {textFormatter(get(values, "maxKol", ""))}
                  </Text>
                )}
            </Col>
          </Row>
          <Col xs={12} className="text-right mt-24 mb-16">
            <Button
              className="mr-8"
              type={"submit"}
              green
              inability={
                Number(get(values, "maxKol", 0)) < Number(get(values, "kol", 0))
              }
            >
              {get(details, "imyaEdIzm", null) ? PAGE.CHANGE : PAGE.ADD}
            </Button>
            <Button onClick={handleClose} danger>
              Закрыть
            </Button>
          </Col>
        </Col>
      </Form>
    </>
  );
};

const AddChangeSemiProductContainer = () => {
  const navigate = useNavigate();
  const [openModal, setOpenModal] = useState(false);
  const dispatch = useAppDispatch();
  const { state } = useLocation();
  const filter = useAppSelector(changeSemiProductFilter);
  const changeProductFormData = useAppSelector(changeSemiProductForm);
  const [disable, setDisable] = useState(false);
  const [productFindId, setProductFindId] = useState<number | string | null>();
  let cancelKn1 = axios.CancelToken.source();
  let cancelKn2 = axios.CancelToken.source();
  let cancelZn1 = axios.CancelToken.source();
  let cancelZn2 = axios.CancelToken.source();
  const [allKontragentOptions, setAllKontragent2] = useState<any>([]);
  const [kontragetn1, setKontragetn1] = useState<Array<any>>([]);
  const [allZakazNaryadOptions, setAllZakazNaryadOptions] = useState<any>({
    zakazNaryad1: [],
    zakazNaryad2: [],
  });
  // loaders
  const [loadingKontragent2, setLoadingKontragent2] = useState(false);
  const [loadingKontragent, setLoadingKontragent] = useState(false);
  const [loadingZn, setLoaidngZn] = useState(false);
  const [loadingZn2, setLoaidngZn2] = useState(false);

  let LOADING = get(changeProductFormData, "loading", false);
  const handleCloseModal = useCallback(() => {
    setOpenModal(false);
  }, []);

  const handleOpenModal = useCallback((id: number | string | null) => {
    setOpenModal(true);
    setProductFindId(id);
  }, []);

  const onSubmit = (values: any) => {
    const subjects = get(changeProductFormData, "data.details", []).map(
      (item: detailsMovement) => ({
        polufabrikatId: get(item, "polufabrikatId", ""),
        edIzmId: get(item, "edIzmId", ""),
        kol: get(item, "kol", ""),
      })
    );
    let valuesToSbm: any = {};

    forEach(values, (value: any, key: any) => {
      valuesToSbm[key] = ["dataDoc", "nomerDoc", "kommentariya"].includes(key)
        ? value
        : get(value, "value", "");
    });

    try {
      DocumentServices.AddChangeSemiProduct({
        transactionTime: new Date(),
        resultCode: null,
        resultMsg: null,
        data: {
          ...valuesToSbm,
          dataDoc: !isEqual(get(values, "dataDoc", null), null)
            ? get(values, "dataDoc", "")
            : moment(new Date()).format("YYYY-MM-DD"),
          details: subjects,
        },
      }).then((res) => {
        setSubmitting(false);
        if (get(res, "status", "") == 200) {
          navigate(-1);
          dispatch(fetchChangeSemiProduct(filter));
          dispatch(fetchChangeSemiProductStat());
          toast.success(MESSAGES.ADDED);
          if (get(state, "id", false)) {
            DocumentServices.RemoveChangeSemiProductTemp(
              get(state, "id", "")
            ).then((res) => {
              if (res.status === 200) {
                console.log("success delete temp");
              }
            });
          }
        }
      });
    } catch (err) {
      console.log("err", err);
      setSubmitting(false);
    }
  };

  const { values, setFieldValue, handleSubmit, setSubmitting, isSubmitting } =
    useFormik({
      enableReinitialize: true,
      initialValues: {
        ...get(changeProductFormData, "data.formIntVls", {}),
        dataDoc: get(state, 'id', false) ? get(changeProductFormData, "data.formIntVls.dataDoc", '') : ''
      },
      onSubmit,
    });

  const saveNotFullFilledForm = useCallback(() => {
    const subjects = get(changeProductFormData, "data.details", []);
    let valuesToSbm: any = {};

    forEach(values, (value: any, key: any) => {
      valuesToSbm[key] = ["dataDoc", "nomerDoc", "kommentariya"].includes(key)
        ? value
        : get(value, "value", "");
    });

    try {
      DocumentServices.AddChangeSemiProductTemp({
        transactionTime: new Date(),
        resultCode: null,
        resultMsg: null,
        data: {
          ...valuesToSbm,
          // temp
          id: get(state, "id", null),
          imyaKontragent1: get(values, "kontragent1Id.label", ""),
          nomerZakazNaryad1: get(values, "zakazNaryad1Id.label", ""),
          nomerDogProdaja1: get(values, "dogProdaja1Id.label", ""),
          imyaTerritoriya1: get(values, "territoriya1Id.label", ""),
          imyaSkladaPF1: get(values, "skladPF1Id.label", ""),

          imyaKontragent2: get(values, "kontragent2Id.label", ""),
          nomerZakazNaryad2: get(values, "zakazNaryad2Id.label", ""),
          nomerDogProdaja2: get(values, "dogProdaja2Id.label", ""),
          imyaTerritoriya2: get(values, "territoriya2Id.label", ""),
          imyaSkladaPF2: get(values, "skladPF2Id.label", ""),
          dataDoc: !isEqual(get(values, "dataDoc", null), null)
            ? get(values, "dataDoc", "")
            : moment(new Date()).format("YYYY-MM-DD"),
          details: subjects,
        },
      }).then((res) => {
        if (get(res, "status", "") == 200) {
          navigate(-1);
          dispatch(fetchTempChangeSemiProduct({}));
          toast.success(MESSAGES.SEVED);
        }
      });
    } catch (err) {
      console.log("err", err);
    }
  }, [
    dispatch,
    navigate,
    values,
    get(changeProductFormData, "data.details", []),
  ]);

  //    fetch document number
  const getDocNumber = useCallback((date: NumberDocType) => {
    DocumentServices.FetchChangeSemiProductNumber(date).then(({ data }) => {
      setFieldValue("nomerDoc", get(data, "data", ""));
      if (get(data, "transactionTime", "")) {
        const transactionTime = get(data, "transactionTime", "");
        const localMaxDate = moment.utc(transactionTime).add(5, 'hours').format("YYYY-MM-DD HH:mm");
        const localDate = moment.utc(transactionTime).add(5, 'hours').format("YYYY-MM-DD");
        setFieldValue("maxDate", localMaxDate);
        if (isEmpty(get(values, 'dataDoc', ''))) {
          setFieldValue("dataDoc", localDate);
        }

      }
    });
  }, [values]);

  //  fetch kn1
  const fetchKontragentOptions = useCallback(() => {
    if (isEmpty(get(state, "id", null))) {
      setLoadingKontragent(true);
      DocumentServices.FetchChangeSemiProductKontragent({
        tip: 1,
        signal: cancelKn1.token,
      })
        .then(({ data }) => {
          setLoadingKontragent(false);
          const res: Array<any> = get(data, "data", []).map((item: any) => ({
            label: get(item, "imyaKontragent", ""),
            value: get(item, "id", ""),
          }));
          if (res) {
            setKontragetn1(res);
          }
        })
        .catch((err) => console.log(err));
    }
  }, []);
  // fetch zn1
  const fetchZakazNaryadOptions1 = useCallback(() => {
    if (get(values, "kontragent1Id.value", null) && !get(state, "id", null)) {
      setLoaidngZn(true);
      DocumentServices.FetchSemiProductZakazNaryad({
        tip: 1,
        kontragentId: get(values, "kontragent1Id.value", null),
        cancel: cancelZn1.token,
      }).then(({ data }) => {
        setLoaidngZn(false);
        const res = get(data, "data", []).map((item: any) => ({
          label: get(item, "nomerDoc", ""),
          value: get(item, "id", ""),
          dogProdajaId: get(item, "dogProdajaId", ""),
          dogProdajaNomer: get(item, "dogProdajaNomer", ""),
          territoriyaId: get(item, "territoriyaId", ""),
          imyaTerritoriya: get(item, "imyaTerritoriya", ""),
          skladId: get(item, "skladId", ""),
          imyaSklada: get(item, "imyaSklada", ""),
        }));
        if (res) {
          setAllZakazNaryadOptions({
            ...allZakazNaryadOptions,
            zakazNaryad1: res,
          });
        }
      });
    }
  }, [values.kontragent1Id]);
  // fetch kn 2
  const fetchKontragentOptions2 = useCallback(() => {
    if (get(values, "zakazNaryad1Id.value", null) && !get(state, "id", false)) {
      setLoadingKontragent2(true);

      DocumentServices.FetchChangeSemiProductKontragent({
        tip: 2,
        zn1Id: get(values, "zakazNaryad1Id.value", ""),
        signal: cancelKn2.token,
      })
        .then(({ data }) => {
          setLoadingKontragent2(false);
          const res: Array<any> = get(data, "data", []).map((item: any) => ({
            label: get(item, "imyaKontragent", ""),
            value: get(item, "id", ""),
          }));
          if (res) {
            setAllKontragent2(res);
          }
        })
        .catch((err) => console.log(err));
    }
  }, [values.zakazNaryad1Id]);

  // fetch zn 2
  const fetchZakazNaryadOptions2 = useCallback(() => {
    if (!get(state, "id", null)) {
      if (
        get(values, "kontragent2Id.value", false) &&
        get(values, "zakazNaryad1Id.value", false)
      ) {
        setLoaidngZn2(true);
        DocumentServices.FetchSemiProductZakazNaryad({
          tip: 2,
          kontragentId: get(values, "kontragent2Id.value", null),
          zn1Id: get(values, "zakazNaryad1Id.value", null),
          cancel: cancelZn2.token,
        }).then(({ data }) => {
          setLoaidngZn2(false);
          const res = get(data, "data", []).map((item: any) => ({
            label: get(item, "nomerDoc", ""),
            value: get(item, "id", ""),
            dogProdajaId: get(item, "dogProdajaId", ""),
            dogProdajaNomer: get(item, "dogProdajaNomer", ""),
            territoriyaId: get(item, "territoriyaId", ""),
            imyaTerritoriya: get(item, "imyaTerritoriya", ""),
            skladId: get(item, "skladId", ""),
            imyaSklada: get(item, "imyaSklada", ""),
          }));
          if (res) {
            setAllZakazNaryadOptions({
              ...allZakazNaryadOptions,
              zakazNaryad2: res,
            });
          }
        });
      }
    }
  }, [values.kontragent2Id]);

  const handleClickTemp = useCallback(() => {
    if (get(state, "id", false)) {
      if (get(state, "isTemp", false)) {
        dispatch(fetchTempChangeSemiProductById(get(state, "id", "")));
      } else {
        dispatch(fetchChangeSemiProductById(get(state, "id", "")));
        setDisable(true);
      }
    } else {
      getDocNumber({});
    }
  }, [dispatch, state]);
  // kn1
  useEffect(() => {
    fetchKontragentOptions();
    return () => {
      cancelKn1.cancel();
      dispatch(clearFormChangeSemiProduct());
    };
  }, []);
  // zn1
  useEffect(() => {
    fetchZakazNaryadOptions1();
    return () => {
      cancelZn1.cancel();
      setAllZakazNaryadOptions({
        ...allZakazNaryadOptions,
        zakazNaryad1: [],
      });
    };
  }, [values.kontragent1Id]);
  // kn2
  useEffect(() => {
    fetchKontragentOptions2();
    return () => {
      cancelKn2.cancel();
      setAllKontragent2([]);
    };
  }, [values.zakazNaryad1Id]);
  // zn2
  useEffect(() => {
    fetchZakazNaryadOptions2();
    return () => {
      cancelZn2.cancel();
      setAllZakazNaryadOptions({
        ...allZakazNaryadOptions,
        zakazNaryad2: [],
      });
    };
  }, [values.kontragent2Id]);
  //  checking by id or temp
  useEffect(() => {
    handleClickTemp();
  }, [handleClickTemp]);
  return (
    <Content className={"min_height"} style={{ position: "relative" }}>
      {/* modal */}
      <ReactModal isOpen={openModal}>
        <AddNewDocToTable
          handleCloseModal={handleCloseModal}
          dataTable={get(changeProductFormData, "data.details", [])}
          zn1Id={get(values, "zakazNaryad1Id.value", "")}
          zn2Id={get(values, "zakazNaryad2Id.value", "")}
          id={productFindId}
        />
      </ReactModal>
      {isEqual(LOADING, false) ? (
        <Form onSubmit={handleSubmit}>
          <Row>
            <Col xs={12}>
              <FlexBox
                bold
                sm
                justify={"space-between"}
                style={{ width: "100%" }}
              >
                <Title bold sm>
                  {PAGE.REQ}
                </Title>
                {/* <Button
                  green
                  handleClick={saveNotFullFilledForm}
                  disabled={disable}
                >
                  {PAGE.SAVE}
                </Button> */}
              </FlexBox>
            </Col>
            <Col xs={12} style={{ padding: 0 }} className={"my-16"}>
              <hr />
            </Col>
          </Row>
          <Row justify="start">
            <Col xs={6}>
              <Row align="center">
                <Col xs={4}>
                  <Title>
                    Номер<span className="required_point">*</span>
                  </Title>
                </Col>
                <Col xs={8} className="form-input-date_picker">
                  <Row>
                    <Col xs={6}>
                      <BaseInput
                        id={"nomerDoc"}
                        value={get(values, "nomerDoc", "")}
                      />
                    </Col>
                    <Col xs={6} style={{ paddingLeft: 0 }}>
                      <FlexBox>
                        <Text>от:</Text>
                        <DatePicker
                          id={"dataDoc"}
                          value={get(values, "dataDoc", null)}
                          handleDate={(e: any) => {
                            getDocNumber({
                              year: checkDateYear({
                                currentYear: get(values, "dataDoc", null),
                                checkYear: moment(e).format("YYYY-MM-DD"),
                              }),
                            });
                            setFieldValue(
                              "dataDoc",
                              moment(e).format("YYYY-MM-DD")
                            );
                          }}
                          dateFormat="dd.MM.yyyy"
                          disabled={disable}
                          // maxDate={new Date()}
                          maxDate={new Date(get(values, 'maxDate', new Date()))}
                        />
                      </FlexBox>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col xs={6}>
              <FormSelect
                className={"mt-24"}
                label={"Контрагент_1"}
                required={true}
                isSearchable
                isDisabled={
                  disable || loadingZn || loadingKontragent2 || loadingZn2
                }
                value={get(values, "kontragent1Id.value", "")}
                options={kontragetn1}
                defaultValue={get(values, "kontragent1Id", "")}
                isLoading={loadingKontragent}
                handleValueLabel={(e: any) => {
                  setFieldValue("kontragent1Id", e);
                  if (!isEqual(e, get(values, "kontragent1Id", null))) {
                    setFieldValue("zakazNaryad1Id", null);
                    setFieldValue("kontragent2Id", null);
                    setFieldValue("zakazNaryad2Id", null);
                    setFieldValue("dogProdaja1Id", null);
                    setFieldValue("territoriya1Id", null);
                    setFieldValue("skladPF1Id", null);
                    setFieldValue("dogProdaja2Id", null);
                    setFieldValue("territoriya2Id", null);
                    setFieldValue("skladPF2Id", null);
                    setAllZakazNaryadOptions({
                      ...allZakazNaryadOptions,
                      zakazNaryad1: [],
                    });
                    dispatch(
                      movementChangeSemiProductSubject({
                        data: [],
                        type: ACTION.ADD,
                      })
                    );
                    setAllKontragent2([]);
                    setAllZakazNaryadOptions({
                      ...allZakazNaryadOptions,
                      zakazNaryad2: [],
                    });
                  }
                }}
              />
              <FormSelect
                className={"mt-24"}
                label={"Заказ-наряд_1"}
                required={true}
                isDisabled={disable || loadingKontragent2 || loadingZn2}
                isSearchable
                defaultValue={get(values, "zakazNaryad1Id", "")}
                value={get(values, "zakazNaryad1Id.value", "")}
                options={allZakazNaryadOptions.zakazNaryad1}
                isLoading={loadingZn}
                handleValueLabel={(e: any) => {
                  setFieldValue("zakazNaryad1Id", e);
                  setFieldValue("dogProdaja1Id.value", e.dogProdajaId);
                  setFieldValue("dogProdaja1Id.label", e.dogProdajaNomer);
                  setFieldValue("territoriya1Id.value", e.territoriyaId);
                  setFieldValue("territoriya1Id.label", e.imyaTerritoriya);
                  setFieldValue("skladPF1Id.value", e.skladId);
                  setFieldValue("skladPF1Id.label", e.imyaSklada);

                  if (
                    !isEqual(e.value, get(values, "zakazNaryad1Id.value", ""))
                  ) {
                    setFieldValue("kontragent2Id", null);
                    setFieldValue("zakazNaryad2Id", null);
                    setFieldValue("dogProdaja2Id", null);
                    setFieldValue("territoriya2Id", null);
                    setFieldValue("skladPF2Id", null);
                    dispatch(
                      movementChangeSemiProductSubject({
                        data: [],
                        type: ACTION.ADD,
                      })
                    );
                  }
                }}
              />
              <Input
                label={"Договор_1"}
                required={true}
                value={get(values, "dogProdaja1Id.label", "")}
              />
              <Input
                label={"Территория_1"}
                required={true}
                value={get(values, "territoriya1Id.label", "")}
              />
              <Input
                label={"Склад полуфабрикатов_1"}
                required={true}
                value={get(values, "skladPF1Id.label", "")}
              />
              <TextArea
                disabled={disable}
                id={"kommentariya"}
                label={"Коментарии"}
                value={get(values, "kommentariya", "")}
                handleInput={(e: string) => {
                  setFieldValue("kommentariya", e);
                }}
              />
            </Col>
            <Col xs={6}>
              <FormSelect
                className={"mt-24"}
                label={"Контрагент_2"}
                required={true}
                isDisabled={disable || loadingZn2}
                isSearchable
                defaultValue={get(values, "kontragent2Id", {})}
                value={get(values, "kontragent2Id.value", "")}
                options={allKontragentOptions}
                isLoading={loadingKontragent2}
                handleValueLabel={(e: any) => {
                  setFieldValue("kontragent2Id", e);
                  if (
                    !isEqual(e.value, get(values, "kontragent2Id.value", ""))
                  ) {
                    setAllZakazNaryadOptions({
                      ...allZakazNaryadOptions,
                      zakazNaryad2: [],
                    });
                    setFieldValue("zakazNaryad2Id", null);
                    setFieldValue("dogProdaja2Id", null);
                    setFieldValue("territoriya2Id", null);
                    setFieldValue("skladPF2Id", null);
                    dispatch(
                      movementChangeSemiProductSubject({
                        data: [],
                        type: ACTION.ADD,
                      })
                    );
                  }
                }}
              />
              <FormSelect
                className={"mt-24"}
                label={"Заказ-наряд_2"}
                required={true}
                isDisabled={disable}
                isSearchable
                value={get(values, "zakazNaryad2Id.value", "")}
                defaultValue={get(values, "zakazNaryad2Id", "")}
                options={allZakazNaryadOptions.zakazNaryad2}
                isLoading={loadingZn2}
                handleValueLabel={(e: any) => {
                  setFieldValue("zakazNaryad2Id", e);
                  setFieldValue("dogProdaja2Id.value", e.dogProdajaId);
                  setFieldValue("dogProdaja2Id.label", e.dogProdajaNomer);
                  setFieldValue("territoriya2Id.value", e.territoriyaId);
                  setFieldValue("territoriya2Id.label", e.imyaTerritoriya);
                  setFieldValue("skladPF2Id.value", e.skladId);
                  setFieldValue("skladPF2Id.label", e.imyaSklada);
                  if (!isEqual(e.value, get(values, "zakazNaryad2Id.value"))) {
                    dispatch(
                      movementChangeSemiProductSubject({
                        data: [],
                        type: ACTION.ADD,
                      })
                    );
                  }
                }}
              />
              <Input
                label={"Договор_2"}
                required={true}
                value={get(values, "dogProdaja2Id.label", "")}
              />
              <Input
                label={"Территория_2"}
                required={true}
                value={get(values, "territoriya2Id.label", "")}
              />
              <Input
                label={"Склад полуфабрикатов_2"}
                required={true}
                value={get(values, "skladPF2Id.label", "")}
              />
            </Col>
          </Row>
          <Row className="mt-24" justify={"between"}>
            <Col xs={12} className="align-center">
              <FlexBox justify={"space-between"} align={"center"}>
                <Title bold sm>
                  Табличная часть<span className="required_point">*</span>
                </Title>
                {!disable &&
                  !isEmpty(get(values, "zakazNaryad2Id.value", null)) && (
                    <ReactSVG
                      src={add_green}
                      className="svg_icon"
                      onClick={() => handleOpenModal(null)}
                    />
                  )}
              </FlexBox>
            </Col>
            <Col xs={12} style={{ padding: 0 }} className={"my-16"}>
              <hr />
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <Col>
                <BaseTable
                  tableHeader={[
                    "№",
                    "Полуфабрикат",
                    "Ед.изм",
                    "Количество",
                    "",
                  ]}
                >
                  {!isEmpty(get(changeProductFormData, "data.details", [])) ? (
                    get(changeProductFormData, "data.details", []).map(
                      (item: prixodDetails, index: number) => (
                        <tr key={index + 1}>
                          <td>{index + 1}</td>
                          <td>{get(item, "imyaPolufabrikat", "-")}</td>
                          <td>{get(item, "imyaEdIzm", "-")}</td>
                          <td>
                            <NumericFormat
                              displayType="text"
                              value={get(item, "kol", "")}
                              thousandSeparator
                              decimalScale={2}
                            />
                          </td>
                          <td>
                            <ActionBase
                              handleUpdate={handleOpenModal}
                              handleDelete={() =>
                                dispatch(
                                  movementChangeSemiProductSubject({
                                    type: ACTION.DELETE,
                                    id: get(item, "polufabrikatId", null),
                                  })
                                )
                              }
                              disable={disable}
                              id={get(item, "polufabrikatId", null)}
                            />
                          </td>
                        </tr>
                      )
                    )
                  ) : (
                    <tr>
                      <td colSpan={8}> Нет данных</td>
                    </tr>
                  )}
                </BaseTable>
              </Col>
            </Col>
          </Row>
          <Row className={"mb-16 mt-32 text-end"}>
            <Col xs={12}>
              <HasAccess>
                {({ userCan }: any) =>
                  userCan({
                    permission: `${DOCUMENT_KEY.IZMENENIE_PF}_CREATE`,
                    page: DOCUMENT_KEY.IZMENENIE_PF,
                    department: MAIN_DEPARTMENT.DOCUMENTS,
                    type: PROTECTED_TYPE.PERMISSIONS,
                  }) && (
                    <FormButton
                      disabled={disable}
                      loading={isSubmitting}
                      handleClick={handleSubmit}
                    >
                      {PAGE.DONE}
                    </FormButton>
                  )
                }
              </HasAccess>
              <Button danger handleClick={() => navigate(-1)}>
                {PAGE.CLOSE}
              </Button>
            </Col>
          </Row>
        </Form>
      ) : (
        <LoadingAddPages />
      )}
    </Content>
  );
};

export default AddChangeSemiProductContainer;
