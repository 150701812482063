import axios from "axios";
import config from "config";
import { get } from "lodash";
import { toast } from "react-toastify";

const request = axios.create({
  baseURL: config.API_ROOT,
  // baseURL: "http://192.168.0.122:8081",
  params: {},

});

let store: any;
export const injectStore = (_store: any) => {
  store = _store;
};

request.interceptors.request.use(
  (config: any) => {
    if (!config.headers.Authorization) {
      const token = store.getState().auth.token;
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }
    }

    return config;
  },
  (error) => {
    console.log(error);
    return Promise.reject(error);
  }
);

request.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    const statusCode = get(error, "response.status", "");
    const errorMsg = get(error, "response.data.resultMsg", "Ошибка!");

    switch (statusCode) {
      case 101:
        toast.warning(errorMsg);
        return;
      case 409:
        toast.warning(errorMsg);
        return;
      case 400:
        toast.warning(errorMsg);
        return;
      case 500:
        toast.error("Ошибка сервера (Server error!)");
        return;
      case 502:
        alert(
          "status:502 \n  В настоящее время мы вносим изменения на сервер!"
        );
        return;
      default:
        return error;
    }
  }
);

export default request;
