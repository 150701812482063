import { Button, Content, DatePicker, FlexBox, Title } from "components";
import { Col, Row } from "react-grid-system";
import { BalanceSheetTable } from "../components";
import { useAppDispatch, useAppSelector } from "app/hook";
import { useCallback, useEffect, useMemo } from "react";
import moment from "moment";
import {
  changeBlanceSheet,
  clearBlancaSheet,
  fetchBalanceSheet,
  filterBlancaSheet,
} from "app/slices/reportSlices/blancaSheetSlices/blancaSheetSlices";
import { balanceSheetFilter, balanceSheetList } from "app/states/reports";
import { get, includes, isEmpty, isEqual } from "lodash";
import ReportInfoTitle from "../components/ReportInfoTitle";
import TableContentLoader from "../louders/TableContentLoader";
// import ExcelJS from "exceljs";
import * as ExcelJS from "exceljs";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { Reports } from "routers/paths";
import NotFound from "../components/NotFound";

type ParamsType = {
  name: string;
  clear?: boolean;
  value: any;
};

// Оборотно-сальдовая ведомость
const BalanceSheetContainer = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const isAddForm = includes(
    get(location, "pathname", ""),
    Reports.REPORT_BALANCE_SHEET_ITEM
  );

  const downloadExcel = async () => {
    const workbook = new ExcelJS.Workbook();
    const EXCEL_SHEET = `Оборотно-сальдовая ведомость ${moment(
      new Date()
    ).format("YYYY-MM-DD")}`;
    const sheet = workbook.addWorksheet(EXCEL_SHEET);
    sheet.mergeCells("A1", `I1`);
    sheet.getCell("A1").value = `Оборотно-сальдовая ведомость с ${moment(
      get(filter, "fromDate", 0)
    ).format("DD.MM.YYYY")} по ${moment(get(filter, "toDate", 0)).format(
      "DD.MM.YYYY"
    )}.`;
    sheet.getRow(3).height = 20; // C3 qatridagi balandlik
    sheet.getRow(4).height = 20; // C4 qatridagi balandlik
    sheet.getRow(1).height = 20;
    sheet.mergeCells("A3", `A4`);
    sheet.getCell("A3").value = "Код";
    sheet.mergeCells("B3", "B4");
    sheet.getCell("B3").value = "Наименованиесчета";
    sheet.mergeCells("C3", "D3");
    sheet.getCell("C3").value = "Сальдо на начало периода";
    sheet.getCell("C4").value = "Дебет";
    sheet.getCell("D4").value = "Кредит";
    sheet.mergeCells("E3", "F3");
    sheet.getCell("E3").value = "Обороты периода";
    sheet.getCell("E4").value = "Дебет";
    sheet.getCell("F4").value = "Кредит";
    sheet.mergeCells("G3", "H3");
    sheet.getCell("G3").value = "Сальдо на конец периода";
    sheet.getCell("G4").value = "Дебет";
    sheet.getCell("H4").value = "Кредит";
    let count = 1;
    sheet.getCell("A3").alignment = {
      horizontal: "center",
      vertical: "middle",
    };
    sheet.getCell("B3").alignment = {
      horizontal: "center",
      vertical: "middle",
    };
    sheet.getCell("C3").alignment = {
      horizontal: "center",
      vertical: "middle",
    };
    sheet.getCell("C4").alignment = {
      horizontal: "center",
      vertical: "middle",
    };
    sheet.getCell("D4").alignment = {
      horizontal: "center",
      vertical: "middle",
    };
    sheet.getCell("E3").alignment = {
      horizontal: "center",
      vertical: "middle",
    };
    sheet.getCell("E4").alignment = {
      horizontal: "center",
      vertical: "middle",
    };
    sheet.getCell("F4").alignment = {
      horizontal: "center",
      vertical: "middle",
    };
    sheet.getCell("G3").alignment = {
      horizontal: "center",
      vertical: "middle",
    };
    sheet.getCell("G4").alignment = {
      horizontal: "center",
      vertical: "middle",
    };
    sheet.getCell("H4").alignment = {
      horizontal: "center",
      vertical: "middle",
    };
    sheet.columns = [
      { width: 30 },
      { width: 60 },
      { width: 30 },
      { width: 30 },
      { width: 30 },
      { width: 30 },
      { width: 30 },
      { width: 30 },
    ];

    const tableHeader = [
      {
        header: "Код",
        key: "nomerScheta",
        width: 30,
      },
      {
        header: "Наименованиесчета",
        key: "imyaScheta",
        width: 60,
      },
      {
        header: "Дебет",
        key: "nachalniyOstatokDebit",
        width: 30,
      },
      {
        header: "Kredit",
        key: "nachalniyOstatokKredit",
        width: 30,
      },
      {
        header: "Способ оплаты",
        key: "oborotDebit",
        width: 30,
      },
      {
        header: "Условия оплаты",
        key: "borotKredit",
        width: 30,
      },
      {
        header: "konechniyOstatokDebit",
        key: "konechniyOstatokDebit",
        width: 30,
      },
      {
        header: "konechniyOstatokKredit",
        key: "konechniyOstatokKredit",
        width: 30,
      },
    ];

    const headerValues = tableHeader.map((item) => item.header);
    const headerKeys = tableHeader.map(({ header, key, ...props }) => ({
      key: key,
      ...props,
    }));
    // sheet.getRow(5).values = headerValues;
    sheet.columns = headerKeys;
    const headerRow = sheet.getRow(3);
    headerRow.height = 25;
    headerRow.alignment = { vertical: "middle", horizontal: "center" };
    let counterRow = 1;
    get(data, "balanceList.list", [])?.forEach((item, index) => {
      // const headerRow1 = sheet.getRow(index + 4);
      // headerRow1.height = 25;
      // headerRow1.alignment = { vertical: "middle", horizontal: "center" };
      sheet.addRow({
        nomerScheta: get(item, "nomerScheta", ""),
        imyaScheta: get(item, "imyaScheta", ""),
        nachalniyOstatokDebit: Number(get(item, "nachalniyOstatok.debit", 0)),
        nachalniyOstatokKredit: Number(get(item, "nachalniyOstatok.kredit", 0)),
        oborotDebit: Number(get(item, "oborot.debit", 0)),
        borotKredit: Number(get(item, "oborot.kredit", 0)),
        konechniyOstatokDebit: Number(get(item, "konechniyOstatok.debit", 0)),
        konechniyOstatokKredit: Number(get(item, "konechniyOstatok.kredit", 0)),
      });
      counterRow++;
    });
    counterRow++;
    sheet.mergeCells(`A${counterRow + 3}`, `B${counterRow + 3}`);
    sheet.getCell(`A${counterRow + 3}`).value = "Итого:";
    sheet.getCell(`C${counterRow + 3}`).value = Number(
      get(data, "balanceList.itogo.NachalniyDebit", 0)
    );
    sheet.getCell(`D${counterRow + 3}`).value = Number(
      get(data, "balanceList.itogo.NachalniyKredit", 0)
    );
    sheet.getCell(`E${counterRow + 3}`).value = Number(
      get(data, "balanceList.itogo.OborotDebit", 0)
    );
    sheet.getCell(`F${counterRow + 3}`).value = Number(
      get(data, "balanceList.itogo.OborotKredit", 0)
    );
    sheet.getCell(`G${counterRow + 3}`).value = Number(
      get(data, "balanceList.itogo.KonechniyDebit", 0)
    );
    sheet.getCell(`H${counterRow + 3}`).value = Number(
      get(data, "balanceList.itogo.KonechniyKredit", 0)
    );
    // sheet.getRow(counterRow + 3).height = 25;
    // sheet.getRow(counterRow + 3).alignment = {
    //   vertical: "middle",
    //   horizontal: "center",
    // };
    const fontBoldCells = [
      "A1",
      "A" + (count + 2),
      "B" + (count + 2),
      "C" + (count + 2),
      "D" + (count + 2),
      "E" + (count + 2),
      "F" + (count + 2),
      "G" + (count + 2),
      "H" + (count + 3),
      "C" + (count + 3),
      "D" + (count + 3),
      "E" + (count + 3),
      "F" + (count + 3),
      "G" + (count + 3),
      "H" + (count + 3),
    ];
    const startCell: any = sheet.getCell(`A${count + 2}`);
    const endCell: any = sheet.getCell(`H${counterRow + count + 2}`);
    for (let row = startCell.row; row <= endCell.row; row++) {
      for (let col = startCell.col; col <= endCell.col; col++) {
        const cell = sheet.getCell(row, col);
        cell.border = {
          top: { style: "thin", color: { argb: "00000000" } }, // Replace 'FF0000FF' with your desired color
          left: { style: "thin", color: { argb: "00000000" } },
          bottom: { style: "thin", color: { argb: "00000000" } },
          right: { style: "thin", color: { argb: "00000000" } },
        };
      }
    }
    fontBoldCells.forEach((item: any) => {
      sheet.getCell(item).font = {
        size: 11,
        bold: true,
      };
    });
    //   sheet.eachCell({ includeEmpty: true }, (cell) => {
    //     cell.border = borderStyle;
    // });
    workbook.xlsx.writeBuffer().then((data: any) => {
      const blob = new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const url = window.URL.createObjectURL(blob);
      const anchor = document.createElement("a");
      anchor.href = url;
      anchor.download = `${EXCEL_SHEET}.xlsx`;
      anchor.click();
      window.URL.revokeObjectURL(url);
    });
  };
  const filter = useAppSelector(balanceSheetFilter);
  const fetchAllBalanceSheets = useCallback(() => {
    dispatch(fetchBalanceSheet(filter));
  }, [dispatch, filter]);
  // data
  const data = useAppSelector(balanceSheetList);

  const LOADING_TABLE = get(data, "loading", false);

  const data_M = useMemo(() => {
    const total = {
      imyaScheta: "Итого:",
      konechniyOstatok: {
        debit: get(data, "balanceList.itogo.KonechniyDebit"),
        kredit: get(data, "balanceList.itogo.KonechniyKredit"),
      },
      nachalniyOstatok: {
        debit: get(data, "balanceList.itogo.NachalniyDebit"),
        kredit: get(data, "balanceList.itogo.NachalniyKredit"),
      },
      nomerScheta: "",
      oborot: {
        debit: get(data, "balanceList.itogo.OborotDebit"),
        kredit: get(data, "balanceList.itogo.OborotKredit"),
      },
    };
    const result = {
      ...data,
      balanceList: {
        list: [...get(data, "balanceList.list", []), total],
      },
    };
    return result;
  }, [data]);

  // clear
  const handleClearFilter = () => {
    dispatch(clearBlancaSheet());
  };

  // change
  const handleChangeFilter = ({
    clear = false,
    value,
    name,
    ...params
  }: ParamsType) => {
    if (isEqual(get(filter, `${name}`, ""), value)) {
      return;
    }

    if (clear) {
      dispatch(changeBlanceSheet(null));
    }

    dispatch(filterBlancaSheet({ ...filter, name, value }));
  };

  const navigateToBlItem = useCallback(
    (env: any) => {
      navigate(Reports.REPORT_BALANCE_SHEET_ITEM, {
        state: { ...env, ...filter },
      });
    },
    [filter, navigate]
  );

  useEffect(() => {
    return () => {
      handleClearFilter();
    };
  }, [dispatch]);

  if (isAddForm) {
    return <Outlet />;
  }
  return (
    <Row>
      <Col xs={12}>
        <Content className={"min_height"}>
          <Row className="mb-16">
            <Col xs={12}>
              <Title sm bold>
                Обязательные параметры
              </Title>
            </Col>
          </Row>
          <Row>
            <Col xs={7}>
              <FlexBox gap={"10px"}>
                <DatePicker
                  placeholder="Дата начало"
                  value={get(filter, "fromDate", "")}
                  dateFormat="dd.MM.yyyy"
                  handleDate={(e) => {
                    handleChangeFilter({
                      name: "fromDate",
                      value: moment(e).format("YYYY-MM-DD"),
                      clear: true,
                    });
                  }}
                />
                <DatePicker
                  placeholder="Дата конец"
                  dateFormat="dd.MM.yyyy"
                  value={get(filter, "toDate", "")}
                  handleDate={(e) => {
                    handleChangeFilter({
                      name: "toDate",
                      value: moment(e).format("YYYY-MM-DD"),
                      clear: true,
                    });
                  }}
                />
              </FlexBox>
            </Col>
            <Col className={"text-right"} xs={5}>
              {!isEmpty(get(data, "balanceList.list", null)) && (
                <Button className="mr-8" grey handleClick={downloadExcel}>
                  Скачать
                </Button>
              )}
              <Button green handleClick={fetchAllBalanceSheets}>
                Сформировать
              </Button>
              <Button danger handleClick={handleClearFilter} className="ml-8">
                Сброс
              </Button>
            </Col>
            <Col xs={12} className={"mt-16"} style={{ padding: 0 }}>
              <hr />
            </Col>
          </Row>
          {!LOADING_TABLE ? (
            <>
              {data.balanceList !== null ? (
                <>
                  <ReportInfoTitle
                    filter={filter}
                    titleTxt={"Оборотно-сальдовая ведомость"}
                  />
                  {!isEmpty(get(data, "balanceList.list", [])) ? (
                    <BalanceSheetTable
                      filter={filter}
                      data={data}
                      onRowClick={navigateToBlItem}
                    />
                  ) : (
                    <NotFound />
                  )}
                </>
              ) : null}
            </>
          ) : (
            <TableContentLoader col={6} />
          )}
        </Content>
      </Col>
    </Row>
  );
};

export default BalanceSheetContainer;
