import { useAppDispatch, useAppSelector } from "app/hook";
import {
  clearRegisteredOrders,
  fetchOrderRegister,
} from "app/slices/documentSlices/orderRegisterSlice/orderRegisterSlice";
import { fetchSalesContracts } from "app/slices/documentSlices/registerSalesCntSlice/registerSalesCntSlice";
import React, { useRef } from "react";
import ReactToPrint from "react-to-print";
import {
  fetchNotFilledShipmentProduct,
  fetchShipmentProduct,
  fetchShipmentProductStat,
  fetchShipmentProductsById,
  fetchShipmentProductsTempById,
  handleAddSubjectsShipment,
  handleChangeItemSubjects,
  movementShipmentSubject,
  clearFormDataShipment,
} from "app/slices/documentSlices/shipmentProductSlices/shipmentProductSlices";
import {
  clearContractorsState,
  fetchAllCounterpart,
} from "app/slices/handbookSlices/counterpartSlices/counterpartSlices";
import {
  clearDeliveryAddressData,
  fetchAllDeliveryAddress,
} from "app/slices/handbookSlices/deleveryAddressSlices/deliveryAddressSlices";
import { fetchDrivers } from "app/slices/handbookSlices/driversSlices/driversSlices";
import { fetchProduct } from "app/slices/handbookSlices/productSlices/productSlices";
import { fetchRawMaterial } from "app/slices/handbookSlices/rawMaterialSlices/rawMaterialSlices";
import { fetchTrucks } from "app/slices/handbookSlices/truckSlices/truckSlices";
import { fetchTypeProduct } from "app/slices/handbookSlices/typeProductSlices/typeProdcutSlices";
import { fetchOwnSkladGP } from "app/slices/handbookSlices/wareHouseSlices/wareHouseSlices";
import {
  shipmentProductFilter,
  shipmentProductForm,
} from "app/states/documents";
import {
  contractorOptions,
  getSaleContractsSubjectData,
  loadingConterpart,
  loadingOrderData,
  orderRegisterOptions,
} from "app/states/documents/selectStates";
import {
  getDeliveryData,
  getDriversData,
  getSkladGP,
  getTerritoriesData,
  getTruckData,
  getWarHouseData,
  loadingDeliverAddress,
  loadingDrivers,
  loadingTruck,
} from "app/states/handbooks/selectStates";
import {
  BaseInput,
  BaseSelect,
  BaseTable,
  Button,
  Content,
  DatePicker,
  FlexBox,
  Form,
  FormButton,
  LoadingAddPages,
  ReactModal,
  Text,
  TextArea,
  Title,
} from "components";
import { TableWrapper } from "./styles/style";
import TableInput from "components/TableInput/TableInput";
import { useFormik } from "formik";
import { forEach, get, isEmpty, isEqual } from "lodash";
import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import { Col, Row } from "react-grid-system";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import DocumentServices from "services/apiServices/documents";
import { MESSAGES, PAGE, PROTECTED_TYPE } from "utilities/constants";

import styled from "styled-components";
import { DOCUMENT_KEY, HANDBOOK_KEY, MAIN_DEPARTMENT } from "routers/constants";
import HasAccess from "routers/HasAccess";
import { AddAddressModal } from "modules/statics/spravochnik/containers/DeliveryAddressesContainer";
import { shipmentProductSchema } from "utilities/schema/document/documentSchemas";
import axios from "axios";
import { NumberDocType } from "types/documentsType";
import { checkDateYear } from "utilities/helpers";
import { ProgressBar } from "primereact/progressbar";

const Template = styled.div`
  display: none;
  position: "absolute";
  z-index: -3;
  @media print {
    display: block; /* or any other display value you want for printing */
  }
`;

// Добавить Отгрузка готовой продукции
const AddShipmentProductContainer = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const territory = useAppSelector(getTerritoriesData);
  const skladOptions = useAppSelector(getSkladGP);
  const orderRegister = useAppSelector(orderRegisterOptions);
  const drivers = useAppSelector(getDriversData);
  const truck = useAppSelector(getTruckData);
  const getSaleContracts = useAppSelector(getSaleContractsSubjectData);
  const contractorDropdowns = useAppSelector(contractorOptions);
  const { state } = useLocation();
  const shipmentSubjects = useAppSelector(shipmentProductForm);
  const filter = useAppSelector(shipmentProductFilter);
  const getDeliveryAddress = useAppSelector(getDeliveryData);
  const [disable, setDisable] = useState(false);
  const [openAddressModal, setOpenAddressModal] = useState(false);
  const cancelDetails = axios.CancelToken.source();
  // loadings
  let LOADING = get(shipmentSubjects, "loading", false);
  let loadingConter = useAppSelector(loadingConterpart);
  let loadingOrder = useAppSelector(loadingOrderData);
  let loadingAddress = useAppSelector(loadingDeliverAddress);
  let isLoadingTruck = useAppSelector(loadingTruck);
  let isLoadingDriver = useAppSelector(loadingDrivers);

  const [loadingDetails, setLoadingDetails] = useState(false);
  //  on Click submit if success show print
  const reactToPrintRef = React.useRef<any>(null);

  const handlePrint = (text: string | null) => {
    if (reactToPrintRef.current && componentRef.current) {
      // Access the span element by class name
      const spanElement1 = componentRef?.current.querySelector(".change_span1");
      const spanElement2 = componentRef?.current.querySelector(".change_span2");

      if (spanElement1 && spanElement2) {
        // Change the text content of the span
        spanElement1.textContent = text;
        spanElement2.textContent = text;
      }
      // open alert
      reactToPrintRef.current?.handlePrint();
    }
  };
  const onSubmit = (values: any) => {
    const cntrcSubjects = get(
      shipmentSubjects,
      "data.otgruzkaGPDetails",
      []
    ).map((item: any) => ({
      tovarId: get(item, "tovarId", ""),
      edIzmId: get(item, "edIzmId", ""),
      kol: get(item, "kol", 0),
      vidTovaraId: get(item, "vidTovaraId", ""),
    }));

    let valuesToSbm: any = {};

    forEach(
      values,
      (value: any, key: any) =>
      (valuesToSbm[key] = [
        "nomerDoc",
        "kommentariya",
        "dataDoc",
        "marka",
      ].includes(key)
        ? value
        : get(value, "value", ""))
    );

    if (
      get(shipmentSubjects, "data.otgruzkaGPDetails", []).find(
        (item: any) => item.kol > 0
      )
    ) {
      try {
        DocumentServices.AddShipmentProduct({
          transactionTime: new Date(),
          resultCode: null,
          resultMsg: null,
          data: {
            ...valuesToSbm,
            dataDoc: !isEqual(get(values, "dataDoc", null), null)
              ? get(values, "dataDoc", "")
              : moment(new Date()).format("YYYY-MM-DD"),
            gosNomer: get(values, "avtoId.label", null)
              ? get(values, "avtoId.label", null)
              : get(values, "gosNomer", ""),
            imyaVoditeli: get(values, "voditeliId.label", null),
            otgruzkaGPDetails: cntrcSubjects,
          },
        }).then((res) => {
          setSubmitting(false);
          if (get(res, "status", "") == 200) {
            toast.success(MESSAGES.ADDED);
            const infoDoc = get(res, "data", null);
            if (infoDoc) {
              handlePrint(get(infoDoc, "data.nomerDoc"));
            }

            if (get(state, "id", false)) {
              DocumentServices.RemoveTempsShipmentProduct(
                get(state, "id", "")
              ).then((res) => {
                if (get(res, "status", "") == 200) {
                  dispatch(fetchNotFilledShipmentProduct());
                }
              });
            }
          }
        });
      } catch (err) {
        console.log("err", err);
      }
    } else {
      setSubmitting(false);
    }
  };

  const { values, setFieldValue, handleSubmit, setSubmitting, isSubmitting } =
    useFormik({
      enableReinitialize: true,
      initialValues: {
        ...get(shipmentSubjects, "data.formIntVls", {}),
        dataDoc: get(state, "id", null) ? get(shipmentSubjects, "data.formIntVls.dataDoc", '') : ''
      },
      // validationSchema: shipmentProductSchema,
      onSubmit,
    });
  const saveNotFullFilledForm = useCallback(() => {
    const cntrcSubjects = get(
      shipmentSubjects,
      "data.otgruzkaGPDetails",
      []
    ).map((item: any) => ({
      tovarId: get(item, "tovarId", ""),
      edIzmId: get(item, "edIzmId", ""),
      imyaEdIzm: get(item, "imyaEdIzm", ""),
      imyaTovar: get(item, "imyaTovar", ""),
      vidTovaraId: get(item, "vidTovaraId", ""),
      kol: get(item, "kol", ""),
    }));

    let valuesToSbm: any = {};

    forEach(
      values,
      (value: any, key: any) =>
      (valuesToSbm[key] = ["nomerDoc", "kommentariya", "dataDoc"].includes(
        key
      )
        ? value
        : get(value, "value", ""))
    );

    try {
      DocumentServices.AddNotFilledShipmentProduct({
        transactionTime: new Date(),
        resultCode: null,
        resultMsg: null,
        data: {
          ...valuesToSbm,
          id: get(state, "id", null),
          dataDoc: !isEqual(get(values, "dataDoc", null), null)
            ? get(values, "dataDoc", "")
            : moment(new Date()).format("YYYY-MM-DD"),
          imyaKontragent: get(values, "kontragentId.label", ""),
          imyaVoditeli: get(values, "voditeliId.label", ""),
          imyaSklada: get(values, "skladGPId.label", ""),
          nomerZakazNaryad: get(values, "zakazNaryadId.label", ""),
          imyaTerritorii: get(values, "territoriyaId.label", ""),
          nomerDogProdaja: get(values, "dogProdajaId.label", ""),
          gosNomer: get(values, "avtoId.label", ""),
          otgruzkaGPDetails: cntrcSubjects,
        },
      }).then((res) => {
        if (get(res, "status", "") == 200) {
          navigate(-1);
          dispatch(fetchNotFilledShipmentProduct());
          toast.success(MESSAGES.ADDED);
        }
      });
    } catch (err) {
      console.log("err", err);
    }
  }, [dispatch, navigate, shipmentSubjects, values]);
  //  in relese come
  const navigatedByReese = useCallback(() => {
    if (get(state, "zakazNaryadIdProps", {})) {
      const znOption = orderRegister.find(
        (item) => item.value == get(state, "zakazNaryadIdProps", {}).value
      );
      setFieldValue("dogProdajaId.value", get(znOption, "dogProdajaId", null));
      setFieldValue(
        "dogProdajaId.label",
        get(znOption, "imyaDogProdaja", null)
      );
      setFieldValue("territoriyaId.value", get(znOption, "territory", null));
      setFieldValue(
        "territoriyaId.label",
        get(znOption, "imyaTerritory", null)
      );
      setFieldValue(
        "sposobDostavkaId",
        get(znOption, "sposobDostavkaId", null)
      );
      setFieldValue(
        "addresaDostavkiId.label",
        get(znOption, "imyaAddresaDostavki", null)
      );
      setFieldValue(
        "addresaDostavkiId.value",
        get(znOption, "addresaDostavkiId", null)
      );
      setFieldValue(
        "dogProdajaDataDog",
        get(znOption, "dogProdajaDataDog", null)
      );
    }
  }, []);
  const handleCheckTemp = useCallback(() => {
    if (get(state, "id", false)) {
      if (get(state, "isTemp", false)) {
        dispatch(fetchShipmentProductsTempById(get(state, "id", "")));
      } else {
        dispatch(fetchShipmentProductsById(get(state, "id", "")));
        setDisable(true);
      }
    } else if (
      get(state, "kontragentIdProps", false) &&
      get(state, "zakazNaryadIdProps", {})
    ) {
      setFieldValue("kontragentId", get(state, "kontragentIdProps", {}));
      setFieldValue("zakazNaryadId", get(state, "zakazNaryadIdProps", {}));
      navigatedByReese();
    }
  }, [dispatch, state]);

  //car name  get by avto id
  const getGosNumber = useCallback(() => {
    if (get(values, "avtoId.value", null)) {
      const find = truck.find(
        (item) => item.value == get(values, "avtoId.value", 0)
      );
      setFieldValue("avtoId.label", get(find, "label", ""));
      setFieldValue("marka", get(find, "imya", ""));
    }
  }, [values.voditeliId, values.avtoId]);

  // get address by kontragent
  const getAddress = useCallback(() => {
    if (get(values, "kontragentId.value", null)) {
      dispatch(
        fetchAllDeliveryAddress({
          kontragentId: get(values, "kontragentId.value", null),
          all: true,
        })
      );
      dispatch(
        fetchOrderRegister({
          all: true,
          otmVipolneniya: 0,
          filterPage: "OTGRUZKA",
          kontragentId: get(values, "kontragentId.value", null),
        })
      );
    }
  }, [values.kontragentId]);

  // get items that will be brought when choosing the order register Id
  const getItemsOrder = useCallback(() => {
    if (get(values, "territoriyaId.value", null)) {
      const findTerritory = territory.find(
        (item) => item?.value === get(values, "territoriyaId.value", 0)
      );
      setFieldValue("skladGPId.value", get(findTerritory, "skladGPId", null));
      setFieldValue("skladGPId.label", get(findTerritory, "imyaSkladGP", null));
    }
    // only works when create new document
    if (get(values, "zakazNaryadId.value", null) && !get(state, "id", null)) {
      setLoadingDetails(true);
      DocumentServices.GetShipmentDetails({
        id: get(values, "zakazNaryadId.value", null),
        signal: cancelDetails.token,
      }).then(({ data }) => {
        setLoadingDetails(false);
        dispatch(handleAddSubjectsShipment(get(data, "data", [])));
      });
    }
  }, [get(values, "zakazNaryadId", "")]);
  //  get Document Number
  const getDocNumber = useCallback((date: NumberDocType) => {
    DocumentServices.GetShipmentNumber(date).then(({ data }) => {
      setFieldValue("nomerDoc", get(data, "data", ""));
      if (get(data, "transactionTime", "")) {
        const transactionTime = get(data, "transactionTime", "");
        const localMaxDate = moment.utc(transactionTime).add(5, 'hours').format("YYYY-MM-DD HH:mm");
        const localDate = moment.utc(transactionTime).add(5, 'hours').format("YYYY-MM-DD");
        setFieldValue("maxDate", localMaxDate);
        if (isEmpty(get(values, 'dataDoc', ''))) {
          setFieldValue("dataDoc", localDate);
        }
      }
    });
  }, [values]);

  //  after close or print button click navigate back
  const handleAfterPrint = () => {
    navigate("/");
    setSubmitting(false);
    navigate("documents/shipment-products", { replace: true });
    dispatch(fetchShipmentProduct(filter));
    dispatch(fetchShipmentProductStat());
  };

  useEffect(() => {
    getGosNumber();
  }, [getGosNumber]);

  useEffect(() => {
    handleCheckTemp();
  }, [handleCheckTemp]);

  useEffect(() => {
    if (!disable) {
      getItemsOrder();
    }
    return () => {
      cancelDetails.cancel();
    };
  }, [values.zakazNaryadId]);

  useEffect(() => {
    if (!disable) {
      getAddress();
    }
    return () => {
      dispatch(clearRegisteredOrders());
      dispatch(clearDeliveryAddressData());
    };
  }, [values.kontragentId]);

  useEffect(() => {
    if (!disable) {
      getDocNumber({});
      const cancelReq = dispatch(
        fetchAllCounterpart({ all: true, filterPage: "OTGRUZKA" })
      );
      const cancel1 = dispatch(fetchDrivers({ all: true }));
      const cancle2 = dispatch(fetchTrucks({ all: true }));
      return () => {
        cancelReq.abort();
        cancel1.abort();
        cancle2.abort();

        dispatch(clearFormDataShipment());
      };
    }
  }, []);
  const componentRef = useRef<HTMLDivElement | null>(null);
  const findShipment = !isEmpty(
    get(shipmentSubjects, "data.otgruzkaGPDetails", [])
  )
    ? get(shipmentSubjects, "data.otgruzkaGPDetails", [])?.filter(
      (item: any) => item?.vidTovaraId == 1
    )
    : [];
  console.log("first", get(shipmentSubjects, "data.formIntVls.dataDoc", ""))
  return (
    <Content className={"min_height"}>
      <ReactModal isOpen={openAddressModal}>
        <AddAddressModal
          handleCloseModal={() => setOpenAddressModal(false)}
          kontragentIdCore={get(values, "kontragentId.value", null)}
          afterSubmitAction={() =>
            dispatch(
              fetchAllDeliveryAddress({
                kontragentId: get(values, "kontragentId.value", null),
                all: true,
              })
            )
          }
        />
      </ReactModal>
      {isEqual(LOADING, false) ? (
        <>
          <Template ref={componentRef}>
            <div>
              <Row
                style={{
                  padding: "50px 20px 50px 20px",
                }}
              >
                <Col xs={4}>
                  <Text dark bold small>
                    {moment(
                      get(state, 'id', false) ? get(shipmentSubjects, "data.formIntVls.dataDoc", "") : get(values, "dataDoc", "")
                    ).format("DD.MM.YYYY")}
                    г.
                  </Text>{" "}
                  <br /> <br />
                  <Text dark bold small>
                    Согл.дог. №
                    <span style={{ textDecoration: "underline" }}>
                      {get(values, "dogProdajaId.value", null)
                        ? get(values, "dogProdajaId.label", "")
                        : get(
                          shipmentSubjects,
                          "data.formIntVls.dogProdajaId.label"
                        )}
                    </span>{" "}
                    <br />
                    от
                    <span style={{ textDecoration: "underline" }}>
                      {" "}
                      {get(values, "dogProdajaDataDog", null)
                        ? moment(get(values, "dogProdajaDataDog", "")).format(
                          "DD.MM.YYYY"
                        )
                        : moment(
                          get(
                            shipmentSubjects,
                            "data.formIntVls.dogProdajaDataDog",
                            ""
                          )
                        ).format("DD.MM.YYYY")}
                    </span>
                    г.
                  </Text>
                </Col>
                <Col
                  xs={4}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Text dark bold small>
                    НАКЛАДНАЯ №
                    <span
                      className="change_span1"
                      style={{ textDecoration: "underline" }}
                    >
                      {
                        get(values, "nomerDoc", false)
                        // ? get(values, "nomerDoc", false)
                        // : get(shipmentSubjects, "data.formIntVls.nomerDoc", "")
                      }
                    </span>
                  </Text>
                </Col>
                <Col xs={4}>
                  <Text dark bold small>
                    OLMOS BLOK <br />
                    ул. Тараккиёт, 8. <br />
                    тел.: (+998) 98-300-06-09 <br />
                  </Text>
                </Col>
                <Col xs={6} className="mt-16">
                  <TableWrapper>
                    <thead>
                      <tr>
                        <th colSpan={3} style={{ fontWeight: "bold" }}>
                          Товар
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Наименование</td>
                        <td>Ед.изм</td>
                        <td>Кол-во</td>
                      </tr>
                      {!isEmpty(
                        get(shipmentSubjects, "data.otgruzkaGPDetails", [])
                      ) &&
                        get(shipmentSubjects, "data.otgruzkaGPDetails", []).map(
                          (item: any, index: number) => {
                            if (Number(get(item, "kol", 0)))
                              return (
                                <tr key={index}>
                                  <td>{get(item, "imyaTovar", "")}</td>
                                  <td>{get(item, "imyaEdIzm", "")}</td>
                                  <td>{get(item, "kol", "")}</td>
                                </tr>
                              );
                          }
                        )}
                    </tbody>
                  </TableWrapper>
                  {get(values, "kommentariya", "") != "" && (
                    <div
                      className="mt-16"
                      style={{
                        height: "100%",
                      }}
                    >
                      <TableWrapper>
                        <tbody>
                          <tr>
                            <th
                              style={{
                                textAlign: "start",
                                height: "100%",
                              }}
                            >
                              <div>
                                <p>
                                  {get(values, "kommentariya", "")
                                    .split("\n")
                                    .map((line: any, index: number) => (
                                      <div key={index}>{line}</div>
                                    ))}
                                </p>
                              </div>
                            </th>
                          </tr>
                        </tbody>
                      </TableWrapper>
                    </div>
                  )}
                </Col>
                <Col xs={6} className="mt-16">
                  <TableWrapper>
                    <thead>
                      <tr>
                        <th colSpan={3} style={{ fontWeight: "bold" }}>
                          Транспортное средство
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Марка </td>
                        <td>Гос. номер </td>
                        <td>Водитель </td>
                      </tr>
                      <tr>
                        <td>
                          {get(values, "marka", null)
                            ? get(values, "marka", "")
                            : get(
                              shipmentSubjects,
                              "data.formIntVls.marka",
                              ""
                            )}
                        </td>
                        <td>
                          {get(values, "avtoId.label", false)
                            ? get(values, "avtoId.label", "")
                            : get(values, "gosNomer", "")}
                        </td>
                        <td>
                          {get(values, "voditeliId.label", null)
                            ? get(values, "voditeliId.label", "")
                            : get(
                              shipmentSubjects,
                              "data.formIntVls.voditeliId.label",
                              ""
                            )}
                        </td>
                      </tr>
                    </tbody>
                  </TableWrapper>
                  <div className="mt-16">
                    <TableWrapper>
                      <thead>
                        <tr>
                          <th colSpan={2} style={{ fontWeight: "bold" }}>
                            Кому
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Заказчик </td>
                          <td>Объект </td>
                        </tr>
                        <tr>
                          <td>
                            {get(values, "kontragentId.label", null)
                              ? get(values, "kontragentId.label", "")
                              : get(
                                shipmentSubjects,
                                "data.formIntVls.kontragentId.label",
                                ""
                              )}
                          </td>
                          <td>
                            {get(values, "addresaDostavkiId.label", null)
                              ? get(values, "addresaDostavkiId.label", "")
                              : get(
                                shipmentSubjects,
                                "data.formIntVls.addresaDostavkiId.label",
                                ""
                              )}
                          </td>
                        </tr>
                      </tbody>
                    </TableWrapper>
                  </div>
                </Col>
              </Row>
              <Row
                style={{
                  padding: "0px 20px 30px",
                }}
              >
                <Col xs={6}>
                  {findShipment?.length >= 1 && (
                    <TableWrapper>
                      <thead>
                        <tr>
                          <th colSpan={2} style={{ fontWeight: "bold" }}>
                            БРУ
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Время прибытия </td>
                          <td>Время убытия </td>
                        </tr>
                        <tr>
                          <td>
                            {get(
                              shipmentSubjects,
                              "data.formIntVls.createdAt",
                              null
                            )
                              ? moment(
                                get(
                                  shipmentSubjects,
                                  "data.formIntVls.createdAt",
                                  null
                                )
                              ).format("HH:mm:ss")
                              : moment(new Date()).format("HH:mm:ss")}
                          </td>
                          <td>{"ㅤ "}</td>
                        </tr>
                      </tbody>
                    </TableWrapper>
                  )}
                </Col>
                <Col xs={6}>
                  {findShipment?.length >= 1 && (
                    <TableWrapper>
                      <thead>
                        <tr>
                          <th colSpan={2} style={{ fontWeight: "bold" }}>
                            Объект
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Время прибытия </td>
                          <td>Время убытия </td>
                        </tr>
                        <tr>
                          <td>{"ㅤ"}</td>
                          <td>{"ㅤ"}</td>
                        </tr>
                      </tbody>
                    </TableWrapper>
                  )}
                </Col>
                <Col xs={6} className="mt-32">
                  <Text dark bold small>
                    Отпустил ________________________________________________
                  </Text>
                </Col>
                <Col xs={6} className="mt-32">
                  <Text dark bold small>
                    Получил _________________________________________________
                  </Text>
                </Col>
              </Row>
            </div>
            <div>
              <Row
                style={{
                  padding: "10px 20px 20px",
                }}
              >
                <Col xs={4}>
                  <Text dark bold small>
                    {moment(
                      get(shipmentSubjects, "data.formIntVls.dataDoc", "")
                    ).format("DD.MM.YYYY")}
                    г.
                  </Text>{" "}
                  <br /> <br />
                  <Text dark bold small>
                    Согл.дог. №
                    <span style={{ textDecoration: "underline" }}>
                      {get(values, "dogProdajaId.value", null)
                        ? get(values, "dogProdajaId.label", "")
                        : get(
                          shipmentSubjects,
                          "data.formIntVls.dogProdajaId.label"
                        )}
                    </span>{" "}
                    <br />
                    от
                    <span style={{ textDecoration: "underline" }}>
                      {" "}
                      {get(values, "dogProdajaDataDog", null)
                        ? moment(get(values, "dogProdajaDataDog", "")).format(
                          "DD.MM.YYYY"
                        )
                        : moment(
                          get(
                            shipmentSubjects,
                            "data.formIntVls.dogProdajaDataDog",
                            ""
                          )
                        ).format("DD.MM.YYYY")}
                    </span>
                    г.
                  </Text>
                </Col>
                <Col
                  xs={4}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Text dark bold small>
                    НАКЛАДНАЯ №
                    <span
                      className="change_span2"
                      style={{ textDecoration: "underline" }}
                    >
                      {get(values, "nomerDoc", "")}
                    </span>
                  </Text>
                </Col>
                <Col xs={4}>
                  <Text dark bold small>
                    OLMOS BLOK <br />
                    ул. Тараккиёт, 8. <br />
                    тел.: (+998) 98-300-06-09 <br />
                  </Text>
                </Col>
                <Col xs={6} className="mt-16">
                  <TableWrapper>
                    <thead>
                      <tr>
                        <th colSpan={3} style={{ fontWeight: "bold" }}>
                          Товар
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Наименование</td>
                        <td>Ед.изм</td>
                        <td>Кол-во</td>
                      </tr>
                      {!isEmpty(
                        get(shipmentSubjects, "data.otgruzkaGPDetails", [])
                      ) &&
                        get(shipmentSubjects, "data.otgruzkaGPDetails", []).map(
                          (item: any, index: number) => {
                            if (Number(get(item, "kol", 0)))
                              return (
                                <tr key={index}>
                                  <td>{get(item, "imyaTovar", "")}</td>
                                  <td>{get(item, "imyaEdIzm", "")}</td>
                                  <td>{get(item, "kol", "")}</td>
                                </tr>
                              );
                          }
                        )}
                    </tbody>
                  </TableWrapper>
                  {get(values, "kommentariya", "") != "" && (
                    <div
                      className="mt-16"
                      style={{
                        height: "100%",
                      }}
                    >
                      <TableWrapper>
                        <tbody>
                          <tr>
                            <th
                              style={{
                                textAlign: "start",
                                height: "100%",
                              }}
                            >
                              <div style={{}}>
                                <p>
                                  {get(values, "kommentariya", "")
                                    .split("\n")
                                    .map((line: any, index: number) => (
                                      <div key={index}>{line}</div>
                                    ))}
                                </p>
                              </div>
                            </th>
                          </tr>
                        </tbody>
                      </TableWrapper>
                    </div>
                  )}
                </Col>
                <Col xs={6} className="mt-16">
                  <TableWrapper>
                    <thead>
                      <tr>
                        <th colSpan={3} style={{ fontWeight: "bold" }}>
                          Транспортное средство
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Марка </td>
                        <td>Гос. номер </td>
                        <td>Водитель </td>
                      </tr>
                      <tr>
                        <td>
                          {get(values, "marka", null)
                            ? get(values, "marka", "")
                            : get(
                              shipmentSubjects,
                              "data.formIntVls.marka",
                              ""
                            )}
                        </td>
                        <td>
                          {get(values, "avtoId.label", false)
                            ? get(values, "avtoId.label", "")
                            : get(values, "gosNomer", "")}
                        </td>
                        <td>
                          {get(values, "voditeliId.label", null)
                            ? get(values, "voditeliId.label", "")
                            : get(
                              shipmentSubjects,
                              "data.formIntVls.voditeliId.label",
                              ""
                            )}
                        </td>
                      </tr>
                    </tbody>
                  </TableWrapper>
                  <div className="mt-16">
                    <TableWrapper>
                      <thead>
                        <tr>
                          <th colSpan={2} style={{ fontWeight: "bold" }}>
                            Кому
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Заказчик </td>
                          <td>Объект </td>
                        </tr>
                        <tr>
                          <td>
                            {get(values, "kontragentId.label", null)
                              ? get(values, "kontragentId.label", "")
                              : get(
                                shipmentSubjects,
                                "data.formIntVls.kontragentId.label",
                                ""
                              )}
                          </td>
                          <td>
                            {get(values, "addresaDostavkiId.label", null)
                              ? get(values, "addresaDostavkiId.label", "")
                              : get(
                                shipmentSubjects,
                                "data.formIntVls.addresaDostavkiId.label",
                                ""
                              )}
                          </td>
                        </tr>
                      </tbody>
                    </TableWrapper>
                  </div>
                </Col>
              </Row>
              <Row
                style={{
                  padding: "0px 20px 30px",
                }}
              >
                <Col xs={6}>
                  {findShipment?.length >= 1 && (
                    <TableWrapper>
                      <thead>
                        <tr>
                          <th colSpan={2} style={{ fontWeight: "bold" }}>
                            БРУ
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Время прибытия </td>
                          <td>Время убытия </td>
                        </tr>
                        <tr>
                          <td>
                            {get(
                              shipmentSubjects,
                              "data.formIntVls.createdAt",
                              null
                            )
                              ? moment(
                                get(
                                  shipmentSubjects,
                                  "data.formIntVls.createdAt",
                                  null
                                )
                              ).format("HH:mm:ss")
                              : moment(new Date()).format("HH:mm:ss")}
                          </td>
                          <td>{"ㅤ "}</td>
                        </tr>
                      </tbody>
                    </TableWrapper>
                  )}
                </Col>
                <Col xs={6}>
                  {findShipment?.length >= 1 && (
                    <TableWrapper>
                      <thead>
                        <tr>
                          <th colSpan={2} style={{ fontWeight: "bold" }}>
                            Объект
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Время прибытия </td>
                          <td>Время убытия </td>
                        </tr>
                        <tr>
                          <td>{"ㅤ"}</td>
                          <td>{"ㅤ"}</td>
                        </tr>
                      </tbody>
                    </TableWrapper>
                  )}
                </Col>
                <Col xs={6} className="mt-32">
                  <Text dark bold small>
                    Отпустил ________________________________________________
                  </Text>
                </Col>
                <Col xs={6} className="mt-32">
                  <Text dark bold small>
                    Получил _________________________________________________
                  </Text>
                </Col>
              </Row>
            </div>
          </Template>
          <Form onSubmit={handleSubmit}>
            <Row>
              <Col xs={12}>
                <FlexBox justify={"space-between"} style={{ width: "100%" }}>
                  <Title bold sm>
                    Реквизиты
                  </Title>
                  <Button
                    green
                    handleClick={saveNotFullFilledForm}
                    disabled={disable}
                  >
                    Сохранить
                  </Button>
                </FlexBox>
              </Col>
              <Col xs={12} style={{ padding: 0 }} className={"my-16"}>
                <hr />
              </Col>
            </Row>
            <Row>
              <Col xs={6}>
                <FlexBox>
                  <Col xs={4} style={{ paddingLeft: 0 }}>
                    <Title>
                      Номер<span className="required_point">*</span>
                    </Title>
                  </Col>
                  <Col xs={8} className="form-input-date_picker">
                    <Row>
                      <Col xs={6}>
                        <BaseInput
                          id={"nomerDoc"}
                          value={get(values, "nomerDoc", "")}
                        />
                      </Col>
                      <Col xs={6} style={{ paddingLeft: 0 }}>
                        <FlexBox>
                          <Text>от:</Text>
                          <DatePicker
                            id={"dataDoc"}
                            value={get(values, "dataDoc", "")}
                            handleDate={(e: any) => {
                              getDocNumber({
                                year: checkDateYear({
                                  currentYear: get(values, "dataDoc", null),
                                  checkYear: moment(e).format("YYYY-MM-DD"),
                                }),
                              });
                              setFieldValue(
                                "dataDoc",
                                moment(e).format("YYYY-MM-DD")
                              );
                            }}
                            // maxDate={new Date()}
                            maxDate={new Date(get(values, 'maxDate', new Date()))}
                            dateFormat="dd.MM.yyyy"
                            disabled={disable}
                          />
                        </FlexBox>
                      </Col>
                    </Row>
                  </Col>
                </FlexBox>
              </Col>
            </Row>
            <Row>
              <Col xs={6}>
                <Row align="center" className="mt-24">
                  <Col xs={4} style={{ paddingRight: 0 }}>
                    <Title>
                      Контрагент<span className="required_point">*</span>
                    </Title>
                  </Col>
                  <Col xs={8}>
                    <BaseSelect
                      id={"kontragentId"}
                      defaultValue={get(values, "kontragentId", {})}
                      placeholder={get(values, "kontragentId.label", "")}
                      isSearchable
                      isLoading={loadingConter}
                      value={get(values, "kontragentId.value", "")}
                      options={contractorDropdowns}
                      handleValueLabel={(e: any) => {
                        setFieldValue("kontragentId", e);
                        if (!isEqual(e, get(values, "kontragentId", false))) {
                          setFieldValue("zakazNaryadId.value", null);
                          setFieldValue("zakazNaryadId.label", null);
                          setFieldValue("dogProdajaId.value", null);
                          setFieldValue("dogProdajaId.label", null);
                          setFieldValue("skladGPId.value", null);
                          setFieldValue("skladGPId.label", null);
                          setFieldValue("territoriyaId.value", null);
                          setFieldValue("territoriyaId.label", null);
                          setFieldValue("sposobDostavkaId.value", null);
                          setFieldValue("sposobDostavkaId.label", null);
                          setFieldValue("addresaDostavkiId.value", null);
                          setFieldValue("addresaDostavkiId.label", null);
                          dispatch(handleAddSubjectsShipment(null));
                        }
                      }}
                      isDisabled={
                        disable ||
                        loadingOrder ||
                        !isEmpty(get(state, "kontragentIdProps", null))
                      }
                    />
                  </Col>
                </Row>
                <Row align="center" className="mt-24">
                  <Col xs={4}>
                    <Title>
                      Заказ-наряд<span className="required_point">*</span>
                    </Title>
                  </Col>
                  <Col xs={8}>
                    <BaseSelect
                      id={"zakazNaryadId"}
                      defaultValue={get(values, "zakazNaryadId", "")}
                      isSearchable
                      isLoading={loadingOrder}
                      value={get(values, "zakazNaryadId.value", "")}
                      options={orderRegister}
                      handleValueLabel={(e: any) => {
                        setFieldValue("zakazNaryadId", e);
                        setFieldValue(
                          "dogProdajaId.value",
                          get(e, "dogProdajaId", null)
                        );
                        setFieldValue(
                          "dogProdajaId.label",
                          get(e, "imyaDogProdaja", null)
                        );
                        setFieldValue(
                          "territoriyaId.value",
                          get(e, "territory", null)
                        );
                        setFieldValue(
                          "territoriyaId.label",
                          get(e, "imyaTerritory", null)
                        );
                        setFieldValue(
                          "sposobDostavkaId",
                          get(e, "sposobDostavkaId", null)
                        );
                        setFieldValue(
                          "addresaDostavkiId.label",
                          get(e, "imyaAddresaDostavki", null)
                        );
                        setFieldValue(
                          "addresaDostavkiId.value",
                          get(e, "addresaDostavkiId", null)
                        );
                        setFieldValue(
                          "dogProdajaDataDog",
                          get(e, "dogProdajaDataDog", null)
                        );
                      }}
                      isDisabled={
                        disable ||
                        !isEmpty(get(state, "zakazNaryadIdProps", null))
                      }
                    />
                  </Col>
                </Row>
                <Row align="center" className="mt-24">
                  <Col xs={4} style={{ paddingRight: 0 }}>
                    <Title>
                      Договор<span className="required_point">*</span>
                    </Title>
                  </Col>

                  <Col xs={8}>
                    <BaseSelect
                      id={"dogProdajaId"}
                      isSearchable
                      defaultValue={get(values, "dogProdajaId", "")}
                      value={get(values, "dogProdajaId.value", "")}
                      options={getSaleContracts}
                      handleValueLabel={(e: any) => {
                        setFieldValue("dogProdajaId.value", e);
                      }}
                      isDisabled={true}
                    />
                  </Col>
                </Row>

                <Row align="center" className="mt-24">
                  <Col xs={4}>
                    <Title>
                      Территория<span className="required_point">*</span>
                    </Title>
                  </Col>
                  <Col xs={8}>
                    <BaseSelect
                      id={"territoriyaId"}
                      placeholder={get(values, "territoriyaId.label", "")}
                      isSearchable
                      value={get(values, "territoriyaId.value", "")}
                      options={territory}
                      handleValueLabel={(e: any) => {
                        setFieldValue("territoriyaId", e);
                      }}
                      isDisabled={true}
                    />
                  </Col>
                </Row>
                <TextArea
                  id={"kommentariya"}
                  label={"Коментарии"}
                  disabled={disable}
                  value={get(values, "kommentariya", "")}
                  handleInput={(e: string) => {
                    setFieldValue("kommentariya", e);
                  }}
                />
              </Col>
              {/* right */}
              <Col xs={6}>
                <Row align="center" className="mt-24">
                  <Col xs={4} style={{ paddingRight: 0 }}>
                    <Title>
                      Склад готовой продукции
                      <span className="required_point">*</span>
                    </Title>
                  </Col>
                  <Col xs={8}>
                    <BaseSelect
                      id={"skladGPId"}
                      defaultValue={get(values, "skladGPId", "")}
                      isSearchable
                      value={get(values, "skladGPId.value", "")}
                      options={skladOptions}
                      handleValueLabel={(e: any) => {
                        setFieldValue("skladGPId", e);
                      }}
                      isDisabled={true}
                    />
                  </Col>
                </Row>
                <Row align="center" className="mt-24">
                  <Col xs={4} style={{ paddingRight: 0 }}>
                    <Title>
                      Адрес доставки<span className="required_point">*</span>
                    </Title>
                  </Col>
                  <Col xs={8}>
                    <HasAccess>
                      {({ userCan }: any) =>
                        userCan({
                          permission: `${HANDBOOK_KEY.ADDRESS_DOSTAVKI}_CREATE`,
                          page: HANDBOOK_KEY.ADDRESS_DOSTAVKI,
                          department: MAIN_DEPARTMENT.HANDBOOK,
                          type: PROTECTED_TYPE.PERMISSIONS,
                        }) ? (
                          <BaseSelect
                            id={"addresaDostavkiId"}
                            isSearchable
                            isLoading={loadingAddress}
                            value={get(values, "addresaDostavkiId.value", "")}
                            options={
                              get(values, "kontragentId.value", null)
                                ? getDeliveryAddress
                                : []
                            }
                            defaultValue={get(values, "addresaDostavkiId", {})}
                            isCustomMenu={
                              get(values, "kontragentId.value", null)
                                ? true
                                : false
                            }
                            menuButtonTitle={"+ Добавить"}
                            handleAddNewOption={() => setOpenAddressModal(true)}
                            handleValueLabel={(e: any) => {
                              setFieldValue("addresaDostavkiId", e);
                            }}
                            isDisabled={disable}
                          />
                        ) : (
                          <BaseSelect
                            id={"addresaDostavkiId"}
                            isSearchable
                            isLoaidng={loadingAddress}
                            value={get(values, "addresaDostavkiId.value", "")}
                            options={getDeliveryAddress}
                            handleValueLabel={(e: any) => {
                              setFieldValue("addresaDostavkiId", e);
                            }}
                            isDisabled={disable}
                          />
                        )
                      }
                    </HasAccess>
                  </Col>
                </Row>
                {Number(get(values, "sposobDostavkaId", 1)) === 2 ? (
                  <Row align="center" className="mt-24">
                    <Col xs={4}>
                      <Title>
                        Водитель<span className="required_point">*</span>
                      </Title>
                    </Col>
                    <Col xs={8}>
                      <BaseInput
                        id={"voditeliId"}
                        value={get(values, "voditeliId.label", "")}
                        handleInput={(e: string) => {
                          setFieldValue("voditeliId.label", e);
                        }}
                        disabled={disable}
                      />
                    </Col>
                  </Row>
                ) : (
                  <Row align="center" className="mt-24">
                    <Col xs={4}>
                      <Title>
                        Водитель<span className="required_point">*</span>
                      </Title>
                    </Col>
                    <Col xs={8}>
                      <BaseSelect
                        id={"voditeliId"}
                        defaultValue={get(values, "voditeliId", "")}
                        isSearchable
                        value={get(values, "voditeliId.value", "")}
                        options={drivers}
                        isLoading={isLoadingDriver}
                        handleValueLabel={(e: any) => {
                          setFieldValue("voditeliId", e);
                          setFieldValue("avtoId.value", get(e, "avto", null));
                        }}
                        isDisabled={disable}
                      />
                    </Col>
                  </Row>
                )}
                <Row align="center" className="mt-24">
                  <Col xs={4} style={{ paddingRight: 0 }}>
                    <Title>
                      Автомобиль<span className="required_point">*</span>
                    </Title>
                  </Col>
                  <Col xs={8}>
                    {Number(get(values, "sposobDostavkaId", 1)) === 2 ? (
                      <BaseInput
                        id={"gosNomer"}
                        value={get(values, "gosNomer", "")}
                        handleInput={(e: string) => {
                          setFieldValue("gosNomer", e);
                        }}
                        disabled={disable}
                      />
                    ) : (
                      <BaseSelect
                        id={"avtoId"}
                        defaultValue={get(values, "avtoId", "")}
                        isSearchable
                        value={get(values, "avtoId.value", "")}
                        isLoading={isLoadingTruck}
                        options={truck}
                        handleValueLabel={(e: any) => {
                          setFieldValue("avtoId", e);
                          setFieldValue("gosNomer", get(e, "label", null));
                        }}
                        isDisabled={disable}
                      />
                    )}
                  </Col>
                </Row>
              </Col>
            </Row>

            <Row className="mt-24" justify={"between"}>
              <Col xs={12} className="align-center">
                <FlexBox justify={"space-between"} align={"center"}>
                  <Title bold sm>
                    Табличная часть<span className="required_point">*</span>
                  </Title>
                </FlexBox>
              </Col>
              <Col xs={12} style={{ padding: 0 }} className={"my-16"}>
                {loadingDetails ? (
                  <ProgressBar
                    color="#5899f5"
                    mode="indeterminate"
                    style={{ height: "2px" }}
                  ></ProgressBar>
                ) : (
                  <hr />
                )}
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <Col>
                  <BaseTable
                    tableHeader={[
                      "№",
                      "Товар",
                      "Единица измерения",
                      "Количество",
                    ]}
                  >
                    {!loadingDetails ? (
                      <>
                        {!isEmpty(
                          get(shipmentSubjects, "data.otgruzkaGPDetails", [])
                        ) ? (
                          get(
                            shipmentSubjects,
                            "data.otgruzkaGPDetails",
                            []
                          ).map((item: any, index: number) => (
                            <tr key={index + 1}>
                              <td>{index + 1}</td>
                              <td>{get(item, "imyaTovar", "")}</td>
                              <td>{get(item, "imyaEdIzm", "")}</td>

                              <td style={{ padding: 0 }}>
                                <TableInput
                                  maxKol={get(item, "maxKol", null)}
                                  handleOnChange={(value: any) => {
                                    dispatch(
                                      handleChangeItemSubjects({
                                        id: index,
                                        name: "kol",
                                        value,
                                      })
                                    );
                                  }}
                                  kol={get(item, "kol", 0)}
                                  disabled={disable}
                                />
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan={5} style={{ fontWeight: "bold" }}>
                              Нет Данных
                            </td>
                          </tr>
                        )}
                      </>
                    ) : (
                      <tr>
                        <td colSpan={7}>Загрузка...</td>
                      </tr>
                    )}
                  </BaseTable>
                </Col>
              </Col>
            </Row>
            <Row className={"mb-16 mt-32 text-end"}>
              <Col xs={12}>
                {disable && (
                  <ReactToPrint
                    trigger={() => {
                      return (
                        <Button dark className={"mr-16"}>
                          Печать
                        </Button>
                      );
                    }}
                    content={() => componentRef.current}
                  />
                )}
                <ReactToPrint
                  ref={reactToPrintRef}
                  onAfterPrint={handleAfterPrint}
                  content={() => componentRef.current}
                />
                <HasAccess>
                  {({ userCan }: any) =>
                    userCan({
                      permission: `${DOCUMENT_KEY.OTGRUZKA_GP}_CREATE`,
                      page: DOCUMENT_KEY.OTGRUZKA_GP,
                      department: MAIN_DEPARTMENT.DOCUMENTS,
                      type: PROTECTED_TYPE.PERMISSIONS,
                    }) && (
                      <FormButton
                        disabled={disable}
                        loading={isSubmitting}
                        handleClick={handleSubmit}
                      >
                        {PAGE.ADD_AND_PRINT}
                      </FormButton>
                    )
                  }
                </HasAccess>
                <Button danger handleClick={() => navigate(-1)}>
                  {PAGE.CLOSE}
                </Button>
              </Col>
            </Row>
          </Form>
        </>
      ) : (
        <LoadingAddPages />
      )}
    </Content>
  );
};

export default AddShipmentProductContainer;
