import { useAppDispatch, useAppSelector } from "app/hook";
import {
  clearRegisterOrderForm,
  clearRegisteredOrders,
  fetchOrderRegister,
} from "app/slices/documentSlices/orderRegisterSlice/orderRegisterSlice";
import {
  fetchNotFilledSalesProduct,
  fetchSalesProduct,
  fetchSalesProductsById,
  fetchSalesProductsByTempId,
  fetchSalesProductStat,
  handleAddSubjectsSales,
  handleChangeItemSubjectsSalesProduct,
  handleClearableFieldsItem,
} from "app/slices/documentSlices/salesProductSlices/salesProductSlices";
import { fetchAllCounterpart } from "app/slices/handbookSlices/counterpartSlices/counterpartSlices";
import { salesProductFilter, salesProductForm } from "app/states/documents";
import {
  contractorOptions,
  getSaleContractsSubjectData,
  loadingConterpart,
  loadingOrderData,
  orderRegisterOptions,
} from "app/states/documents/selectStates";
import { getPaymentMethodData } from "app/states/enumerations/selectStates";
import {
  getDiscountData,
  getTerritoriesData,
  getWarHouseData,
} from "app/states/handbooks/selectStates";
import {
  BaseInput,
  BaseSelect,
  BaseTable,
  Button,
  Content,
  DatePicker,
  FlexBox,
  Form,
  FormButton,
  FormInput,
  LoadingAddPages,
  Text,
  TextArea,
  Title,
} from "components";
import { useFormik } from "formik";
import { debounce, forEach, get, isEmpty, isEqual } from "lodash";
import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import { Col, Row } from "react-grid-system";
import { NumericFormat } from "react-number-format";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import HasAccess from "routers/HasAccess";
import { DOCUMENT_KEY, MAIN_DEPARTMENT } from "routers/constants";
import DocumentServices from "services/apiServices/documents";
import { MESSAGES, PAGE, PROTECTED_TYPE } from "utilities/constants";
import BaseMarkInput from "components/BaseMarkInput/BaseMarkInput";
import axios from "axios";
import { fetchDiscounts } from "app/slices/handbookSlices/discountsSlices/discountSlices";
import { NumberDocType } from "types/documentsType";
import { checkDateYear, textFormatter } from "utilities/helpers";
import { ProgressBar } from "primereact/progressbar";
import { useDebounce, useDebouncedCallback } from "use-debounce";
type StateType = {
  tovarId?: number | string | null;
  skidkaId?: number | string | null;
  additionalSumma?: number | string | null;
  kol?: number | string | null;
  contextKol?: number | string;
};
// Добавить Реализация готовой продукции
const AddSalesProductContainer = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const territory = useAppSelector(getTerritoriesData);
  const skladOptions = useAppSelector(getWarHouseData);
  const orderRegister = useAppSelector(orderRegisterOptions);
  const getSaleContracts = useAppSelector(getSaleContractsSubjectData);
  const paymentMethod = useAppSelector(getPaymentMethodData);
  const contractorDropdowns = useAppSelector(contractorOptions);
  const { state } = useLocation();
  const salesProductSubjects = useAppSelector(salesProductForm);
  const filter = useAppSelector(salesProductFilter);
  const [disable, setDisable] = useState(false);
  const discountPro = useAppSelector(getDiscountData);
  const cancelDetails = axios.CancelToken.source();
  const [tipDogovor, setTipDogovor] = useState<number | null>(0);
  const [kolLimitList, setKolLimitList] = useState([]);

  // loaders
  let LOADING = get(salesProductSubjects, "loading", false);
  let loadingKontragent = useAppSelector(loadingConterpart);
  let loadingOrder = useAppSelector(loadingOrderData);
  const [loadingDetails, setLoadingDetails] = useState(false);
  const onSubmit = (values: any) => {
    let valuesToSbm: any = {};

    forEach(
      values,
      (value: any, key: any) =>
      (valuesToSbm[key] = ["nomerDoc", "kommentariya"].includes(key)
        ? value
        : get(value, "value", ""))
    );

    try {
      DocumentServices.AddSalesProduct({
        transactionTime: new Date(),
        resultCode: null,
        resultMsg: null,
        data: {
          ...valuesToSbm,
          dataDoc: !isEqual(get(values, "dataDoc", null), null)
            ? get(values, "dataDoc", "")
            : moment(new Date()).format("YYYY-MM-DD"),
          realizatsiyaGPDetails: get(
            salesProductSubjects,
            "data.realizatsiyaGPDetails",
            []
          ),
        },
      }).then((res) => {
        setSubmitting(false);
        if (get(res, "status", "") == 200) {
          navigate(-1);
          dispatch(fetchSalesProduct(filter));
          dispatch(fetchSalesProductStat());
          toast.success(MESSAGES.ADDED);

          if (get(state, "id", false)) {
            DocumentServices.RemoveTempsSalesProduct(get(state, "id", "")).then(
              (res) => {
                if (get(res, "status", "") == 200) {
                  dispatch(fetchNotFilledSalesProduct());
                }
              }
            );
          }
        }
      });
    } catch (err) {
      console.log("err", err);
    }
  };

  const { values, setFieldValue, handleSubmit, setSubmitting, isSubmitting } =
    useFormik({
      enableReinitialize: true,
      initialValues: {
        ...get(salesProductSubjects, "data.formIntVls", {}),
        dataDoc: get(state, "id", false) ? get(salesProductSubjects, "data.formIntVls.dataDoc", "") : '',
      },
      onSubmit,
    });

  const saveNotFullFilledForm = useCallback(() => {
    let valuesToSbm: any = {};

    forEach(values, (value: any, key: any) => {
      valuesToSbm[key] = ["nomerDoc", "kommentariya"].includes(key)
        ? value
        : get(value, "value", "");
    });
    const requestData = {
      ...valuesToSbm,
      id: get(state, "id", null),
      dataDoc: !isEqual(get(values, "dataDoc", null), null)
        ? get(values, "dataDoc", "")
        : moment(new Date()).format("YYYY-MM-DD"),
      imyaKontragent: get(values, "kontragentId.label", ""),
      imyaTipDogovora: get(values, "sexaId.label", ""),
      nomerZakazNaryad: get(values, "zakazNaryadId.label", ""),
      nomerDogProdaja: get(values, "dogProdajaId.label", ""),
      imyaSposobOplati: get(values, "sposobOplatiId.label", ""),
      imyaSkladaGP: get(values, "skladGPId.label", ""),
      dataIsp: get(values, "dataIsp", ""),
      imyaTerritoriya: get(values, "territoriyaId.label", ""),
      realizatsiyaGPDetails: get(
        salesProductSubjects,
        "data.realizatsiyaGPDetails",
        []
      ),
    };
  }, [dispatch, navigate, salesProductSubjects, values]);

  const getDocNumber = useCallback((date: NumberDocType) => {
    DocumentServices.GetSalesNumber(date).then(({ data }) => {
      setFieldValue("nomerDoc", get(data, "data", ""));
      if (get(data, "transactionTime", "")) {
        const transactionTime = get(data, "transactionTime", "");
        const localMaxDate = moment.utc(transactionTime).add(5, 'hours').format("YYYY-MM-DD HH:mm");
        const localDate = moment.utc(transactionTime).add(5, 'hours').format("YYYY-MM-DD");
        setFieldValue("maxDate", localMaxDate);
        if (isEmpty(get(values, 'dataDoc', ''))) {
          setFieldValue("dataDoc", localDate);
        }
      }
    });
  }, [values]);
  const handleCheckTemp = useCallback(() => {
    if (get(state, "id", false)) {
      if (get(state, "isTemp", false)) {
        dispatch(fetchSalesProductsByTempId(get(state, "id", "")));
      } else {
        dispatch(fetchSalesProductsById(get(state, "id", "")));
        setDisable(true);
      }
    } else {
      getDocNumber({});
    }
  }, [dispatch, state]);
  console.log("state", state)
  const getSkladGp = useCallback(() => {
    if (get(values, "territoriyaId.value", null)) {
      const findTerritory = territory.find(
        (item: any) =>
          get(item, "value", 0) === get(values, "territoriyaId.value", 0)
      );
      setFieldValue("skladGPId.value", get(findTerritory, "skladGPId", null));
      setFieldValue("skladGPId.label", get(findTerritory, "imyaSkladGP", null));
    }
  }, [values.territoriyaId, setFieldValue]);

  //  change prodtset
  const debounced = useDebouncedCallback((props) => {
    if (
      get(values, "zakazNaryadId.value", null) &&
      isEmpty(get(state, "id", null))
    ) {
      DocumentServices.ChangeDiscountProduct({
        zakazNaryadId: get(values, "zakazNaryadId.value", ""),
        tovarId: get(props, "tovarId", null),
        skidkaId: get(props, "skidkaId", 1),
        kol: get(props, "kol", 0),
        additionalSumma: get(props, "additionalSumma", null),
      })
        .then((res) => {
          if (res.status == 200) {
            dispatch(
              handleChangeItemSubjectsSalesProduct({
                id: get(props, "tovarId", 0),
                data: get(res, "data.data", null),
              })
            );
          }
        })
        .catch((err) => console.log(err));
    }
  }, 500);

  const handleChangeSkida = useCallback(
    ({
      kol,
      skidkaId,
      tovarId,
      additionalSumma,
    }: {
      kol: number;
      skidkaId: number;
      tovarId: number | string;
      additionalSumma: number;
    }) => {
      if (
        get(values, "zakazNaryadId.value", null) &&
        isEmpty(get(state, "id", null))
      ) {
        DocumentServices.ChangeDiscountProduct({
          zakazNaryadId: get(values, "zakazNaryadId.value", ""),
          tovarId: tovarId,
          skidkaId: skidkaId,
          kol: kol,
          additionalSumma: additionalSumma,
        })
          .then((res) => {
            if (res.status == 200) {
              dispatch(
                handleChangeItemSubjectsSalesProduct({
                  id: tovarId,
                  data: get(res, "data.data", null),
                })
              );
            }
          })
          .catch((err) => console.log(err));
      }
    },
    [state, values]
  );
  //  function get all material Prices
  let sumWithInitial = get(
    salesProductSubjects,
    "data.realizatsiyaGPDetails",
    []
  ).reduce(
    (accumulator: any, currentValue: any) => accumulator + currentValue.vsego,
    0
  );
  const getDetails = useCallback(() => {
    if (get(values, "zakazNaryadId.value", null) && isEqual(disable, false)) {
      // try get details
      try {
        setLoadingDetails(true);
        DocumentServices.GetSalesProductDetails({
          zakazNaryadId: get(values, "zakazNaryadId.value", null),
          signal: cancelDetails.token,
        }).then((res) => {
          setLoadingDetails(false);
          if (res.status == 200) {
            setKolLimitList(
              get(res, "data.data", []).map((item: any) => item.kol) // make details limit kol  (max kol in details item)
            );
            dispatch(handleAddSubjectsSales(get(res, "data.data", []))); // push store details list
          }
        });
      } catch (error) {
        console.log(error);
      }
    }
  }, [values.zakazNaryadId]);

  useEffect(() => {
    handleCheckTemp();
  }, [handleCheckTemp]);

  useEffect(() => {
    if (get(values, "kontragentId.value", null) && !get(state, "id", null)) {
      const cancelReq = dispatch(
        fetchOrderRegister({
          all: true,
          otmVipolneniya: 0,
          filterPage: "REALIZATSIYA",
          kontragentId: get(values, "kontragentId.value", null),
        })
      );

      return () => {
        cancelReq.abort();
        dispatch(clearRegisteredOrders());
      };
    }
  }, [values.kontragentId]);

  useEffect(() => {
    getSkladGp();
  }, [getSkladGp]);

  useEffect(() => {
    if (!get(state, "id", null)) {
      getDetails();
    }
    return () => {
      cancelDetails.cancel();
    };
  }, [values.zakazNaryadId]);

  useEffect(() => {
    if (isEmpty(get(state, "id", null))) {
      const cancelReq = dispatch(
        fetchAllCounterpart({ filterPage: "REALIZATSIYA", all: true })
      );
      let cancelReq2 = dispatch(
        fetchDiscounts({ all: true, signal: cancelDetails.token })
      );

      return () => {
        cancelReq.abort();
        cancelReq2.abort();
      };
    }
  }, []);
  useEffect(() => {
    return () => {
      dispatch(clearRegisterOrderForm());
    };
  }, []);

  return (
    <Content className={"min_height"}>
      {isEqual(LOADING, false) ? (
        <Form onSubmit={handleSubmit}>
          <Row>
            <Col xs={12}>
              <FlexBox justify={"space-between"} style={{ width: "100%" }}>
                <Title bold sm>
                  Реквизиты
                </Title>
                <Button
                  green
                  handleClick={saveNotFullFilledForm}
                  disabled={disable}
                >
                  Сохранить
                </Button>
              </FlexBox>
            </Col>
            <Col xs={12} style={{ padding: 0 }} className={"my-16"}>
              <hr />
            </Col>
          </Row>
          <Row>
            <Col xs={6}>
              <Row align="center">
                <Col xs={4}>
                  <Title>
                    Номер<span className="required_point">*</span>
                  </Title>
                </Col>
                <Col xs={8} className="form-input-date_picker">
                  <Row>
                    <Col xs={6}>
                      <BaseInput
                        id={"nomerDoc"}
                        value={get(values, "nomerDoc", "")}
                      />
                    </Col>
                    <Col
                      xs={6}
                      style={{
                        paddingLeft: 0,
                      }}
                    >
                      <FlexBox>
                        <Text>от:</Text>
                        <DatePicker
                          id={"dataDoc"}
                          value={get(values, "dataDoc", "")}
                          handleDate={(e: any) => {
                            getDocNumber({
                              year: checkDateYear({
                                currentYear: get(values, "dataDoc", null),
                                checkYear: moment(e).format("YYYY-MM-DD"),
                              }),
                            });

                            setFieldValue(
                              "dataDoc",
                              moment(e).format("YYYY-MM-DD")
                            );
                          }}
                          maxDate={new Date(get(values, 'maxDate', new Date()))}
                          disabled={disable}
                          dateFormat="dd.MM.yyyy"
                        />
                      </FlexBox>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col xs={6}>
              <Row align="center" className="mt-24">
                <Col xs={4} style={{ paddingRight: 0 }}>
                  <Title>
                    Контрагент<span className="required_point">*</span>
                  </Title>
                </Col>
                <Col xs={8}>
                  <BaseSelect
                    id={"kontragentId"}
                    isSearchable={true}
                    value={get(values, "kontragentId.value", "")}
                    defaultValue={get(values, "kontragentId", {})}
                    options={contractorDropdowns}
                    isLoading={loadingKontragent}
                    handleValueLabel={(e: any) => {
                      setFieldValue("kontragentId", e);

                      if (!isEqual(e, get(values, "kontragentId", false))) {
                        setFieldValue("zakazNaryadId.value", null);
                        setFieldValue("zakazNaryadId.label", null);
                        setFieldValue("dogProdajaId.value", null);
                        setFieldValue("dogProdajaId.label", null);
                        setFieldValue("territoriyaId.value", null);
                        setFieldValue("territoriyaId.label", null);
                        setFieldValue("sposobOplatiId.value", null);
                        setFieldValue("sposobOplatiId.label", null);
                        setFieldValue("skladGPId.value", null);
                        setFieldValue("skladGPId.label", null);
                        dispatch(handleAddSubjectsSales(null));
                      }
                    }}
                    isDisabled={disable || loadingOrder}
                  />
                </Col>
              </Row>
              <Row align="center" className="mt-24">
                <Col xs={4}>
                  <Title>
                    Заказ-наряд<span className="required_point">*</span>
                  </Title>
                </Col>
                <Col xs={8}>
                  <BaseSelect
                    id={"zakazNaryadId"}
                    isSearchable
                    value={get(values, "zakazNaryadId.value", "")}
                    defaultValue={get(values, "zakazNaryadId", {})}
                    options={
                      get(values, "kontragentId.value", null)
                        ? orderRegister
                        : []
                    }
                    isLoading={loadingOrder}
                    handleValueLabel={(e: any) => {
                      setFieldValue("zakazNaryadId", e);
                      setTipDogovor(get(e, "tipDogovoraId", null));
                      setFieldValue(
                        "dogProdajaId.value",
                        get(e, "dogProdajaId", null)
                      );
                      setFieldValue(
                        "dogProdajaId.label",
                        get(e, "imyaDogProdaja", null)
                      );
                      setFieldValue(
                        "territoriyaId.value",
                        get(e, "territory", null)
                      );
                      setFieldValue(
                        "territoriyaId.label",
                        get(e, "imyaTerritory", null)
                      );
                      setFieldValue(
                        "sposobOplatiId.value",
                        get(e, "sposobOplataId", null)
                      );
                      setFieldValue(
                        "sposobOplatiId.label",
                        get(e, "imyaSposobOplata", null)
                      );
                    }}
                    isDisabled={disable}
                  />
                </Col>
              </Row>
              <Row align="center" className="mt-24">
                <Col xs={4}>
                  <Title>
                    Договор<span className="required_point">*</span>
                  </Title>
                </Col>
                <Col xs={8}>
                  <BaseSelect
                    id={"dogProdajaId"}
                    defaultValue={get(values, "dogProdajaId", "")}
                    isSearchable
                    value={get(values, "dogProdajaId.value", "")}
                    options={getSaleContracts}
                    isDisabled={true}
                  />
                </Col>
              </Row>
              <TextArea
                id={"kommentariya"}
                label={"Коментарии"}
                disabled={disable}
                value={get(values, "kommentariya", "")}
                handleInput={(e: string) => {
                  setFieldValue("kommentariya", e);
                }}
              />
            </Col>
            {/* right */}
            <Col xs={6}>
              <Row align="center" className="mt-24">
                <Col xs={4}>
                  <Title>
                    Территория<span className="required_point">*</span>
                  </Title>
                </Col>
                <Col xs={8}>
                  <BaseSelect
                    id={"territoriyaId"}
                    isSearchable
                    value={get(values, "territoriyaId.value", "")}
                    defaultValue={get(values, "territoriyaId", {})}
                    options={territory}
                    isDisabled={true}
                  />
                </Col>
              </Row>
              <Row align="center" className="mt-24">
                <Col xs={4} style={{ paddingRight: 0 }}>
                  <Title>
                    Склад ГП<span className="required_point">*</span>
                  </Title>
                </Col>
                <Col xs={8}>
                  <BaseSelect
                    defaultValue={get(values, "skladGPId", {})}
                    id={"skladGPId"}
                    isSearchable
                    value={get(values, "skladGPId.value", "")}
                    options={skladOptions}
                    isDisabled={true}
                  />
                </Col>
              </Row>
              <Row align="center" className="mt-24">
                <Col xs={4} style={{ paddingRight: 0 }}>
                  <Title>
                    Способ оплаты<span className="required_point">*</span>
                  </Title>
                </Col>
                <Col xs={8}>
                  <BaseSelect
                    id={"sposobOplatiId"}
                    isSearchable
                    value={get(values, "sposobOplatiId.value", "")}
                    defaultValue={get(values, "sposobOplatiId", {})}
                    options={paymentMethod}
                    isDisabled={true}
                  />
                </Col>
              </Row>
              <FormInput
                label={"Общая сумма"}
                required={true}
                id={"sebestTovar"}
                value={sumWithInitial}
                disabled={true}
              />
            </Col>
          </Row>
          <Row className="mt-24" justify={"between"}>
            <Col xs={12} className="align-center">
              <FlexBox justify={"space-between"} align={"center"}>
                <Title bold sm>
                  Табличная часть<span className="required_point">*</span>
                </Title>
              </FlexBox>
            </Col>
            <Col xs={12} style={{ padding: 0 }} className={"my-16"}>
              {loadingDetails ? (
                <ProgressBar
                  color="#5899f5"
                  mode="indeterminate"
                  style={{ height: "2px" }}
                ></ProgressBar>
              ) : (
                <hr />
              )}
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <Col>
                <BaseTable
                  tableHeader={[
                    "№",
                    "Товар",
                    "Ед. изм",
                    "Цена",
                    "Скидка",
                    "Корректировка",
                    "Количество",
                    "Цена со скидкой",
                    "Сумма",
                    "Сумма НДС",
                    "Всего",
                  ]}
                  className="table_scroll"
                >
                  {!loadingDetails ? (
                    <>
                      {!isEmpty(
                        get(
                          salesProductSubjects,
                          "data.realizatsiyaGPDetails",
                          []
                        )
                      ) ? (
                        get(
                          salesProductSubjects,
                          "data.realizatsiyaGPDetails",
                          []
                        ).map((item: any, index: number) => (
                          <tr key={index + 1} className="responsive_overflow">
                            <td>{index + 1}</td>
                            <td>{get(item, "imyaTovar", "")}</td>
                            <td>{get(item, "imyaEdIzm", "")}</td>
                            <td>
                              <NumericFormat
                                displayType="text"
                                value={get(item, "tsena", 0)}
                                thousandSeparator={true}
                                decimalScale={2}
                              />
                            </td>
                            <td className={"min-w-80"}>
                              {/* only ' dogovor == gadavoy ' show select */}
                              {isEqual(tipDogovor, 1) &&
                                isEqual(disable, false) ? (
                                <FlexBox style={{ zIndex: 1000 }}>
                                  <BaseSelect
                                    width={"250px"}
                                    className="mr-8"
                                    menuPlacement={"top"}
                                    id={"skidkaProtsent"}
                                    defaultValue={{
                                      label: get(item, "skidkaProtsent", null),
                                      value: 1,
                                    }}
                                    options={discountPro}
                                    placement={"top"}
                                    handleValueLabel={(e: any) => {
                                      handleChangeSkida({
                                        kol: get(item, "kol", 0),
                                        skidkaId: get(e, "value", 1),
                                        additionalSumma: get(
                                          item,
                                          "additionalSumma",
                                          0
                                        ),
                                        tovarId: get(item, "tovarId", null),
                                      });
                                    }}
                                  />
                                </FlexBox>
                              ) : (
                                get(item, "skidkaProtsent", 0)
                              )}
                            </td>
                            <td>
                              {isEqual(tipDogovor, 1) &&
                                isEqual(disable, false) ? (
                                //  adding summa
                                <BaseMarkInput
                                  value={get(item, "additionalSumma", 0) || "0"}
                                  handleInput={(e: number) => {
                                    debounced({
                                      kol: get(item, "kol", 0),
                                      skidkaId:
                                        discountPro.find((details) =>
                                          isEqual(
                                            details.prosent,
                                            get(item, "skidkaProtsent", 0)
                                          )
                                        )?.value || 0,
                                      additionalSumma: e,
                                      tovarId: get(item, "tovarId", null),
                                    });
                                  }}
                                  maxLength={40}
                                />
                              ) : (
                                <NumericFormat
                                  displayType="text"
                                  value={get(item, "additionalSumma", 0) || 0}
                                  thousandSeparator={true}
                                  decimalScale={2}
                                />
                              )}
                            </td>
                            <td>
                              {isEqual(disable, false) ? (
                                // kol
                                <BaseMarkInput
                                  placeholder={`до: ${textFormatter(
                                    kolLimitList[index]
                                  )}`}
                                  value={get(item, "kol", "") || ""}
                                  handleInput={(e: number) => {
                                    debounced({
                                      kol: e,
                                      skidkaId:
                                        discountPro.find((detailsOption) =>
                                          isEqual(
                                            detailsOption.prosent,
                                            get(item, "skidkaProtsent", 0)
                                          )
                                        )?.value || 0,
                                      additionalSumma: get(
                                        item,
                                        "additionalSumma",
                                        0
                                      ),
                                      tovarId: get(item, "tovarId", null),
                                    });
                                  }}
                                  maxLength={40}
                                />
                              ) : (
                                <NumericFormat
                                  displayType="text"
                                  value={get(item, "kol", 0) || 0}
                                  thousandSeparator={true}
                                  decimalScale={2}
                                />
                              )}
                            </td>
                            <td>
                              <NumericFormat
                                displayType="text"
                                value={get(item, "tsenaSoSkidkoy", 0)}
                                thousandSeparator={true}
                                decimalScale={2}
                              />
                            </td>
                            <td>
                              <NumericFormat
                                displayType="text"
                                value={get(item, "summa", 0)}
                                thousandSeparator={true}
                                decimalScale={2}
                              />
                            </td>
                            <td>
                              <NumericFormat
                                displayType="text"
                                value={get(item, "summaNDS", 0)}
                                thousandSeparator={true}
                                decimalScale={2}
                              />
                            </td>
                            <td>
                              <NumericFormat
                                displayType="text"
                                value={get(item, "vsego", 0)}
                                thousandSeparator={true}
                                decimalScale={2}
                              />
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan={11}>Нет Данных</td>
                        </tr>
                      )}
                    </>
                  ) : (
                    <tr>
                      <td colSpan={11}>Загрузка...</td>
                    </tr>
                  )}
                </BaseTable>
              </Col>
            </Col>
          </Row>
          <Row className={"mb-16 mt-32 text-end"}>
            <Col xs={12}>
              <HasAccess>
                {({ userCan }: any) =>
                  userCan({
                    permission: `${DOCUMENT_KEY.REALIZATSIYA_GP}_CREATE`,
                    page: DOCUMENT_KEY.REALIZATSIYA_GP,
                    department: MAIN_DEPARTMENT.DOCUMENTS,
                    type: PROTECTED_TYPE.PERMISSIONS,
                  }) && (
                    <FormButton
                      disabled={disable}
                      loading={isSubmitting}
                      handleClick={handleSubmit}
                    >
                      {PAGE.DONE}
                    </FormButton>
                  )
                }
              </HasAccess>

              <Button danger handleClick={() => navigate(-1)}>
                {PAGE.CLOSE}
              </Button>
            </Col>
          </Row>
        </Form>
      ) : (
        <LoadingAddPages />
      )}
    </Content>
  );
};

export default AddSalesProductContainer;
