import { Space, Spin } from "antd";
import { useAppDispatch, useAppSelector } from "app/hook";
import { prixodDetails } from "app/slices/documentSlices/receivedMaterialSlices/type";
import {
  changeVATPrice,
  fetchNotFilledPurchaseContracts,
  fetchPurchaseContractById,
  fetchPurchaseContractByTempId,
  fetchPurchaseContracts,
  fetchPurchaseContractsStatistics,
  movementPurchaseSubject,
} from "app/slices/documentSlices/registerPurchaseSlices/registerPurchaseSlices";
import { PurchaseTableDetails } from "app/slices/documentSlices/registerPurchaseSlices/type";
import { fetchAllCounterpart } from "app/slices/handbookSlices/counterpartSlices/counterpartSlices";
import { fetchRateVAT } from "app/slices/handbookSlices/rateVATSlices/rateVATSlices";
import { fetchRawMaterial } from "app/slices/handbookSlices/rawMaterialSlices/rawMaterialSlices";
import {
  purchaseContractFilter,
  purchaseContractsForm,
} from "app/states/documents";
import {
  contractorOptions,
  deliveryMethodsOptions,
  loadingConterpart,
  paymentMethodsOptions,
  unitsMeasurementOptions,
} from "app/states/documents/selectStates";
import {
  getMaterialData,
  getTermsPaymentData,
  getVatRateData,
} from "app/states/handbooks/selectStates";
import add_green from "assets/images/icons/add_green.svg";
import {
  ActionBase,
  BaseInput,
  BaseSelect,
  BaseTable,
  Button,
  Content,
  DatePicker,
  FlexBox,
  Form,
  FormButton,
  FormInput,
  FormSelect,
  LoadingAddPages,
  ReactModal,
  Text,
  TextArea,
  Title,
} from "components";
import BaseMarkInput from "components/BaseMarkInput/BaseMarkInput";
import { useFormik } from "formik";
import { forEach, get, isEmpty, isEqual } from "lodash";
import { AddNewCounterpartModal } from "modules/statics/spravochnik/containers/CounterpartsContainer";
import { AddRowMaterialModal } from "modules/statics/spravochnik/containers/RawMaterialsContainer";
import moment from "moment";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Col, Row } from "react-grid-system";
import { NumericFormat } from "react-number-format";
import { useLocation, useNavigate } from "react-router-dom";
import { ReactSVG } from "react-svg";
import { toast } from "react-toastify";
import HasAccess from "routers/HasAccess";
import { DOCUMENT_KEY, MAIN_DEPARTMENT } from "routers/constants";
import DocumentServices from "services/apiServices/documents";
import { ACTION, MESSAGES, PAGE, PROTECTED_TYPE } from "utilities/constants";
import {
  calcAmountWithVAT,
  calcTotalAmount,
  calcTotalPrice,
  removeValueOption,
} from "utilities/helpers";
import { MaterialDetailsSchema } from "utilities/schema/document/documentSchemas";

// Добавить  Регистрация договоров на покупку
type OptionType = {
  label: string;
  value: number | string;
};

type ContractDetails = {
  nomerDoc: string;
  dataDoc: string;
  kontragentId: OptionType | {};
  dataIsp: string;
  sposobOplataId: OptionType | {};
  sposobDostavkaId: OptionType | {};
};

const AddNewDocToTable = ({
  handleOpenModal,
  handleCloseModal,
  stavkaNDSId,
  dataTable,
  setIsOpenMaterial,
  id,
}: {
  handleOpenModal?: () => void;
  handleCloseModal: () => void;
  stavkaNDSId?: number | string | null;
  dataTable?: Array<PurchaseTableDetails>;
  id?: number | null | undefined;
  setIsOpenMaterial?: any;
}) => {
  const dispatch = useAppDispatch();
  const getMaterialOptions = useAppSelector(getMaterialData);
  const unitsMeasurementDropdowns = useAppSelector(unitsMeasurementOptions);
  const vatRate = useAppSelector(getVatRateData);
  const details = dataTable?.find((value: any) => value.materialId === id);
  const onSubmit = (values: any) => {
    const res = {
      ...values,
      materialId: get(values, "materialId.value", null),
      imyaMateriala: get(values, "materialId.label", ""),
      imyaEdIzm: get(values, "materialId.imyaEdIzm", ""),
      summa: get(values, "totalAmt", 0),
      vsego: get(values, "total", 0),
      summaNDS: get(values, "nds", 0),
    };
    if (isEmpty(details)) {
      dispatch(movementPurchaseSubject({ data: res, type: ACTION.ADD }));
    } else {
      dispatch(movementPurchaseSubject({ data: res, type: ACTION.UPDATE, id }));
    }
    resetForm();
    handleCloseModal();
  };

  const { values, handleSubmit, setFieldValue, resetForm } = useFormik({
    initialValues: {
      edIzmId: get(details, "edIzmId", ""),
      kol: get(details, "kol", ""),
      sena: get(details, "sena", ""),
      materialId: {
        value: get(details, "materialId", ""),
        label: get(details, "imyaMateriala", ""),
        imyaEdIzm: get(details, "imyaEdIzm", ""),
      },
      totalAmt: get(details, "totalAmt", 0),
      nds: get(details, "nds", 0),
      id: get(details, "id", ""),
      total: get(details, "total", ""),
    },
    enableReinitialize: true,
    validationSchema: MaterialDetailsSchema,
    onSubmit,
  });

  const handleCalcTotalAmt = useCallback(() => {
    const totalAmt = calcTotalAmount({
      amount: get(values, "sena", ""),
      quantity: get(values, "kol", ""),
    });
    setFieldValue("totalAmt", totalAmt);
  }, [values.sena, values.kol]);

  const handleCalcAmtWithVAT = useCallback(() => {
    if (stavkaNDSId && values.totalAmt) {
      const finedVAT = vatRate.find(
        (item: any) => get(item, "value", 0) == stavkaNDSId
      );
      const amountWithVAT = calcAmountWithVAT({
        amount: get(values, "totalAmt", ""),
        VAT: get(finedVAT, "percentage", 0),
        kol: get(values, "kol", ""),
        sena: get(values, "sena", ""),
      });
      setFieldValue("nds", amountWithVAT);
    }
  }, [values.kol, values.totalAmt, values.nds, values.sena]);
  const handleCloseButton = () => {
    resetForm();
    handleCloseModal();
  };
  const handleCalcTotal = useCallback(() => {
    const finedVAT = vatRate.find(
      (item: any) => get(item, "value", 0) == stavkaNDSId
    );
    const totalSum = calcTotalPrice({
      nds: get(finedVAT, "percentage", 0),
      totalAmount: get(values, "totalAmt", 0),
    });
    setFieldValue("total", totalSum);
  }, [values.nds, values.totalAmt]);

  useEffect(() => {
    handleCalcTotalAmt();
  }, [handleCalcTotalAmt]);

  useEffect(() => {
    handleCalcTotal();
  }, [handleCalcTotal]);

  useEffect(() => {
    handleCalcAmtWithVAT();
  }, [handleCalcAmtWithVAT]);

  return (
    <>
      <Col xs={12} className="mt-16 text-left">
        <Title sm bold>
          Ввод данных
        </Title>
      </Col>
      <Col xs={12} style={{ padding: 0 }} className={"my-16"}>
        <hr />
      </Col>
      <Form onSubmit={handleSubmit} align={"center"}>
        <Col xs={12} className={"px-24"}>
          <Row align="center" className="mt-24">
            <Col xs={4}>
              <Title>
                Сырья и материалов<span className="required_point">*</span>
              </Title>
            </Col>
            <Col xs={8}>
              <HasAccess>
                {({ userCan }: any) =>
                  userCan({
                    permission: "SM_CREATE",
                    page: "SM",
                    department: "SPRAVOCHNIKI",
                    type: PROTECTED_TYPE.PERMISSIONS,
                  }) ? (
                    <BaseSelect
                      id={"materialId"}
                      isSearchable
                      value={get(values, "materialId.value", "")}
                      defaultValue={get(values, "materialId", {})}
                      options={removeValueOption({
                        currentOption: getMaterialOptions,
                        removeValueList: dataTable,
                        valueName: "materialId",
                      })}
                      handleValueLabel={(e: any) => {
                        setFieldValue("materialId", e);
                        setFieldValue("imyaEdIzm", get(e, "imyaEdIzm", ""));
                        setFieldValue("edIzmId", get(e, "edIzmId", ""));
                      }}
                      isCustomMenu={true}
                      menuButtonTitle={"+ Добавить"}
                      handleAddNewOption={() => setIsOpenMaterial(true)}
                    />
                  ) : (
                    <BaseSelect
                      id={"materialId"}
                      isSearchable
                      value={get(values, "materialId.value", "")}
                      defaultValue={get(values, "materialId", {})}
                      options={removeValueOption({
                        currentOption: getMaterialOptions,
                        removeValueList: dataTable,
                        valueName: "materialId",
                      })}
                      handleValueLabel={(e: any) => {
                        setFieldValue("materialId", e);
                        setFieldValue("imyaEdIzm", get(e, "imyaEdIzm", ""));
                        setFieldValue("edIzmId", get(e, "edIzmId", ""));
                      }}
                      isCustomMenu={false}
                      menuButtonTitle={"+ Добавить"}
                      handleAddNewOption={() => setIsOpenMaterial(true)}
                    />
                  )
                }
              </HasAccess>
            </Col>
          </Row>
          <Row align="center" className="mt-24">
            <Col xs={4} style={{ paddingRight: 0 }}>
              <Title>
                Количество <span className="required_point">*</span>
              </Title>
            </Col>
            <Col xs={8} className="form-input-date_picker">
              <Row>
                <Col xs={7}>
                  <BaseMarkInput
                    min={1}
                    id={"kol"}
                    value={get(values, "kol", "")}
                    handleInput={(e: number) => {
                      setFieldValue("kol", e);
                    }}
                  />
                </Col>
                <Col xs={5} style={{ paddingLeft: 0 }}>
                  <FlexBox>
                    <Text xs>Ед.изм.:</Text>
                    <BaseSelect
                      id={"edIzmId"}
                      isSearchable
                      value={get(values, "edIzmId", "")}
                      options={unitsMeasurementDropdowns}
                      isDisabled={true}
                    />
                  </FlexBox>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row align="center" className="mt-24">
            <Col xs={4} style={{ paddingRight: 0 }}>
              <Title>
                Цена <span className="required_point">*</span>
              </Title>
            </Col>
            <Col xs={8}>
              <BaseMarkInput
                value={get(values, "sena", "")}
                handleInput={(e: number) => {
                  setFieldValue("sena", e);
                }}
                maxLength={40}
              />
            </Col>
          </Row>
          <FormInput
            value={get(values, "totalAmt", "")}
            label={"Сумма"}
            required={true}
            disabled={true}
          />

          <FormInput
            disabled={true}
            label={"Сумма НДС"}
            required={true}
            value={get(values, "nds", "")}
          />
          <FormInput
            label={"Всего"}
            required={true}
            value={get(values, "total", "")}
            disabled={true}
          />
          <Col xs={12} className="text-right mt-24 mb-16">
            <Button className="mr-8" type={"submit"} green>
              {get(details, "edIzmId", null) ? PAGE.CHANGE : PAGE.ADD}
            </Button>
            <Button onClick={handleCloseButton} danger>
              Закрыть
            </Button>
          </Col>
        </Col>
      </Form>
    </>
  );
};
const AddPurchaseContractsContainer = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { state } = useLocation();

  const purchaseContract = useAppSelector(purchaseContractsForm);
  const [openModal, setOpenModal] = useState(false);
  const contractorDropdowns = useAppSelector(contractorOptions);
  const paymentMethodsDropdowns = useAppSelector(paymentMethodsOptions);
  const deliveryMethodsDropdowns = useAppSelector(deliveryMethodsOptions);
  const termsPayment = useAppSelector(getTermsPaymentData);
  const filter = useAppSelector(purchaseContractFilter);
  const vatRate = useAppSelector(getVatRateData);
  const [materialId, setMaterial] = useState<number | undefined>();
  const [disable, setDisable] = useState(false);

  const [isOpenMaterialModal, setIsOpenMaterial] = useState(false);

  const [openCounterpart, setOpenCounterpart] = useState(false);
  const LOADING = useMemo(
    () => get(purchaseContract, "loading", false),
    [purchaseContract]
  );
  let loading_kontragent = useAppSelector(loadingConterpart);
  const handleCloseModal = useCallback(() => {
    setOpenModal(false);
  }, []);

  const handleOpenModal = useCallback((id?: number | undefined) => {
    setOpenModal(true);
    setMaterial(id);
  }, []);

  const onSubmit = (values: ContractDetails) => {
    const subjects = get(purchaseContract, "data.details", []).map(
      (item: prixodDetails) => ({
        materialId: get(item, "materialId", ""),
        edIzmId: get(item, "edIzmId", ""),
        kol: get(item, "kol", ""),
        sena: get(item, "sena", ""),
      })
    );
    let valuesToSbm: any = {};
    forEach(
      values,
      (value: any, key: any) =>
      (valuesToSbm[key] = [
        "dataIsp",
        "nomerDoc",
        "dataDoc",
        "summaDog",
        "kommentariya",
      ].includes(key)
        ? value
        : get(value, "value", ""))
    );

    try {
      DocumentServices.AddPurchases({
        transactionTime: new Date(),
        resultCode: null,
        resultMsg: null,
        data: {
          ...valuesToSbm,
          dataDoc: !isEqual(get(values, "dataDoc", null), null)
            ? get(values, "dataDoc", "")
            : moment(new Date()).format("YYYY-MM-DD"),
          details: subjects,
        },
      }).then((res) => {
        if (get(res, "status", "") == 200) {
          navigate(-1);
          dispatch(fetchPurchaseContracts(filter));
          dispatch(fetchPurchaseContractsStatistics());
          toast.success(MESSAGES.ADDED);
          if (get(state, "id", false)) {
            DocumentServices.RemovePurchaseTempContractById(
              get(state, "id", "")
            ).then((res) => {
              if (get(res, "status", "") == 200) {
                dispatch(fetchNotFilledPurchaseContracts());
              }
            });
          }
        }
        setSubmitting(false);
      });
    } catch (err) {
      console.log("err", err);
    }
  };
  const { values, setFieldValue, handleSubmit, setSubmitting, isSubmitting } =
    useFormik({
      enableReinitialize: true,
      initialValues: {
        ...get(purchaseContract, "data.formIntVls", {}),
        dataDoc: get(state, 'id', false) ? get(purchaseContract, "data.formIntVls.dataDoc", '') : ''
      },
      onSubmit,
    });

  const handleUpdatePurchase = useCallback(() => {
    const subjectsUpdate = get(purchaseContract, "data.details", []).map(
      (item: prixodDetails) => ({
        materialId: get(item, "materialId", ""),
        edIzmId: get(item, "edIzmId", ""),
        kol: get(item, "kol", ""),
        id: get(item, "id", ""),
        sena: get(item, "sena", ""),
      })
    );
    let valuesToSbm: any = {};

    forEach(values, (value: any, key: any) => {
      valuesToSbm[key] = [
        "dataIsp",
        "nomerDoc",
        "dataDoc",
        "summaDog",
        "kommentariya",
      ].includes(key)
        ? value
        : get(value, "value", "");
    });
    try {
      DocumentServices.UpdateRegisterPurchases({
        transactionTime: new Date(),
        resultCode: null,
        resultMsg: null,
        data: {
          ...valuesToSbm,
          dataDoc: !isEqual(get(values, "dataDoc", null), null)
            ? get(values, "dataDoc", "")
            : moment(new Date()).format("YYYY-MM-DD"),
          id: get(state, "id", null),
          details: subjectsUpdate,
        },
      }).then((res) => {
        if (get(res, "status", "") == 200) {
          navigate(-1);
          dispatch(fetchPurchaseContracts(filter));
          dispatch(fetchPurchaseContractsStatistics());
          toast.success(MESSAGES.ADDED);
        }
        setSubmitting(false);
      });
    } catch (err) {
      console.log("err", err);
    }
  }, [dispatch, navigate, values, get(purchaseContract, "data.details", [])]);
  const saveNotFullFilledForm = useCallback(() => {
    const TempSubjects = get(purchaseContract, "data.details", []).map(
      (item: any) => ({
        materialId: get(item, "materialId", ""),
        imyaMateriala: get(item, "imyaMateriala", ""),
        imyaEdIzm: get(item, "imyaEdIzm", ""),
        edIzmId: get(item, "edIzmId", ""),
        kol: get(item, "kol", ""),
        sena: get(item, "sena", ""),
        nds: get(item, "nds", 0),
        total: get(item, "total", 0),
        totalAmt: get(item, "totalAmt", 0),
      })
    );
    let valuesToSbm: any = {};

    forEach(values, (value: any, key: any) => {
      valuesToSbm[key] = [
        "dataIsp",
        "nomerDoc",
        "dataDoc",
        "summaDog",
        "kommentariya",
      ].includes(key)
        ? value
        : get(value, "value", "");
    });

    try {
      DocumentServices.AddNotFullFilledPurchases({
        transactionTime: new Date(),
        resultCode: null,
        resultMsg: null,
        data: {
          ...valuesToSbm,
          dataDoc: !isEqual(get(values, "dataDoc", null), null)
            ? get(values, "dataDoc", "")
            : moment(new Date()).format("YYYY-MM-DD"),
          id: get(state, "id", null),
          imyaTipDogovora: get(values, "tipDogovoraId.label", ""),
          imyaKontragent: get(values, "kontragentId.label", ""),
          sposobOplataName: get(values, "sposobOplataId.label", ""),
          sposobDostavkaName: get(values, "sposobDostavkaId.label", ""),
          imyaUsloviyaOplati: get(values, "usloviyaOplataId.label", ""),
          details: TempSubjects,
        },
      }).then((res) => {
        if (get(res, "status", "") == 200) {
          navigate(-1);
          dispatch(fetchNotFilledPurchaseContracts());
          toast.success(MESSAGES.ADDED);
        }
      });
    } catch (err) {
      console.log("err", err);
    }
  }, [dispatch, navigate, values, get(purchaseContract, "data.details", [])]);

  const handleCheckTemp = useCallback(() => {
    if (!isEmpty(get(state, "id", false))) {
      if (get(state, "isTemp", false)) {
        dispatch(fetchPurchaseContractByTempId(get(state, "id", ""))).then((res: any) => {

          if (get(res, "payload.transactionTime", "")) {
            const transactionTime = get(res, "payload.transactionTime", "");
            const localMaxDate = moment.utc(transactionTime).add(5, 'hours').format("YYYY-MM-DD HH:mm");
            const localDate = moment.utc(transactionTime).add(5, 'hours').format("YYYY-MM-DD");
            setFieldValue("maxDate", localMaxDate);
            if (isEmpty(get(values, 'dataDoc', ''))) {
              setFieldValue("dataDoc", localDate);
            }
          }
        });
      } else if (get(state, "isUpdate", false)) {
        dispatch(fetchPurchaseContractById(get(state, "id", ""))).then((res: any) => {

          if (get(res, "payload.transactionTime", "")) {
            const transactionTime = get(res, "payload.transactionTime", "");
            const localMaxDate = moment.utc(transactionTime).add(5, 'hours').format("YYYY-MM-DD HH:mm");
            const localDate = moment.utc(transactionTime).add(5, 'hours').format("YYYY-MM-DD");
            setFieldValue("maxDate", localMaxDate);
            // if (isEmpty(get(values, 'dataDoc', ''))) {
            //   setFieldValue("dataDoc", localDate);
            // }
          }
        });
        dispatch(fetchAllCounterpart({ all: true, filterPage: "DOG_POKUPKA" }))
        dispatch(fetchRateVAT({ all: true }));
        dispatch(fetchRawMaterial({ all: true, tip: "SM" }));
      } else {
        dispatch(fetchPurchaseContractById(get(state, "id", ""))).then((res: any) => {

          if (get(res, "payload.transactionTime", "")) {
            const transactionTime = get(res, "payload.transactionTime", "");
            const localMaxDate = moment.utc(transactionTime).add(5, 'hours').format("YYYY-MM-DD HH:mm");
            const localDate = moment.utc(transactionTime).add(5, 'hours').format("YYYY-MM-DD");
            setFieldValue("maxDate", localMaxDate);
            if (isEmpty(get(values, 'dataDoc', ''))) {
              setFieldValue("dataDoc", localDate);
            }
          }
        });
        setDisable(true);
      }
    } else {
      dispatch(fetchAllCounterpart({ all: true, filterPage: "DOG_POKUPKA" }));
      dispatch(fetchRateVAT({ all: true })).then((res: any) => {
        if (get(res, "payload.transactionTime", "")) {
          const transactionTime = get(res, "payload.transactionTime", "");
          const localMaxDate = moment.utc(transactionTime).add(5, 'hours').format("YYYY-MM-DD HH:mm");
          const localDate = moment.utc(transactionTime).add(5, 'hours').format("YYYY-MM-DD");
          setFieldValue("maxDate", localMaxDate);
          if (isEmpty(get(values, 'dataDoc', ''))) {
            setFieldValue("dataDoc", localDate);
          }
        }
      });
      dispatch(fetchRawMaterial({ all: true, tip: "SM" }));
    }
  }, [dispatch, state]);

  //  function get all material Prices

  const fetchDetailsSum = useCallback(() => {
    const sumWithInitial = get(purchaseContract, "data.details", []).reduce(
      (accumulator: any, currentValue: any) =>
        accumulator + Number(currentValue.vsego),
      0
    );

    if (sumWithInitial > 0) {
      setFieldValue("summaDog", sumWithInitial);
    } else if (isEmpty(get(purchaseContract, "data.details", []))) {
      setFieldValue("summaDog", 0);
    }
  }, [get(purchaseContract, "data.details", [])]);

  // change VAT price in material when change select
  const handleCalcAmtWithVAT = useCallback(() => {
    const finedVAT = vatRate.find(
      (item: any) =>
        get(item, "value", 0) == get(values, "stavkaNDSId.value", 0)
    );

    get(purchaseContract, "data.details", []).map(
      (item: any, index: number) => {
        if (finedVAT && get(item, "summa", "")) {
          // calculate VAT price
          const amountWithVAT = calcAmountWithVAT({
            amount: get(item, "summa", ""),
            VAT: get(finedVAT, "percentage", ""),
            kol: get(item, "kol", ""),
            sena: get(item, "sena", ""),
          });
          const totalSum = calcTotalPrice({
            nds: get(finedVAT, "percentage", ""),
            totalAmount: get(item, "summa", 0),
          });
          dispatch(
            changeVATPrice({
              value: amountWithVAT,
              id: index,
              nds: get(finedVAT, "percentage", 0),
              vsego: totalSum,
            })
          );
        }
      }
    );
  }, [get(values, "stavkaNDSId.value", 0), vatRate]);

  const fetchAllRawMaterialData = useCallback(() => {
    dispatch(fetchRawMaterial({ ...filter, all: true, InAscOrder: false }));
  }, [dispatch]);

  useEffect(() => {
    handleCalcAmtWithVAT();
  }, [handleCalcAmtWithVAT]);
  useEffect(() => {
    handleCheckTemp();
  }, [dispatch, handleCheckTemp]);

  useEffect(() => {
    fetchDetailsSum();
  }, [fetchDetailsSum]);

  return (
    <Content className={"min_height"} style={{ position: "relative" }}>
      {/* modal */}
      <ReactModal isOpen={openModal}>
        <AddNewDocToTable
          setIsOpenMaterial={setIsOpenMaterial}
          handleCloseModal={handleCloseModal}
          stavkaNDSId={get(values, "stavkaNDSId.value", 0)}
          dataTable={get(purchaseContract, "data.details", [])}
          id={materialId}
        />
      </ReactModal>
      {/* kontragent */}
      <ReactModal isOpen={openCounterpart}>
        <AddNewCounterpartModal
          handleCloseModal={() => setOpenCounterpart(false)}
          afterSubmitAction={() =>
            dispatch(
              fetchAllCounterpart({ all: true, filterPage: "DOG_POKUPKA" })
            )
          }
        />
      </ReactModal>
      {/* material */}
      <ReactModal isOpen={isOpenMaterialModal}>
        <AddRowMaterialModal
          handleCloseModal={() => setIsOpenMaterial(false)}
          afterSubmitAction={() => fetchAllRawMaterialData()}
        />
      </ReactModal>
      {isEqual(LOADING, false) ? (
        <Form onSubmit={handleSubmit}>
          <Row>
            <Col xs={12}>
              <FlexBox justify={"space-between"} style={{ width: "100%" }}>
                <Title bold sm>
                  Реквизиты
                </Title>
                <Button
                  green
                  handleClick={saveNotFullFilledForm}
                  disabled={
                    disable || get(state, "isUpdate", false) ? true : false
                  }
                >
                  Сохранить
                </Button>
              </FlexBox>
            </Col>
            <Col xs={12} style={{ padding: 0 }} className={"my-16"}>
              <hr />
            </Col>
          </Row>
          <Row>
            <Col xs={6}>
              <Row align="center">
                <Col xs={4}>
                  <Title>
                    Номер<span className="required_point">*</span>
                  </Title>
                </Col>
                <Col xs={8} className="form-input-date_picker">
                  <Row>
                    <Col xs={5}>
                      <BaseInput
                        id={"nomerDoc"}
                        value={get(values, "nomerDoc", "")}
                        handleInput={(e: string) => {
                          setFieldValue("nomerDoc", e);
                        }}
                        disabled={disable}
                      />
                    </Col>
                    <Col xs={7} style={{ paddingLeft: 0 }}>
                      <FlexBox>
                        <Text>от:</Text>
                        <DatePicker
                          id={"dataDoc"}
                          value={get(values, "dataDoc", null)}
                          handleDate={(e: any) => {
                            setFieldValue(
                              "dataDoc",
                              moment(e).format("YYYY-MM-DD")
                            );
                          }}
                          dateFormat="dd.MM.yyyy"
                          disabled={disable}
                          // maxDate={new Date()}
                          maxDate={new Date(get(values, 'maxDate', new Date()))}
                        />
                      </FlexBox>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row align="center" className="mt-24">
                <Col xs={4}>
                  <Title>
                    Контрагент<span className="required_point">*</span>
                  </Title>
                </Col>
                <Col xs={8}>
                  <BaseSelect
                    id={"kontragentId"}
                    placeholder={get(values, "kontragentId.label", "")}
                    isSearchable
                    isLoading={loading_kontragent}
                    value={get(values, "kontragentId.value", "")}
                    options={contractorDropdowns}
                    handleValueLabel={(e: any) => {
                      setFieldValue("kontragentId", e);
                    }}
                    isDisabled={disable}
                    isCustomMenu={true}
                    menuButtonTitle={"+ Добавить"}
                    handleAddNewOption={() => setOpenCounterpart(true)}
                  />
                </Col>
              </Row>
              <Row align="center" className="mt-24">
                <Col xs={4}>
                  <Title>
                    Способ оплаты<span className="required_point">*</span>
                  </Title>
                </Col>
                <Col xs={8}>
                  <BaseSelect
                    id={"sposobOplataId"}
                    isSearchable
                    value={get(values, "sposobOplataId.value", "")}
                    options={paymentMethodsDropdowns}
                    handleValueLabel={(e: any) => {
                      setFieldValue("sposobOplataId", e);
                    }}
                    isDisabled={disable}
                  />
                </Col>
              </Row>
              <Row align="center" className="mt-24">
                <Col xs={4} style={{ paddingRight: 0 }}>
                  <Title>
                    Способ доставки<span className="required_point">*</span>
                  </Title>
                </Col>
                <Col xs={8}>
                  <BaseSelect
                    id={"sposobDostavkaId"}
                    isSearchable
                    value={get(values, "sposobDostavkaId.value", "")}
                    options={deliveryMethodsDropdowns}
                    handleValueLabel={(e: any) => {
                      setFieldValue("sposobDostavkaId", e);
                    }}
                    isDisabled={disable}
                  />
                </Col>
              </Row>
              <TextArea
                id={"kommentariya"}
                label={"Коментарии"}
                disabled={disable}
                value={get(values, "kommentariya", "")}
                handleInput={(e: string) => {
                  setFieldValue("kommentariya", e);
                }}
              />
            </Col>
            <Col xs={6}>
              <Row align="center">
                <Col xs={4} style={{ paddingRight: 0 }}>
                  <Title>Дата действия договора</Title>
                </Col>
                <Col xs={8}>
                  <DatePicker
                    id={"dataIsp"}
                    value={get(values, "dataIsp", null)}
                    dateFormat="dd.MM.yyyy"
                    handleDate={(e) =>
                      setFieldValue("dataIsp", moment(e).format("YYYY-MM-DD"))
                    }
                    disabled={disable}
                  />
                </Col>
              </Row>
              <Row align="center" className="mt-24">
                <Col xs={4}>
                  <Title>
                    Условия оплаты<span className="required_point">*</span>
                  </Title>
                </Col>
                <Col xs={8}>
                  <BaseSelect
                    id={"usloviyaOplataId"}
                    isSearchable
                    value={get(values, "usloviyaOplataId.value", "")}
                    options={termsPayment}
                    handleValueLabel={(e: any) => {
                      setFieldValue("usloviyaOplataId", e);
                    }}
                    isDisabled={disable}
                  />
                </Col>
              </Row>

              <Row align="center" className="mt-24">
                <Col xs={4}>
                  <Title>
                    Ставка НДС<span className="required_point">*</span>
                  </Title>
                </Col>
                <Col xs={8}>
                  <BaseSelect
                    id={"stavkaNDSId"}
                    isSearchable
                    value={get(values, "stavkaNDSId.value", 0)}
                    options={vatRate}
                    handleValueLabel={(e: any) => {
                      setFieldValue("stavkaNDSId", e);
                    }}
                    isDisabled={disable}
                  />
                </Col>
              </Row>
              <FormInput
                label={"Сумма договора"}
                required={true}
                id={"summaDog"}
                value={get(values, "summaDog", 0)}
                disabled={true}
              />
            </Col>
          </Row>
          <Row className="mt-24" justify={"between"}>
            <Col xs={12} className="align-center">
              <FlexBox justify={"space-between"} align={"center"}>
                <Title bold sm>
                  Табличная часть<span className="required_point">*</span>
                </Title>
                {isEqual(disable, false) && (
                  <ReactSVG
                    src={add_green}
                    className="svg_icon"
                    onClick={() => handleOpenModal()}
                  />
                )}
              </FlexBox>
            </Col>
            <Col xs={12} style={{ padding: 0 }} className={"my-16"}>
              <hr />
            </Col>
          </Row>

          <Row>
            <Col xs={12}>
              <Col>
                <BaseTable
                  tableHeader={[
                    "№",
                    "Сырьё и материал",
                    "Ед. изм",
                    "Количество",
                    "Цена",
                    "Сумма",
                    "Сумма НДС",
                    "Всего",
                    "",
                  ]}
                >
                  {!isEmpty(get(purchaseContract, "data.details", [])) ? (
                    get(purchaseContract, "data.details", []).map(
                      (item: any, index: number) => (
                        <tr key={index + 1}>
                          <td>{index + 1}</td>
                          <td>{get(item, "imyaMateriala", "-")}</td>
                          <td>{get(item, "imyaEdIzm", "-")}</td>
                          <td>
                            <NumericFormat
                              displayType="text"
                              value={get(item, "kol", "")}
                              thousandSeparator
                              decimalScale={2}
                            />
                          </td>
                          <td>
                            <NumericFormat
                              displayType="text"
                              value={get(item, "sena", "")}
                              thousandSeparator
                              decimalScale={2}
                            />
                          </td>
                          <td>
                            <NumericFormat
                              displayType="text"
                              value={get(item, "summa", "")}
                              thousandSeparator
                              decimalScale={2}
                            />
                          </td>
                          <td>
                            <NumericFormat
                              displayType="text"
                              value={get(item, "summaNDS", "")}
                              thousandSeparator
                              decimalScale={2}
                            />
                          </td>
                          <td>
                            <NumericFormat
                              displayType="text"
                              value={get(item, "vsego", "")}
                              thousandSeparator
                              decimalScale={2}
                            />
                          </td>
                          <td>
                            <ActionBase
                              handleUpdate={handleOpenModal}
                              handleDelete={() =>
                                dispatch(
                                  movementPurchaseSubject({
                                    type: ACTION.DELETE,
                                    id: get(item, "materialId", null),
                                  })
                                )
                              }
                              disable={disable}
                              id={get(item, "materialId", null)}
                            />
                          </td>
                        </tr>
                      )
                    )
                  ) : (
                    <tr>
                      <td colSpan={9}> Нет Реквизиты</td>
                    </tr>
                  )}
                </BaseTable>
              </Col>
            </Col>
          </Row>
          <Row className="mb-16 mt-32 text-end">
            <Col xs={12}>
              {get(state, "isUpdate", false) ? (
                <FormButton
                  disabled={disable}
                  loading={isSubmitting}
                  handleClick={handleUpdatePurchase}
                >
                  {PAGE.CHANGE}
                </FormButton>
              ) : (
                <HasAccess>
                  {({ userCan }: any) =>
                    userCan({
                      permission: `${DOCUMENT_KEY.DOG_POKUPKA}_CREATE`,
                      page: DOCUMENT_KEY.DOG_POKUPKA,
                      department: MAIN_DEPARTMENT.DOCUMENTS,
                      type: PROTECTED_TYPE.PERMISSIONS,
                    }) && (
                      <FormButton
                        disabled={disable}
                        loading={isSubmitting}
                        handleClick={handleSubmit}
                      >
                        {PAGE.DONE}
                      </FormButton>
                    )
                  }
                </HasAccess>
              )}
              <Button danger handleClick={() => navigate(-1)}>
                {PAGE.CLOSE}
              </Button>
            </Col>
          </Row>
        </Form>
      ) : (
        <LoadingAddPages />
      )}
    </Content>
  );
};

export default AddPurchaseContractsContainer;
