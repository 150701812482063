import { useAppDispatch, useAppSelector } from "app/hook";
import {
  clearOtherExpenses,
  fetchAllOtherProdCosts,
  fetchAllOtherProdCostsTemp,
  makeDetailsOtherCost,
  movementOtherCost,
} from "app/slices/documentSlices/otherProdCostSlice/otherProdCostSlice";
import { fetchExpanseItem } from "app/slices/handbookSlices/expenseItemSlices/expanseItemSlices";
import { otherCostDetails, otherCostFilter } from "app/states/documents";
import { optionsStatyaZatrata } from "app/states/documents/selectStates";
import { getTerritoriesData } from "app/states/handbooks/selectStates";
import add_green from "assets/images/icons/add_green.svg";
import {
  ActionBase,
  BaseInput,
  BaseMaskInput,
  BaseSelect,
  BaseTable,
  Button,
  Content,
  DatePicker,
  FlexBox,
  Form,
  FormButton,
  FormSelect,
  LoadingAddPages,
  ReactModal,
  Text,
  TextArea,
  Title,
} from "components";
import { useFormik } from "formik";
import { get, isEmpty, isEqual } from "lodash";
import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import { Col, Row } from "react-grid-system";
import { NumericFormat } from "react-number-format";
import { useLocation, useNavigate } from "react-router-dom";
import { ReactSVG } from "react-svg";
import { toast } from "react-toastify";
import HasAccess from "routers/HasAccess";
import { DOCUMENT_KEY, MAIN_DEPARTMENT } from "routers/constants";
import DocumentServices from "services/apiServices/documents";
import { ACTION, MESSAGES, PAGE, PROTECTED_TYPE } from "utilities/constants";
import { checkDateYear, removeValueOption } from "utilities/helpers";
import { otherExpensesDetails } from "utilities/schema/document/documentSchemas";
import { NumberDocType } from "types/documentsType";

// Ввод прочих производственных расходов Добавить
interface ItemType {
  id: string | null;
  imyaStatyaRasxoda: string | null;
  statyaRasxodaId: number | null;
  summa: number | null;
}
export const AddContractSubjects = ({
  handleOpenModal,
  handleCloseModal,
  tableDetails,
  id,
}: {
  handleOpenModal?: () => void;
  handleCloseModal: () => void;
  tipDogProdaja?: number | string;
  tableDetails: Array<ItemType>;
  id?: number | string | null;
}) => {
  const optionsStatyaZatrat = useAppSelector(optionsStatyaZatrata);
  const details = tableDetails?.find(
    (item: any, index: number) => index === id
  );
  const dispatch = useAppDispatch();
  const onSubmit = (values: any) => {
    const payload = {
      statyaRasxod: get(values, "statyaRasxodaId", ""),
      statyaRasxodaId: get(values, "statyaRasxodaId.value", ""),
      imyaStatyaRasxoda: get(values, "statyaRasxodaId.label", ""),
      summa: get(values, "summa", ""),
    };
    if (isEmpty(details)) {
      dispatch(movementOtherCost({ type: ACTION.ADD, data: payload }));
    } else {
      dispatch(movementOtherCost({ type: ACTION.UPDATE, data: payload, id }));
    }
    handleCloseModal();
    resetForm();
  };

  const { values, handleSubmit, resetForm, setFieldValue } = useFormik({
    enableReinitialize: true,
    initialValues: {
      statyaRasxodaId: {
        label: get(details, "imyaStatyaRasxoda", null),
        value: get(details, "statyaRasxodaId", null),
      },
      summa: get(details, "summa", null),
    },
    onSubmit,
    validationSchema: otherExpensesDetails,
  });
  useEffect(() => {
    dispatch(fetchExpanseItem({ all: true }));
  }, []);
  return (
    <>
      <Form onSubmit={handleSubmit}>
        <Col xs={12} className="mt-16 text-left">
          <Title sm bold>
            Реквизиты
          </Title>
        </Col>
        <Col xs={12} style={{ padding: 0 }} className={"my-16"}>
          <hr />
        </Col>
        <Col xs={12} className={"px-24"}>
          <FormSelect
            label={"Статья расхода"}
            required={true}
            id={"statyaRasxodaId"}
            isSearchable
            defaultValue={get(values, "statyaRasxodaId", {})}
            isLoading={optionsStatyaZatrat.loading}
            value={get(values, "statyaRasxodaId.value", "")}
            options={removeValueOption({
              currentOption: optionsStatyaZatrat.list,
              removeValueList: tableDetails,
              valueName: "statyaRasxodaId",
              isUpdate: get(values, "statyaRasxodaId", {}),
            })}
            handleValueLabel={(e: any) => {
              setFieldValue("statyaRasxodaId", e);
            }}
            menuPosition={"static"}
          />
          <Row align="center" className="mt-24">
            <Col xs={4} style={{ paddingRight: 0 }}>
              <Title>
                Сумма<span className="required_point">*</span>
              </Title>
            </Col>
            <Col xs={8}>
              <BaseMaskInput
                id={"summa"}
                name={"summa"}
                value={get(values, "summa", "") || ""}
                handleInput={(e: number) => {
                  setFieldValue("summa", e);
                }}
                min={1}
                maxLength={40}
              />
            </Col>
          </Row>
          <Col xs={12} className="text-right mt-24 mb-16 ">
            <Button className="mr-8" green type="submit">
              {get(details, "statyaRasxodaId", null) ? PAGE.CHANGE : PAGE.ADD}
            </Button>
            <Button
              onClick={() => {
                handleCloseModal();
                resetForm();
              }}
              danger
            >
              Закрыть
            </Button>
          </Col>
        </Col>
      </Form>
    </>
  );
};

// Ввод прочих производственных расходов
const AddOtherCostsContainer = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [openModal, setOpenModal] = useState(false);
  const territory = useAppSelector(getTerritoriesData);
  const filter = useAppSelector(otherCostFilter);
  const details = useAppSelector(otherCostDetails);
  const [detailId, setDetailId] = useState<number | null | undefined>(null);
  const { state } = useLocation();
  const [LOADING, setLoading] = useState(true);
  const [disable, setDisable] = useState(false);

  const handleCloseModal = useCallback(() => {
    setOpenModal(false);
  }, []);
  const handleOpenModal = useCallback((id: number | null) => {
    setOpenModal(true);
    setDetailId(id);
  }, []);

  const onSubmit = (values: any) => {
    const params = {
      ...values,
      territoriyaId: get(values, "territoriyaId.value", ""),
      kommentariya: get(values, "kommentariya", ""),
      prochRasxodiDetailsList: get(details, "data", []).map(
        ({ statyaRasxodaId, summa }: any) => ({
          statyaRasxodaId,
          summa,
        })
      ),
    };
    try {
      DocumentServices.AddOtherProdCost({
        transactionTime: new Date(),
        resultCode: null,
        resultMsg: null,
        data: params,
      }).then((res) => {
        if (get(res, "status", "") == 200) {
          navigate(-1);
          dispatch(clearOtherExpenses());
          dispatch(fetchAllOtherProdCosts(filter));
          toast.success(MESSAGES.ADDED);
          if (get(state, "id", false)) {
            DocumentServices.RemoveOtherCostTemp(get(state, "id", "")).then(
              (res) => {
                if (res.status == 200) {
                  dispatch(fetchAllOtherProdCostsTemp());
                }
              }
            );
          }
        }
        setSubmitting(false);
      });
    } catch (err) {
      console.log("err", err);
    }
  };

  let initialValues = {
    nomerDoc: "",
    dataDoc: '',
    territoriyaId: "",
    kommentariya: "",
  };

  const { values, setFieldValue, handleSubmit, isSubmitting, setSubmitting } =
    useFormik({
      enableReinitialize: true,
      initialValues,
      onSubmit,
    });

  const handleSetInitialValues = (data: any) => {
    dispatch(makeDetailsOtherCost(get(data, "prochRasxodiDetailsList", [])));
    setFieldValue("nomerDoc", get(data, "nomerDoc", ""));
    setFieldValue("dataDoc", get(data, "dataDoc", ""));
    setFieldValue("territoriyaId", {
      value: get(data, "territoriyaId", ""),
    });
  };

  const saveNotFullFilledForm = useCallback(() => {
    const params = {
      ...values,
      id: get(state, "id", ""),
      territoriyaId: get(values, "territoriyaId.value", ""),
      imyaTerritorii: get(values, "territoriyaId.label", ""),
      prochRasxodiDetailsList: get(details, "data", []),
    };

    try {
      DocumentServices.SaveOtherProdCostTemp({
        transactionTime: new Date(),
        resultCode: null,
        resultMsg: null,
        data: params,
      }).then((res) => {
        if (get(res, "status", "") == 200) {
          navigate(-1);
          dispatch(fetchAllOtherProdCostsTemp());
          toast.success(MESSAGES.ADDED);
        }
      });
    } catch (err) {
      console.log("err", err);
    }
  }, [dispatch, navigate, details, values]);

  const getDocNumber = useCallback(
    (date: NumberDocType) => {
      if (get(state, "isTemp", true)) {
        DocumentServices.OtherProdCostNumber(date).then(({ data }) => {
          setFieldValue("nomerDoc", get(data, "data", ""));
          if (get(data, "transactionTime", "")) {
            const transactionTime = get(data, "transactionTime", "");
            const localMaxDate = moment.utc(transactionTime).add(5, 'hours').format("YYYY-MM-DD HH:mm");
            const localDate = moment.utc(transactionTime).add(5, 'hours').format("YYYY-MM-DD");
            setFieldValue("maxDate", localMaxDate);
            if (isEmpty(get(values, 'dataDoc', ''))) {
              setFieldValue("dataDoc", localDate);
            }
          }
        });
      }
      dispatch(clearOtherExpenses());
    },
    [setFieldValue, state, values]
  );

  const handleCheckTemp = useCallback(() => {
    if (get(state, "id", false)) {
      if (get(state, "isTemp", false)) {
        DocumentServices.FetchOtherProdCostTempById(get(state, "id", "")).then(
          (res) => {
            if (get(res, "status", "") == 200) {
              let respond = get(res, "data.data", {});
              handleSetInitialValues(respond);
              setLoading(false);
            } else {
              setLoading(false);
            }
          }
        );
      } else {
        DocumentServices.FetchOtherProdCostById(get(state, "id", "")).then(
          (res) => {
            if (get(res, "status", "") == 200) {
              let respond = get(res, "data.data", {});
              setLoading(false);
              handleSetInitialValues(respond);
            } else {
              setLoading(false);
            }
          }
        );
        setDisable(true);
      }
    } else {
      getDocNumber({});
      setLoading(false);
    }
  }, [dispatch, state]);

  useEffect(() => {
    handleCheckTemp();
  }, [handleCheckTemp]);

  return (
    <Content className={"min_height"}>
      <ReactModal isOpen={openModal}>
        <AddContractSubjects
          handleCloseModal={handleCloseModal}
          tableDetails={get(details, "data", [])}
          id={detailId}
        />
      </ReactModal>
      {isEqual(LOADING, false) ? (
        <Form onSubmit={handleSubmit}>
          <Row>
            <Col xs={12}>
              <FlexBox justify={"space-between"} style={{ width: "100%" }}>
                <Title bold sm>
                  Реквизиты
                </Title>
                <Button
                  green
                  handleClick={saveNotFullFilledForm}
                  disabled={disable}
                >
                  Сохранить
                </Button>
              </FlexBox>
            </Col>
            <Col xs={12} style={{ padding: 0 }} className={"my-16"}>
              <hr />
            </Col>
          </Row>
          <Row>
            <Col xs={6}>
              <Row align="center">
                <Col xs={4}>
                  <Title>
                    Номер
                    <span className="required_point">*</span>
                  </Title>
                </Col>
                <Col xs={8} className="form-input-date_picker">
                  <Row>
                    <Col xs={6}>
                      <BaseInput
                        id={"nomerDoc"}
                        value={get(values, "nomerDoc", "")}
                      />
                    </Col>
                    <Col
                      xs={6}
                      style={{
                        paddingLeft: 0,
                      }}
                    >
                      <FlexBox>
                        <Text>от:</Text>
                        <DatePicker
                          id={"dataDoc"}
                          value={get(values, "dataDoc", "")}
                          handleDate={(e: any) => {
                            getDocNumber({
                              year: checkDateYear({
                                currentYear: get(values, "dataDoc", null),
                                checkYear: moment(e).format("YYYY-MM-DD"),
                              }),
                            });
                            setFieldValue(
                              "dataDoc",
                              moment(e).format("YYYY-MM-DD")
                            );
                          }}
                          // maxDate={new Date()}
                          maxDate={new Date(get(values, 'maxDate', new Date()))}
                          disabled={disable}
                          dateFormat="dd.MM.yyyy"
                        />
                      </FlexBox>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row align="center" className="mt-24">
                <Col xs={4}>
                  <Title>
                    Территория
                    <span className="required_point">*</span>
                  </Title>
                </Col>
                <Col xs={8}>
                  <BaseSelect
                    id={"territoriyaId"}
                    isSearchable
                    value={get(values, "territoriyaId.value", "")}
                    options={territory}
                    handleValueLabel={(e: any) => {
                      setFieldValue("territoriyaId", e);
                    }}
                    isDisabled={disable}
                  />
                </Col>
              </Row>
              <TextArea
                id={"kommentariya"}
                label={"Коментарии"}
                disabled={disable}
                value={get(values, "kommentariya", "")}
                handleInput={(e: string) => {
                  setFieldValue("kommentariya", e);
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col xs={6}></Col>
          </Row>

          <Row className="mt-24" justify={"between"}>
            <Col xs={12} className="align-center">
              <FlexBox justify={"space-between"} align={"center"}>
                <Title bold sm>
                  Табличная часть
                  <span className="required_point">*</span>
                </Title>
                {!disable && (
                  <ReactSVG
                    src={add_green}
                    className="svg_icon"
                    onClick={() => handleOpenModal(null)}
                  />
                )}
              </FlexBox>
            </Col>
            <Col xs={12} className={"mt-16"}>
              <hr />
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <Col>
                <BaseTable tableHeader={["№", "Статья расхода", "Сумма", ""]}>
                  {!isEmpty(get(details, "data", [])) ? (
                    get(details, "data", []).map((item: any, index: number) => (
                      <tr key={index + 1}>
                        <td>{index + 1}</td>
                        <td>{get(item, "imyaStatyaRasxoda", "")}</td>
                        <td>
                          <NumericFormat
                            displayType="text"
                            value={get(item, "summa", 0)}
                            thousandSeparator={true}
                            fixedDecimalScale={true}
                          />
                        </td>
                        <td>
                          <ActionBase
                            handleUpdate={handleOpenModal}
                            handleDelete={() =>
                              dispatch(
                                movementOtherCost({
                                  type: ACTION.DELETE,
                                  id: index,
                                })
                              )
                            }
                            disable={disable}
                            id={index}
                          />
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan={10}>Нет Данных</td>
                    </tr>
                  )}
                </BaseTable>
              </Col>
            </Col>
          </Row>
          <Row className={"mb-16 mt-32 text-end"}>
            <Col xs={12}>
              {/* <Button dark className={"mr-16"} type="submit">
                        Печать
                    </Button> */}
              <HasAccess>
                {({ userCan }: any) =>
                  userCan({
                    permission: `${DOCUMENT_KEY.PROCHI_RASXOD}_CREATE`,
                    page: DOCUMENT_KEY.PROCHI_RASXOD,
                    department: MAIN_DEPARTMENT.DOCUMENTS,
                    type: PROTECTED_TYPE.PERMISSIONS,
                  }) && (
                    <FormButton
                      disabled={disable}
                      loading={isSubmitting}
                      handleClick={handleSubmit}
                    >
                      {PAGE.DONE}
                    </FormButton>
                  )
                }
              </HasAccess>
              <Button danger handleClick={() => navigate(-1)}>
                {PAGE.CLOSE}
              </Button>
            </Col>
          </Row>
        </Form>
      ) : (
        <LoadingAddPages />
      )}
    </Content>
  );
};

export default AddOtherCostsContainer;
